import { Dispatch, FC, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Box,
  TextFilter,
  Pagination,
  Flashbar
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { IUserWorkSpaceDecommissionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';
import { FlashbarProps } from '@awsui/components-react';

import UserWorkSpaceDecommissionRequestDetails from '../userWorkSpaceDecommissionRequestDetails';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  userWorkSpaceDecommissionRequests: IUserWorkSpaceDecommissionRequest[];
  selectedUserWorkSpaceDecommissionRequests: IUserWorkSpaceDecommissionRequest[];
  disableActionButtons: boolean;
  handleTableRowSelection: (user: IUserWorkSpaceDecommissionRequest[]) => void;
  tableIsLoading: boolean;
  refreshUserWorkSpaceDecommissionRequestTable: () => void;
  userWorkSpaceDecommissionRequestDetailsModalIsVisible: boolean;
  setUserWorkSpaceDecommissionRequestDetailsModalIsVisible: Dispatch<
    SetStateAction<boolean>
  >;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({
  userWorkSpaceDecommissionRequests,
  selectedUserWorkSpaceDecommissionRequests,
  handleTableRowSelection,
  disableActionButtons,
  tableIsLoading,
  refreshUserWorkSpaceDecommissionRequestTable,
  userWorkSpaceDecommissionRequestDetailsModalIsVisible,
  setUserWorkSpaceDecommissionRequestDetailsModalIsVisible,
  flashBarItems
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No All WorkSpace Decommission Requests</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No WorkSpace Decommissiont Requests to display.
        </Box>
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching User WorkSpace Decommission Requests</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(userWorkSpaceDecommissionRequests, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: [
        'firstName',
        'lastName',
        'corpEmail',
        'homeGroup',
        'justification'
      ]
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredUserWorkSpaceDecommissionRequests = [] } =
    collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshUserWorkSpaceDecommissionRequestTable()}
          />
          <Button
            variant="primary"
            disabled={disableActionButtons}
            onClick={() =>
              setUserWorkSpaceDecommissionRequestDetailsModalIsVisible(true)
            }
          >
            View
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredUserWorkSpaceDecommissionRequests.length
            ? `(${filteredUserWorkSpaceDecommissionRequests.length}/${userWorkSpaceDecommissionRequests.length})`
            : `(${userWorkSpaceDecommissionRequests.length})`
        }
        actions={renderTableActions()}
      >
        All WorkSpace Decommission Requests
      </Header>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'userName',
      header: 'Username',
      cell: (item: IUserWorkSpaceDecommissionRequest) => item.userName || '-'
    },
    {
      id: 'corpEmail',
      header: 'Corp Email',
      cell: (item: IUserWorkSpaceDecommissionRequest) => item.corpEmail || '-'
    },
    {
      id: 'workSpaceId',
      header: 'WorkSpace ID',
      cell: (item: IUserWorkSpaceDecommissionRequest) => item.workSpaceId || '-'
    },
    {
      id: 'justification',
      header: 'Justification',
      cell: (item: IUserWorkSpaceDecommissionRequest) =>
        item.justification || '-'
    }
  ];

  const renderUserWorkSpaceDecommissionRequestDetailsModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setUserWorkSpaceDecommissionRequestDetailsModalIsVisible(false);
        }}
        visible={userWorkSpaceDecommissionRequestDetailsModalIsVisible}
        closeAriaLabel="Close modal"
        size="large"
        header="WorkSpace Decommission Request Details"
      >
        <UserWorkSpaceDecommissionRequestDetails
          selectedUserWorkSpaceDecommissionRequests={
            selectedUserWorkSpaceDecommissionRequests
          }
        />
      </ChildModal>
    );
  };

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedUserWorkSpaceDecommissionRequests}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Users"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Users"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderUserWorkSpaceDecommissionRequestDetailsModal()}
    </>
  );
};
export default View;
