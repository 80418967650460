import { FC, useState } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { ICreateDuoPlatform } from '../../../../../../interfaces/integrations/duo';

import View from './view';

interface IndexProps {
  refreshDuoPlatformTable: () => void;
}

const CreateDuoPlatformForm: FC<IndexProps> = ({ refreshDuoPlatformTable }) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const formValues: ICreateDuoPlatform = {
    platformName: ''
  };

  const handleSubmittedForm = async (data: ICreateDuoPlatform) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .createDuoPlatform(data)
      .then(() => {
        refreshDuoPlatformTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Duo Platform has been created!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to create Duo Platform!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default CreateDuoPlatformForm;
