import { stageConfigs } from '../../etc/application';

export class ConfigHelper {
  static get() {
    const stageConfig =
      stageConfigs[process.env.REACT_APP_NODE_STAGE_ENV || 'default'];

    return stageConfig;
  }
}
