import { FC, useContext } from 'react';
import { TextContent, Link } from '@awsui/components-react';

import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { GlobalContext } from '../../context/globalContext';

const Footer: FC = () => {
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;

  const { currentUser } = useContext(GlobalContext);

  return (
    <div className="footer">
      <TextContent>
        <p>
          <small>
            Copyright © {prevYear} - {currentYear} Amazon Web Services.
          </small>
        </p>
      </TextContent>

      { !currentUser
        ? <div style={{ position: 'fixed', right: '1em' }}>
            <Link onFollow={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Amazon })}>
              <small>Admin</small>
            </Link>
          </div>
        : <></>
      }
    </div>
  );
};

export default Footer;
