import { FC } from 'react';

import {
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  SpaceBetween
} from '@awsui/components-react';

interface ViewProps {
  handleSyncActiveDirectory: () => void;
  handleSyncWorkSpaces: () => void;
  handleSyncWorkSpaceBundles: () => void;
  handleSyncWorkSpaceDirectories: () => void;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({
  handleSyncActiveDirectory,
  handleSyncWorkSpaces,
  handleSyncWorkSpaceBundles,
  handleSyncWorkSpaceDirectories,
  flashBarItems
}) => {
  return (
    <Container header={<Header variant="h2">Sync Data</Header>}>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />

        <SpaceBetween direction="vertical" size="xl">
          <FormField
            description="This will execute queries against AD and repopulate Active Directory data."
            label="Run Sync with Active Directory"
          >
            <Button
              variant="primary"
              onClick={() => handleSyncActiveDirectory()}
            >
              Sync AD
            </Button>
          </FormField>

          <FormField
            description="This will execute queries against AWS WorkSpaces and repopulate WorkSpaces data."
            label="Run Sync with AWS WorkSpaces"
          >
            <Button variant="primary" onClick={() => handleSyncWorkSpaces()}>
              Sync WorkSpaces
            </Button>
          </FormField>

          <FormField
            description="This will execute queries against AWS WorkSpaces and repopulate WorkSpace Bundle data."
            label="Run Sync with AWS WorkSpace Bundles"
          >
            <Button
              variant="primary"
              onClick={() => handleSyncWorkSpaceBundles()}
            >
              Sync WorkSpace Bundles
            </Button>
          </FormField>

          <FormField
            description="This will execute queries against AWS WorkSpaces and repopulate WorkSpace Directory data."
            label="Run Sync with AWS WorkSpace Directories"
          >
            <Button
              variant="primary"
              onClick={() => handleSyncWorkSpaceDirectories()}
            >
              Sync WorkSpace Directories
            </Button>
          </FormField>
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};
export default View;
