import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  SelectProps,
  Select,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps } from '@awsui/components-react';
import { IUpdateUserWorkSpaceProvisionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';

interface ViewProps {
  handleSubmittedForm: (
    data: IUpdateUserWorkSpaceProvisionRequest
  ) => Promise<void>;
  formValues: IUpdateUserWorkSpaceProvisionRequest;
  bundleSelectValues: SelectProps.Options;
  directorySelectValues: SelectProps.Options;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({
  handleSubmittedForm,
  formValues,
  bundleSelectValues,
  directorySelectValues,
  flashBarItems
}) => {
  const validationSchema = yup.object({
    bundleId: yup.string().required(),
    directoryId: yup.string().required()
  });

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={formValues}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={flashBarItems} />
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormField label="WorkSpace Bundle" errorText={errors.bundleId}>
              <Select
                name="bundleId"
                selectedOption={{
                  value: values.bundleId
                }}
                onChange={({ detail }) => {
                  setFieldValue('bundleId', detail.selectedOption.value);
                }}
                options={bundleSelectValues}
                invalid={!!errors.bundleId}
                filteringType="auto"
              />
            </FormField>

            <FormField label="Directory ID" errorText={errors.directoryId}>
              <Select
                name="directoryId"
                selectedOption={{
                  value: values.directoryId
                }}
                onChange={({ detail }) => {
                  setFieldValue('directoryId', detail.selectedOption.value);
                }}
                options={directorySelectValues}
                invalid={!!errors.directoryId}
                filteringType="auto"
              />
            </FormField>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
