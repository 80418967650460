import {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext
} from 'react';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';
import { GlobalContext } from '../../../../context/globalContext';

import { ICreateUserWorkSpaceDecommissionRequest } from '../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';
import { IUserWorkSpace } from '../../../../interfaces/workSpaces/userworkSpaces';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

interface IndexProps {
  userWorkSpaces: IUserWorkSpace[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  setDecommissionRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
}
const DecommissionRequestForm: FC<IndexProps> = ({
  userWorkSpaces,
  setFlashBarItems,
  setDecommissionRequestModalIsVisible
}) => {
  const {
    currentUser: { userName, corpEmail }
  } = useContext(GlobalContext);

  const [formValues, setFormValues] =
    useState<ICreateUserWorkSpaceDecommissionRequest>({
      userName: '',
      corpEmail: '',
      workSpaceId: '',
      justification: ''
    });
  const [modalFlashBarItems, setModalFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      ...{ userName, corpEmail }
    }));
  }, [corpEmail, userName]);

  useEffect(() => {
    for (const userWorkSpace of userWorkSpaces) {
      setFormValues((prevState) => ({
        ...prevState,
        ...{ workSpaceId: userWorkSpace.workSpaceId }
      }));
    }
  }, [userWorkSpaces]);

  const handleSubmittedForm = async (
    data: ICreateUserWorkSpaceDecommissionRequest
  ) => {
    setModalFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .requestWorkSpaceDecommissionRequest(data)
      .then(() => {
        setDecommissionRequestModalIsVisible(false);
        setModalFlashBarItems([]);
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Your WorkSpace Decommission request has been received!</>
          }
        ]);
      })
      .catch(() => {
        setModalFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to submit your WorkSpace Decommission request!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      modalFlashBarItems={modalFlashBarItems}
    />
  );
};
export default DecommissionRequestForm;
