import {
    FC,
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    useContext
} from 'react';

import { useInterval } from '../../../common/CustomHooks';
import { MainServiceApi } from '../../../../lib/api/mainServiceApi';
import { GlobalContext } from '../../../../context/globalContext';
import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { ICreateUserWorkSpaceRequest } from '../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';
import { IUser } from '../../../../interfaces/domain/user';

import View from '../../../user/workSpaces/provisionRequestForm/view';

interface IndexProps {
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  setProvisionRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  users: IUser[];
}

const UserProvisionRequestForm: FC<IndexProps> = ({
  setFlashBarItems,
  setProvisionRequestModalIsVisible,
  users
}) => {
  const {
    currentUser: { corpEmail }
  } = useContext(GlobalContext);


const [modalFlashBarItems, setModalFlashBarItems] = useState<
  FlashbarProps.MessageDefinition[]
>([]);

const [bundleSelectValues, setBundleSelectValues] =
  useState<SelectProps.Options>([]);

const [adDirectorySelectValues, setAdDirectorySelectValues] =
  useState<SelectProps.Options>([]);

const [formValues, setFormValues] =
  useState<ICreateUserWorkSpaceRequest>({
    userName: '',
    corpEmail: '',
    bundleId: '',
    directoryId: '',
    justification: '',
    updatedAt: 0,
    updatedBy: ''
  });

useEffect(() => {
  for (const user of users) {
    setFormValues({
      userName: user.userName,
      corpEmail: user.corpEmail,
      bundleId: '',
      justification: '',
      directoryId: '',
      updatedAt: 0,
      updatedBy: corpEmail
    });
  }
}, [users, corpEmail]);


useInterval(() => {
  const api = new MainServiceApi();
  Promise.all([
    api.getAllDirectories(),
    api.getAllBundles()
  ])
  .then(([directories, bundles]) => {
    setAdDirectorySelectValues(
      directories.map((adDirectory) =>({
        label: adDirectory.alias,
        value: adDirectory.directoryId
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
    );
    setBundleSelectValues(
      bundles.map((bundle) =>({
        label: bundle.bundleName,
        value: bundle.bundleId
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
    );
  }).catch(() => {
    setModalFlashBarItems([
      {
        type: 'error',
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setModalFlashBarItems([]),
        content: <>Unable to retrieve data for this provision request!</>
      }
    ]);
  });

}, []);


const handleSubmittedForm = async (
  data: ICreateUserWorkSpaceRequest
) => {
  setFlashBarItems([
    {
      type: 'success',
      loading: true,
      content: 'Processing...'
    }
  ]);

  const api = new MainServiceApi();
  api.createUserWorkSpace(data)
    .then(() => {
      setProvisionRequestModalIsVisible(false);
      setFlashBarItems([
        {
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Your WorkSpace creation request has been initiated!</>
        }
      ]);
      setModalFlashBarItems([]);
    })
    .catch(() => {
      setModalFlashBarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setModalFlashBarItems([]),
          content: <>Unable to initiate your WorkSpace creation request!</>
        }
      ]);
      setFlashBarItems([]);
    })

};

return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      bundleSelectValues={bundleSelectValues}
      modalFlashBarItems={modalFlashBarItems}
      adDirectorySelectValues={adDirectorySelectValues}
    />
  );
};
export default UserProvisionRequestForm;
