import { FC } from 'react';
import { Box } from '@awsui/components-react';

interface Props {
  label: string;
  children: JSX.Element | any;
}

const ValueWithLabel: FC<Props> = ({ label, children }) => {
  return (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-label">
        {label}
      </Box>
      <div>{children}</div>
    </div>
  );
};

export default ValueWithLabel;
