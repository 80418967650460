import { FlashbarProps } from '@awsui/components-react';
import { Dispatch, SetStateAction } from 'react';
import { IUserWorkSpace, UserWorkSpaceAction } from '../../../interfaces/workSpaces/userworkSpaces';
import { MainServiceApi } from '../../../lib/api/mainServiceApi';

export const handleWorkSpaceReboot = (
  selectedWorkSpaces: IUserWorkSpace[],
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>
) => {
  setFlashBarItems([
    {
      type: 'success',
      loading: true,
      content: 'Processing...'
    }
  ]);
  const api = new MainServiceApi();

  for (const selectedWorkSpace of selectedWorkSpaces) {
    if (selectedWorkSpace.workSpaceId) {
      api
        .requestRebootWorkSpace(selectedWorkSpace.workSpaceId)
        .then(() => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Your WorkSpace Reboot request has been received!</>
            }
          ]);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to submit your WorkSpace Reboot request!</>
            }
          ]);
        });
    }
  }
};

export const handleWorkSpaceRebuild = (
  selectedWorkSpaces: IUserWorkSpace[],
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>,
  setRebuildConfirmationModalIsVisible: Dispatch<SetStateAction<boolean>>
) => {
  setFlashBarItems([
    {
      type: 'success',
      loading: true,
      content: 'Processing...'
    }
  ]);

  const api = new MainServiceApi();

  for (const selectedWorkSpace of selectedWorkSpaces) {
    if (selectedWorkSpace.workSpaceId) {
      api
        .requestRebuildWorkSpace(selectedWorkSpace.workSpaceId)
        .then((resp) => {
          setRebuildConfirmationModalIsVisible(false);
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Your WorkSpace Rebuild request has been received!</>
            }
          ]);
        })
        .catch((err) => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to submit your WorkSpace Rebuild request!</>
            }
          ]);
        });
    }
  }
};

export const handleWorkSpaceStart = (
  selectedWorkSpaces: IUserWorkSpace[],
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>
) => {
  setFlashBarItems([
    {
      type: 'success',
      loading: true,
      content: 'Processing...'
    }
  ]);

  const api = new MainServiceApi();

  for (const selectedWorkSpace of selectedWorkSpaces) {
    if (selectedWorkSpace.workSpaceId) {
      api
        .requestStartWorkSpace(selectedWorkSpace.workSpaceId)
        .then(() => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Your WorkSpace Start request has been received!</>
            }
          ]);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to submit your WorkSpace Start request!</>
            }
          ]);
        });
    }
  }
};

export const updateWorkSpaceTableActions = (
  selectedWorkSpaces: IUserWorkSpace[],
  setAllowedWSActions: Dispatch<SetStateAction<UserWorkSpaceAction[]>>,
) => {
  for (const selectedWorkSpace of selectedWorkSpaces) {
    switch (selectedWorkSpace.workSpaceState) {
      case 'AVAILABLE': // fallthrough on purpose
      case 'UNHEALTHY':
        setAllowedWSActions(['reboot', 'rebuild']);
        break;
      case 'STOPPED':
        setAllowedWSActions(['start', 'rebuild']);
        break;
      case 'ERROR': // fallthrough on purpose
      case 'REBOOTING':
        setAllowedWSActions(['rebuild']);
        break;
      default:
        setAllowedWSActions([]);
    }
  }
};
