import { FC } from 'react';

import {
  Button,
  SpaceBetween,
  FormField,
  Form,
  Flashbar,
  Checkbox,
  Multiselect,
  Select
} from '@awsui/components-react';

import { Formik } from 'formik';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { IUpdateHomeGroup } from '../../../../../interfaces/domain/homegroup';

interface ViewProps {
  formValues: IUpdateHomeGroup;
  flashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: IUpdateHomeGroup) => Promise<void>;
  bundleSelectValues: SelectProps.Options;
  directorySelectValues: SelectProps.Options;
  approvedDomainsSelectValues: SelectProps.Options;
}

const View: FC<ViewProps> = ({
  formValues,
  flashBarItems,
  handleSubmittedForm,
  bundleSelectValues,
  directorySelectValues,
  approvedDomainsSelectValues
}) => {
  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={formValues}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSubmittedForm(data).then((resp) => {
            resetForm();
            setSubmitting(false);
          });
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
          <SpaceBetween direction="vertical" size="xs">
            <Flashbar items={flashBarItems} />
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="m">
                <FormField
                  label="Email Domain"
                  description="Approved Domain for group access"
                  errorText={errors.emailDomain}
                >
                  <Select
                    name="emailDomain"
                    selectedOption={{
                      value: values.emailDomain,
                      label: values.emailDomain
                    }}
                    onChange={({ detail }) =>
                      setFieldValue('emailDomain', detail.selectedOption.value)
                    }
                    options={approvedDomainsSelectValues}
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                  />
                </FormField>
                <FormField
                  label="Bundles"
                  description="Bundles for users to select from"
                  errorText={errors.bundles}
                >
                  <Multiselect
                    name="bundles"
                    selectedOptions={values.bundles.map((bundle) => ({
                      label: bundle.bundleName,
                      value: bundle.bundleId
                    }))}
                    onChange={({ detail }) => {
                      setFieldValue(
                        'bundles',
                        detail.selectedOptions.map((bundle) => ({
                          bundleName: bundle.label,
                          bundleId: bundle.value
                        }))
                      );
                    }}
                    options={bundleSelectValues}
                    invalid={!!errors.bundles}
                    deselectAriaLabel={(e) => 'Remove ' + e.label}
                    placeholder="Choose Bundles"
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                  />
                </FormField>
                <FormField
                  label="Directory IDs"
                  description="Directory IDs for WorkSpace creation"
                  errorText={errors.adDirectories}
                >
                  <Multiselect
                    name="adDirectories"
                    selectedOptions={values.adDirectories.map(
                      (adDirectory) => ({
                        label: adDirectory.alias,
                        value: adDirectory.directoryId
                      })
                    )}
                    onChange={({ detail }) => {
                      setFieldValue(
                        'adDirectories',
                        detail.selectedOptions.map((directory) => ({
                          alias: directory.label,
                          directoryId: directory.value
                        }))
                      );
                    }}
                    options={directorySelectValues}
                    invalid={!!errors.adDirectories}
                    deselectAriaLabel={(e) => 'Remove ' + e.label}
                    placeholder="Choose Directories"
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                  />
                </FormField>
                <FormField>
                  <Checkbox
                    name="deprecated"
                    checked={values.deprecated === 1}
                    onChange={({ detail }) =>
                      setFieldValue('deprecated', detail.checked ? 1 : 0)
                    }
                  >
                    Deprecated
                  </Checkbox>
                </FormField>
              </SpaceBetween>
            </Form>
          </SpaceBetween>
        )}
      </Formik>
    </>
  );
};
export default View;
