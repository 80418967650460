import { FC } from 'react';
import { Button, Container, Header } from '@awsui/components-react';

const NotFound: FC = () => (
  <Container
    footer={
      <Button variant="primary" href="/">
        Go Home
      </Button>
    }
    header={<Header variant="h2">Not Found</Header>}
  >
    Page does not exist or you don't have access to it.
  </Container>
);

export default NotFound;
