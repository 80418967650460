import { FC, useState, useCallback } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUserWorkSpaceDecommissionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';

import View from './view';

const UserWorkSpacePendingDecommissionRequestTable: FC = () => {
  const [
    userWorkSpaceDecommissionRequests,
    setUserWorkSpaceDecommissionRequests
  ] = useState<IUserWorkSpaceDecommissionRequest[]>([]);
  const [
    selectedUserWorkSpaceDecommissionRequests,
    setSelectedUserWorkSpaceDecommissionRequests
  ] = useState<IUserWorkSpaceDecommissionRequest[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    rejectUserWorkSpaceDecommissionRequestModalIsVisible,
    setRejectUserWorkSpaceDecommissionRequestModalIsVisible
  ] = useState(false);
  const [
    userWorkSpaceDecommissionRequestDetailsModalIsVisible,
    setUserWorkSpaceDecommissionRequestDetailsModalIsVisible
  ] = useState(false);

  const getUserWorkSpaceDecommissionRequests = useCallback(() => {
    setTableIsLoading(true);

    const api = new MainServiceApi();
    return api
      .getPendingUserWorkSpaceDecommissionRequests()
      .then((data) => {
        setUserWorkSpaceDecommissionRequests(data);
        setTableIsLoading(false);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: (
              <>Unable to get Pending User WorkSpace Decommission Requests!</>
            )
          }
        ]);
        return [];
      });
  }, []);

  useInterval(() => {
    getUserWorkSpaceDecommissionRequests();
  }, [getUserWorkSpaceDecommissionRequests], 60000);

  const handleTableRowSelection = (
    userWorkSpaceDecommissionRequest: IUserWorkSpaceDecommissionRequest[]
  ) => {
    if (userWorkSpaceDecommissionRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedUserWorkSpaceDecommissionRequests(
      userWorkSpaceDecommissionRequest
    );
  };
  const refreshUserWorkSpaceDecommissionRequestTable = () => {
    getUserWorkSpaceDecommissionRequests();
  };

  const handleApproval = () => {
    const api = new MainServiceApi();

    for (const selectedUserWorkSpaceDecommissionRequest of selectedUserWorkSpaceDecommissionRequests) {
      setFlashBarItems([
        {
          type: 'success',
          loading: true,
          content: 'Processing...'
        }
      ]);

      api
        .approveUserWorkSpaceDecommissionRequest(
          selectedUserWorkSpaceDecommissionRequest.uuid
        )
        .then((resp) => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  User WorkSpace Decommission Request for{' '}
                  {selectedUserWorkSpaceDecommissionRequest.userName} was
                  approved successfully!
                </>
              )
            }
          ]);
          refreshUserWorkSpaceDecommissionRequestTable();
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  Unable to approve User WorkSpace Decommission Request for{' '}
                  {selectedUserWorkSpaceDecommissionRequest.userName}!
                </>
              )
            }
          ]);
        });
    }
  };

  return (
    <View
      userWorkSpaceDecommissionRequests={userWorkSpaceDecommissionRequests}
      selectedUserWorkSpaceDecommissionRequests={
        selectedUserWorkSpaceDecommissionRequests
      }
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      refreshUserWorkSpaceDecommissionRequestTable={
        refreshUserWorkSpaceDecommissionRequestTable
      }
      handleApproval={handleApproval}
      rejectUserWorkSpaceDecommissionRequestModalIsVisible={
        rejectUserWorkSpaceDecommissionRequestModalIsVisible
      }
      setRejectUserWorkSpaceDecommissionRequestModalIsVisible={
        setRejectUserWorkSpaceDecommissionRequestModalIsVisible
      }
      userWorkSpaceDecommissionRequestDetailsModalIsVisible={
        userWorkSpaceDecommissionRequestDetailsModalIsVisible
      }
      setUserWorkSpaceDecommissionRequestDetailsModalIsVisible={
        setUserWorkSpaceDecommissionRequestDetailsModalIsVisible
      }
    />
  );
};
export default UserWorkSpacePendingDecommissionRequestTable;
