import { FC, useState, useEffect, useContext } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';
import { GlobalContext } from '../../../../../../context/globalContext';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoPhone } from '../../../../../../interfaces/integrations/duo';

import View from './view';

const DuoPhoneTable: FC = () => {
  const {
    currentUser: { userName }
  } = useContext(GlobalContext);

  const [duoPhones, setDuoPhones] = useState<IDuoPhone[]>([]);
  const [hasDuoToken, setHasDuoToken] = useState(false);
  //this needs to be an array because polaris.. ugh
  const [selectedDuoPhones, setSelectedDuoPhones] = useState<IDuoPhone[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [createDuoPhoneModalIsVisible, setCreateDuoPhoneModalIsVisible] =
    useState(false);
  const [activationCodeModalIsVisible, setActivationCodeModalIsVisible] =
  useState(false);

  const [deletePhoneModalIsVisible, setDeletePhoneModalIsVisible] =
    useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const [selectedDuoPhoneId, setSelectedDuoPhoneId] = useState('');

  useEffect(() => {
    for (const selectedDuoPhone of selectedDuoPhones) {
      setSelectedDuoPhoneId(selectedDuoPhone.phone_id);
    }
  }, [selectedDuoPhones]);

  useEffect(() => {
    getDuoDevices().then((data) => {
      setDuoPhones(data.phones);
      setHasDuoToken(data.tokens.length > 0);
      setTableIsLoading(false);
    });
  }, [userName]);

  const getDuoDevices = () => {
    const api = new MainServiceApi();
    return api
      .getDuoDevicesByUserName()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get DUO Phones!</>
          }
        ]);

        return { phones: [], tokens: [] };
      });
  };
  const duoPhonesIsEmpty = () => {
    return duoPhones.length === 0;
  };

  const handleTableRowSelection = (duoPhone: IDuoPhone[]) => {
    if (duoPhone.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedDuoPhones(duoPhone);
  };
  const refreshDuoPhoneTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getDuoDevices().then((data) => {
      setDuoPhones(data.phones);
      setHasDuoToken(data.tokens.length > 0);
      setTableIsLoading(false);
    });
  };

  // Step 1: generate SMS verification code
  const handleResetSMS = async () => {
    setDeleteConfirmed(true);

    const api = new MainServiceApi();

    return api.resetSMS().then(() => {
      setDeletePhoneModalIsVisible(true);
      setFlashBarItems([
        {
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Sent SMS code for verification - enter it below</>
        }
      ]);
    }).catch(() => {
      setFlashBarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Failed to send SMS verification - try again later</>
        }
      ]);
    });
  };

  // Step 2: collect SMS code and perform the deletion
  const handleDeletion = async (smsCode: string) => {
    const api = new MainServiceApi();

    setDisableActionButtons(true);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    for (const duoPhone of selectedDuoPhones) {
      return api
        .deleteDuoPhone({ phoneId: duoPhone.phone_id, smsCode })
        .then((resp) => {
          setDeletePhoneModalIsVisible(false);
          setDeleteConfirmed(false);
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>DUO Phone has been deleted!</>
            }
          ]);
          refreshDuoPhoneTable();
        })
        .catch((err) => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to delete DUO Phone! - {err}</>
            }
          ]);
        });
    }
  };
  return (
    <View
      duoPhones={duoPhones}
      hasDuoToken={hasDuoToken}
      selectedDuoPhones={selectedDuoPhones}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      duoPhonesIsEmpty={duoPhonesIsEmpty}
      tableIsLoading={tableIsLoading}
      refreshDuoPhoneTable={refreshDuoPhoneTable}
      handleDeletion={handleDeletion}
      handleResetSMS={handleResetSMS}
      createDuoPhoneModalIsVisible={createDuoPhoneModalIsVisible}
      setCreateDuoPhoneModalIsVisible={setCreateDuoPhoneModalIsVisible}
      selectedDuoPhoneId={selectedDuoPhoneId}
      activationCodeModalIsVisible={activationCodeModalIsVisible}
      setActivationCodeModalIsVisible={setActivationCodeModalIsVisible}
      deletePhoneModalIsVisible={deletePhoneModalIsVisible}
      setDeletePhoneModalIsVisible={setDeletePhoneModalIsVisible}
      deleteConfirmed={deleteConfirmed}
      setDeleteConfirmed={setDeleteConfirmed}
    />
  );
};
export default DuoPhoneTable;
