import { IStageConfig } from '../interfaces/main';

export const defaults: IStageConfig = {
  TEAM_NAME_SHORT: 'CWE',
  TEAM_NAME_FULL: 'Controlled Working Environment',
  TEAM_DOC_URL:
    'https://w.amazon.com/bin/view/InnovationEngineering/ControlledWorkingEnvironment/',
  PRODUCT_DOC_URL:
    'https://w.amazon.com/bin/view/InnovationEngineering/ControlledWorkingEnvironment/'
};
