import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUserReactivationRequest } from '../../../../../interfaces/domain/userReactivationRequest';

import View from './view';

const UserReactivationAllRequestTable: FC = () => {
  const [userReactivationRequests, setUserReactivationRequests] = useState<
    IUserReactivationRequest[]
  >([]);
  const [selectedUserReactivationRequests, setSelectedUserReactivationRequests] =
    useState<IUserReactivationRequest[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    userReactivationRequestDetailsModalIsVisible,
    setUserReactivationRequestDetailsModalIsVisible
  ] = useState(false);

  useEffect(() => {
    getUserReactivationRequests().then((data) => {
      setUserReactivationRequests(data);
      setTableIsLoading(false);
    });
  }, []);

  const getUserReactivationRequests = () => {
    const api = new MainServiceApi();
    return api
      .getAllUserReactivationRequests()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to retrieve User Reactivation Requests!</>
          }
        ]);
        return [];
      });
  };

  const handleTableRowSelection = (
    userReactivationRequest: IUserReactivationRequest[]
  ) => {
    if (userReactivationRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedUserReactivationRequests(userReactivationRequest);
  };
  const refreshUserReactivationRequestTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getUserReactivationRequests().then((data) => {
      setUserReactivationRequests(data);
      setTableIsLoading(false);
    });
  };

  return (
    <View
      userReactivationRequests={userReactivationRequests}
      selectedUserReactivationRequests={selectedUserReactivationRequests}
      flashBarItems={flashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      refreshUserReactivationRequestTable={refreshUserReactivationRequestTable}
      userReactivationRequestDetailsModalIsVisible={
        userReactivationRequestDetailsModalIsVisible
      }
      setUserReactivationRequestDetailsModalIsVisible={
        setUserReactivationRequestDetailsModalIsVisible
      }
    />
  );
};
export default UserReactivationAllRequestTable;
