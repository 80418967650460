import { FC } from 'react';
import { HelpPanel } from '@awsui/components-react';

import { ConfigHelper } from '../../lib/config/helper';
import { Link } from 'react-router-dom';

const Toolbox: FC = () => {
  return (
    <div>
      <HelpPanel
        footer={
          <div>
            <ul>
              <li>
                <a
                  href={ConfigHelper.get().TEAM_DOC_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Team Documentation
                </a>
              </li>
            </ul>
          </div>
        }
        header={<h2> WorkSpaces Self-Service Portal</h2>}
      >
        <div>
          <p>
            This website is meant to allow users be able to request CWE
            accounts, request CWE WorkSpace. Once account and workspace are
            approved, users can self administer aspects of their AWS WorkSpace
            and DUO devices, and manage their CWE password.
          </p>
          <br />
          <Link to="/support/docs">User Documentation</Link>
        </div>
      </HelpPanel>
    </div>
  );
};

export default Toolbox;
