import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { IUser } from '../../../../../interfaces/domain/user';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

interface IndexProps {
  users: IUser[];
}

const UserDetails: FC<IndexProps> = ({ users }) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const [user, setUser] = useState<IUser>({
    userName: '',
    enabled: 0,
    displayName: '',
    firstName: '',
    middleInitial: '',
    lastName: '',
    workMailEmail: '',
    corpEmail: '',
    distinguishedName: '',
    workDocsId: '',
    workMailId: '',
    homeGroup: '',
    deprecated: 0,
    createdAt: 0,
    updatedAt: 0,
    passwordAge: 0,
    passwordExpiresAt: 0
  });

  useEffect(() => {
    const api = new MainServiceApi();

    for (const user of users) {
      api
        .getUserByUserName(user.userName)
        .then((data) => {
          setUser(data);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to get User data!</>
            }
          ]);
        });
    }
  }, [users]);

  return <View user={user} flashBarItems={flashBarItems} />;
};
export default UserDetails;
