import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUser } from '../../../../../interfaces/domain/user';

import View from './view';

const UsersTable: FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [provisionRequestModalIsVisible, setProvisionRequestModalIsVisible] =
  useState(false);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [userDetailModalIsVisible, setUserDetailModalIsVisible] =
    useState(false);
  const [
    userWorkSpacesDetailModalIsVisible,
    setUserWorkSpacesDetailModalIsVisible
  ] = useState(false);
  const [createUserFormModalIsVisible, setCreateUserFormModalIsVisible] =
    useState(false);
  const [editUserFormModalIsVisible, setEditUserFormModalIsVisible] =
    useState(false);

  useEffect(() => {
    getUsers().then((data) => {
      setUsers(data);
      setTableIsLoading(false);
    });
  }, []);

  const renderUserEnabledToggledText = (user: IUser) => {
    if (user.enabled) {
      return `${user.userName} has been locked`;
    } else {
      return `${user.userName} has been unlocked`;
    }
  };
  const getUsers = () => {
    const api = new MainServiceApi();
    return api
      .getAllUsers()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Users!</>
          }
        ]);

        return [];
      });
  };
  const usersIsEmpty = () => {
    return users.length === 0;
  };
  const handlePasswordReset = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    for (const selectedUser of selectedUsers) {
      api
        .requestPasswordReset({ userName: selectedUser.userName })
        .then((resp) => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>The password {selectedUser.userName} has been reset!</>
            }
          ]);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>Unable to reset the password for {selectedUser.userName}!</>
              )
            }
          ]);
        });
    }
  };

  const handleToggleAccountLock = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    for (const selectedUser of selectedUsers) {
      api
        .requestUpdateUserAccountEnabledStatus({
          userName: selectedUser.userName
        })
        .then(() => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>{renderUserEnabledToggledText(selectedUser)}</>
            }
          ]);
          refreshUserTable();
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>Unable to update the account for {selectedUser.userName}!</>
              )
            }
          ]);
        });
    }
  };
  const handleTableRowSelection = (user: IUser[]) => {
    if (user.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedUsers(user);
  };
  const refreshUserTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getUsers().then((data) => {
      setUsers(data);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      users={users}
      selectedUsers={selectedUsers}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      usersIsEmpty={usersIsEmpty}
      handlePasswordReset={handlePasswordReset}
      handleToggleAccountLock={handleToggleAccountLock}
      tableIsLoading={tableIsLoading}
      userDetailModalIsVisible={userDetailModalIsVisible}
      setUserDetailModalIsVisible={setUserDetailModalIsVisible}
      createUserFormModalIsVisible={createUserFormModalIsVisible}
      setCreateUserFormModalIsVisible={setCreateUserFormModalIsVisible}
      userWorkSpacesDetailModalIsVisible={userWorkSpacesDetailModalIsVisible}
      setUserWorkSpacesDetailModalIsVisible={
        setUserWorkSpacesDetailModalIsVisible
      }
      refreshUserTable={refreshUserTable}
      editUserFormModalIsVisible={editUserFormModalIsVisible}
      setEditUserFormModalIsVisible={setEditUserFormModalIsVisible}
      setProvisionRequestModalIsVisible={setProvisionRequestModalIsVisible}
      provisionRequestModalIsVisible={provisionRequestModalIsVisible}
    />
  );
};
export default UsersTable;
