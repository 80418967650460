import { FC } from 'react';

import {
  Button,
  SpaceBetween,
  FormField,
  Input,
  Form,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps } from '@awsui/components-react';
import { ICreateApprovedDomain } from '../../../../../interfaces/authentication/approvedDomain';

interface ViewProps {
  formValues: ICreateApprovedDomain;
  flashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: ICreateApprovedDomain) => Promise<void>;
}

const View: FC<ViewProps> = ({
  formValues,
  flashBarItems,
  handleSubmittedForm
}) => {
  const validationSchema = yup.object({
    domainName: yup
      .string()
      .matches(
        /^([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+$/,
        'Must be a valid email domain!'
      )
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={flashBarItems} />
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormField label="Domain Name" errorText={errors.domainName}>
              <Input
                name="domainName"
                onChange={({ detail }) => {
                  setFieldValue('domainName', detail.value);
                }}
                value={values.domainName}
                invalid={!!errors.domainName}
              />
            </FormField>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
