import { FC, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { ICreateApprovedDomain } from '../../../../../interfaces/authentication/approvedDomain';

import View from './view';

interface IndexProps {
  refreshTable: () => void;
}

const CreateForm: FC<IndexProps> = ({ refreshTable }) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const formValues: ICreateApprovedDomain = {
    domainName: ''
  };

  const handleSubmittedForm = async (data: ICreateApprovedDomain) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .createApprovedDomain(data)
      .then(() => {
        refreshTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Approved Domain has been created!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to create Approved Domain!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default CreateForm;
