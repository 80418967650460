import { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';

import View from './view';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IUserWorkSpaceProvisionRequest,
  IRejectUserWorkSpaceProvisionRequest
} from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';

interface IndexProps {
  userWorkSpaceProvisionRequests: IUserWorkSpaceProvisionRequest[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  setRejectUserWorkSpaceProvisionRequestModalIsVisible: Dispatch<
    SetStateAction<boolean>
  >;
  refreshUserWorkSpaceProvisionRequestTable: () => void;
}

const RejectUserWorkSpaceProvisionRequestForm: FC<IndexProps> = ({
  userWorkSpaceProvisionRequests,
  setRejectUserWorkSpaceProvisionRequestModalIsVisible,
  refreshUserWorkSpaceProvisionRequestTable,
  setFlashBarItems
}) => {
  const [uuid, setUuid] = useState('');
  const formValues: IRejectUserWorkSpaceProvisionRequest = {
    rejectionReason: ''
  };

  useEffect(() => {
    for (const userWorkSpaceProvisionRequest of userWorkSpaceProvisionRequests) {
      setUuid(userWorkSpaceProvisionRequest.uuid);
    }
  }, [userWorkSpaceProvisionRequests]);

  const handleSubmittedForm = async (
    data: IRejectUserWorkSpaceProvisionRequest
  ) => {
    setRejectUserWorkSpaceProvisionRequestModalIsVisible(false);

    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .rejectUserWorkSpaceProvisionRequest(uuid, data.rejectionReason)
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WorkSpace Provision Request was rejected successfully!</>
          }
        ]);
        refreshUserWorkSpaceProvisionRequestTable();
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to reject WorkSpace Provision Request!</>
          }
        ]);
      });
  };

  return (
    <View handleSubmittedForm={handleSubmittedForm} formValues={formValues} />
  );
};

export default RejectUserWorkSpaceProvisionRequestForm;
