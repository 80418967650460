import { FC } from 'react';

import {
  Button,
  SpaceBetween,
  FormField,
  Form,
  Flashbar,
  Checkbox,
  Input
} from '@awsui/components-react';

import { Formik } from 'formik';

import { FlashbarProps } from '@awsui/components-react';
import { IUpdateBundle } from '../../../../../interfaces/workSpaces/bundle';

interface ViewProps {
  formValues: IUpdateBundle;
  flashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: IUpdateBundle) => Promise<void>;
}

const View: FC<ViewProps> = ({
  formValues,
  flashBarItems,
  handleSubmittedForm
}) => {
  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={formValues}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSubmittedForm(data).then((resp) => {
            resetForm();
            setSubmitting(false);
          });
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
          <SpaceBetween direction="vertical" size="xs">
            <Flashbar items={flashBarItems} />
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="m">
              <FormField
                  label="Option Name"
                  description="This must be unique across all bundles."
                  errorText={errors.uiName}
                >
                  <Input
                    name="uiName"
                    value={values.uiName || ''}
                    onChange={({ detail }) =>
                      setFieldValue('uiName', detail.value)
                    }
                    invalid={!!errors.uiName}
                  />
                </FormField>
                <FormField label="Option Description" errorText={errors.uiDescription}>
                  <Input
                    name="uiDescription"
                    value={values.uiDescription || ''}
                    onChange={({ detail }) =>
                      setFieldValue('uiDescription', detail.value)
                    }
                    invalid={!!errors.uiDescription}
                  />
                </FormField>
                <FormField errorText={errors.deprecated}>
                  <Checkbox
                    name="deprecated"
                    checked={values.deprecated === 1}
                    onChange={({ detail }) =>
                      setFieldValue('deprecated', detail.checked ? 1 : 0)
                    }
                  >
                    Deprecated
                  </Checkbox>
                </FormField>
              </SpaceBetween>
            </Form>
          </SpaceBetween>
        )}
      </Formik>
    </>
  );
};
export default View;
