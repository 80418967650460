import { FC, Dispatch, SetStateAction } from 'react';

import {
  Flashbar,
  SpaceBetween,
  FlashbarProps
} from '@awsui/components-react';

import SMSVerifyForm from '.';
import ChildModal from '../../../../common/ChildModal';

// Wrapper for SMSVerifyForm to provide a modal with flashbar feedback
// (used by delete phone and disassociate token. Associate token already has a modal)
interface IndexProps {
  submitSMSCode: (smsCode: string) => Promise<void>;
  phoneNumber: string;
  header: string;
  isVisible: boolean;
  handleDismiss: () => void;
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>
}

const SMSVerifyModal: FC<IndexProps> = ({
  submitSMSCode,
  phoneNumber,
  header,
  isVisible,
  handleDismiss,
  flashBarItems,
  setFlashBarItems
}) => {
  return (
    <ChildModal
      onDismiss={handleDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header={header}
    >
      <SpaceBetween size="s">
        <Flashbar items={flashBarItems} />

        <SMSVerifyForm
          submitSMSCode={submitSMSCode}
          phoneNumber={phoneNumber}
          setFlashBarItems={setFlashBarItems}
        />
      </SpaceBetween>
    </ChildModal>
  );
};

export default SMSVerifyModal;
