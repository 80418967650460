import { FC, Dispatch, SetStateAction } from 'react';
import {
  Alert,
  Form,
  Button,
  SpaceBetween,
  FormField,
  Input,
  Select,
  Flashbar,
  Header,
  StatusIndicator,
  Checkbox,
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { ICreateDuoPhone } from '../../../../../../interfaces/integrations/duo';
import DuoPhoneActivationContent from '../duoPhoneActivationContent';
import SMSVerifyForm from '../../smsVerifyForm';
import ChildModal from '../../../../../common/ChildModal';

interface ViewProps {
  formValues: ICreateDuoPhone;
  handleSubmittedForm: (data: ICreateDuoPhone) => Promise<void>;
  duoPhonePlatformsSelectValues: SelectProps.Options;
  duoPhoneTypesSelectValues: SelectProps.Options;
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  blockingFlashBarItems: FlashbarProps.MessageDefinition[];
  duoPhoneId: string;
  blockForm: boolean;
  setActivationCodeModalIsVisible: Dispatch<SetStateAction<boolean>>;
  activationCodeModalIsVisible: boolean;
  showSMSVerify: boolean;
  setShowSMSVerify: Dispatch<SetStateAction<boolean>>;
  handleSubmitSMSCode: (smsCode: string) => Promise<void>;
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  duoPhonePlatformsSelectValues,
  duoPhoneTypesSelectValues,
  flashBarItems,
  setFlashBarItems,
  blockingFlashBarItems,
  duoPhoneId,
  blockForm,
  activationCodeModalIsVisible,
  setActivationCodeModalIsVisible,
  showSMSVerify,
  setShowSMSVerify,
  handleSubmitSMSCode
}) => {
  const validationSchema = yup.object({
    name: yup.string().matches(/^[a-zA-Z0-9 ]+$/).required(),
    number: yup
      .string()
      .required()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        'Phone number is not valid.'
      ),
    confirmNumber: yup.string().test('equal', 'Phone numbers must match', function (value) {
      return value === this.resolve(yup.ref('number'))
    }).required(),
    consented: yup.boolean().isTrue(),
    type: yup.string().required(),
    platform: yup.string().required()
  });

  const renderActivationCode = () => {
    if (duoPhoneId !== '') {
      return (
        <ChildModal
        onDismiss={() => {
          setActivationCodeModalIsVisible(false);
        }}
        visible={activationCodeModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header={
          <Header variant="h3" headingTagOverride="h5">
            <StatusIndicator type="warning">
              Action Required for your new DUO phone!
            </StatusIndicator>
          </Header>
        }
        >
            <DuoPhoneActivationContent 
            duoPhoneId={duoPhoneId}
             />
      </ChildModal>
      );
    }
  };

  return (
    <SpaceBetween size="s">
      <Flashbar items={flashBarItems} />

      { showSMSVerify ?
        <SMSVerifyForm
          submitSMSCode={handleSubmitSMSCode}
          handleCancel={() => setShowSMSVerify(false)}
          phoneNumber={formValues.number}
          setFlashBarItems={setFlashBarItems}
          isPhoneSetup={true}
        /> :
        <Formik
          initialValues={formValues}
          validateOnChange={true}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            await handleSubmittedForm(data);
            resetForm();
            setSubmitting(false);
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
            <SpaceBetween direction="vertical" size="xs">
              <Flashbar items={flashBarItems} />
              {
                blockForm ?
                <></> :
                <Alert statusIconAriaLabel="Info" type="info">
                  Registering your phone enrolls it as a DUO MFA device for your CWE account.
                  <br></br>
                  This number is also sent SMS verification codes if you change DUO devices in the future.
                </Alert>
              }
              {renderActivationCode()}
              <Flashbar items={blockingFlashBarItems} />
              <Form
                actions={
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting || blockForm}
                  >
                    Continue
                  </Button>
                }
              >
                <SpaceBetween direction="vertical" size="xs">
                  <FormField label="Name" errorText={errors.name}>
                    <Input
                      value={values.name}
                      onChange={({ detail }) => setFieldValue('name', detail.value)}
                      onBlur={() => setFieldValue('name', values.name.trim())}
                      invalid={!!errors.name}
                    />
                  </FormField>
                  <FormField label="Phone Number" errorText={errors.number}>
                    <Input
                      value={values.number}
                      onChange={({ detail }) =>
                        setFieldValue('number', detail.value)
                      }
                      onBlur={() => setFieldValue('number', values.number.trim())}
                      invalid={!!errors.number}
                    />
                  </FormField>

                  <FormField label="Confirm Phone Number" errorText={errors.confirmNumber}>
                    <Input
                      value={values.confirmNumber}
                      onChange={({ detail }) =>
                        setFieldValue('confirmNumber', detail.value)
                      }
                      onBlur={() => setFieldValue('confirmNumber', values.confirmNumber.trim())}
                      invalid={!!errors.confirmNumber}
                    />
                  </FormField>

                  <FormField label="Phone Type" errorText={errors.type}>
                    <Select
                      selectedOption={{ value: values.type }}
                      onChange={({ detail }) =>
                        setFieldValue('type', detail.selectedOption.value)
                      }
                      options={duoPhoneTypesSelectValues}
                      selectedAriaLabel="Selected"
                      filteringType="auto"
                    />
                  </FormField>

                  <FormField label="Phone Platform" errorText={errors.platform}>
                    <Select
                      selectedOption={{ value: values.platform }}
                      onChange={({ detail }) =>
                        setFieldValue('platform', detail.selectedOption.value)
                      }
                      options={duoPhonePlatformsSelectValues}
                      selectedAriaLabel="Selected"
                      filteringType="auto"
                    />
                  </FormField>

                  <FormField errorText={errors.consented}>
                    <Checkbox
                      onChange={({ detail }) =>
                        setFieldValue('consented', detail.checked)
                      }
                      checked={values.consented}
                    >
                      I agree to receive an SMS verification code at the provided phone number
                      each time I add or remove DUO devices in the future, as well as an initial
                      verification code when I press <b>Continue</b> below. Message and data rates may apply.
                    </Checkbox>
                  </FormField>
                </SpaceBetween>
              </Form>
            </SpaceBetween>
          )}
        </Formik>
      }
    </SpaceBetween>
  );
};
export default View;
