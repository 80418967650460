import { FC, useEffect, useState } from 'react';

import { useInterval } from '../../../common/CustomHooks';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import {
  ICreateUserAccountRequest,
  IUserAccountRequest
} from '../../../../interfaces/domain/userAccountRequest';

import View from './view';

const CreateUserAccountRequest: FC<{ logOut: () => void }> = ({ logOut }) => {
  const [userHasPendingRequest, setUserHasPendingRequest] = useState(false);
  const [userAccountRequests, setUserAccountRequests] = useState<
    IUserAccountRequest[]
  >([]);
  const [homeGroupSelectValues, setHomeGroupSelectValues] =
    useState<SelectProps.Options>([]);
  const [selectedHomeGroup, setSelectedHomeGroup] = useState('');
  const [bundleSelectValues, setBundleSelectValues] =
    useState<SelectProps.Options>([]);
  const [directorySelectValues, setDirectorySelectValues] =
    useState<SelectProps.Options>([]);
  const [allowAutoProvision, setAllowAutoProvision] = useState(false);

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const formValues: ICreateUserAccountRequest = {
    firstName: '',
    lastName: '',
    homeGroup: '',
    bundle: { uiName: '', uiDescription: '' },
    directory: { uiName: '', uiDescription: '' },
    ticketLink: '',
    justification: ''
  };

  useInterval(() => {
    const api = new MainServiceApi();

    refreshUserAccountRequests();

    api
      .getHomeGroupsByUserDomain()
      .then((data) => {
        let tempHomeGroupSelectValues = data
          .map((homeGroup) => ({
            label: homeGroup.groupName,
            value: homeGroup.groupName
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        setHomeGroupSelectValues(tempHomeGroupSelectValues);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Home Groups!</>
          }
        ]);
      });
  }, []);

  useEffect(() => {
    if (selectedHomeGroup) {
      const api = new MainServiceApi();

      Promise.all([
        api.getSimpleBundlesByHomeGroup(selectedHomeGroup),
        api.getSimpleDirectoriesByHomeGroup(selectedHomeGroup)
      ]).then(([bundles, directories]) => {
        setBundleSelectValues(
          bundles.map((bundle) => ({
            label: bundle.uiName,
            value: bundle.uiName,
            description: bundle.uiDescription
          }))
        );

        setDirectorySelectValues(
          directories.map((directory) => ({
            label: directory.uiName,
            value: directory.uiName,
            description: directory.uiDescription
          }))
        );
      }).catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get WorkSpace bundle/directory options!</>
          }
        ]);
      });
    }
  }, [selectedHomeGroup]);

  useEffect(() => {
    const api = new MainServiceApi();

    api
      .getPortalSettingsByNames(['enableAutoProvision'])
      .then((settings) => {
        setAllowAutoProvision(settings.enableAutoProvision);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to check if WorkSpace can be auto-provisioned!</>
          }
        ]);
      });
  });

  const refreshUserAccountRequests = () => {
    const api = new MainServiceApi();

    api
      .getUserAccountRequestsForSelf()
      .then((data) => {
        data.sort((a, b) => {
          const x = b.createdAt;
          const y = a.createdAt;
          return x < y ? -1 : x > y ? 1 : 0;
        });

        setUserAccountRequests(data);

        for (const request of data) {
          if (request.requestStatus === 'Pending') {
            setUserHasPendingRequest(true);
            setFlashBarItems([
              {
                type: 'info',
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setFlashBarItems([]),
                content: <>You already have a pending User Account Request!</>
              }
            ]);

            return;
          }
        }
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get User Account Requests!</>
          }
        ]);
      });
  };

  const handleSubmittedForm = async (data: ICreateUserAccountRequest) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const { bundle, directory, ...userData } = data;

    const api = new MainServiceApi();
    api
      .createUserAccountRequest({
        ...(
          allowAutoProvision
            ? { bundleUiName: bundle.uiName, directoryUiName: directory.uiName }
            : {}
        ),
        ...userData
      })
      .then(() => {
        refreshUserAccountRequests();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>User Account Request has been created!</>
          }
        ]);
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to create User Account Request! - {`${err}`}</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      homeGroupSelectValues={homeGroupSelectValues}
      setSelectedHomeGroup={setSelectedHomeGroup}
      bundleSelectValues={bundleSelectValues}
      directorySelectValues={directorySelectValues}
      flashBarItems={flashBarItems}
      userAccountRequests={userAccountRequests}
      userHasPendingRequest={userHasPendingRequest}
      allowAutoProvision={allowAutoProvision}
      logOut={logOut}
    />
  );
};
export default CreateUserAccountRequest;
