import { FC, Dispatch, SetStateAction } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Input,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps } from '@awsui/components-react';
import { IAssociateDuoToken } from '../../../../../../interfaces/integrations/duo';

import SMSVerifyForm from '../../smsVerifyForm';

interface ViewProps {
  formValues: IAssociateDuoToken;
  phoneNumber: string;
  showSMSVerify: boolean;
  setShowSMSVerify: Dispatch<SetStateAction<boolean>>
  handleSubmittedForm: (data: IAssociateDuoToken) => Promise<void>;
  handleSubmitSMSCode: (smsCode: string) => Promise<void>;
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>
}

const View: FC<ViewProps> = ({
  formValues,
  phoneNumber,
  showSMSVerify,
  setShowSMSVerify,
  handleSubmittedForm,
  handleSubmitSMSCode,
  flashBarItems,
  setFlashBarItems
}) => {
  const validationSchema = yup.object({
    serial: yup.string().matches(/^[a-zA-Z0-9]+$/).max(128).required()
  });

  return (
    <SpaceBetween size="s">
      <Flashbar items={flashBarItems} />
      
      { showSMSVerify ?
        <SMSVerifyForm
          submitSMSCode={handleSubmitSMSCode}
          handleCancel={() => setShowSMSVerify(false)}
          phoneNumber={phoneNumber}
          setFlashBarItems={setFlashBarItems}
        /> :
        <Formik
          initialValues={formValues}
          validateOnChange={true}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);

            handleSubmittedForm(data).then((resp) => {
              setSubmitting(false);
            });
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
            <SpaceBetween direction="vertical" size="xs">
              <Form
                actions={
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                }
              >
                <FormField label="Serial" errorText={errors.serial}>
                  <Input
                    value={values.serial}
                    onChange={({ detail }) => setFieldValue('serial', detail.value)}
                    onBlur={() =>
                      setFieldValue(
                        'serial',
                        values.serial.replace(/[^a-zA-Z0-9]/g, '').trim()
                      )
                    }
                    invalid={!!errors.serial}
                  />
                </FormField>
              </Form>
            </SpaceBetween>
          )}
        </Formik>
      }
    </SpaceBetween>
  );
};
export default View;
