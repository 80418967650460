import { useEffect, useRef } from 'react';

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type Delay = number | null;
type TimerHandler = (...args: any[]) => void;

export const useInterval = (
  callback: TimerHandler,
  dependencies: any[],
  delay: Delay=30000,
  initialCall: boolean=true
) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (initialCall) {
      handler();
    }

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, delay]);
};
