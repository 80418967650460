import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';

import UserAccountRequestDetails from '../userAccountRequestDetails';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  userAccountRequests: IUserAccountRequest[];
  selectedUserAccountRequests: IUserAccountRequest[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  disableActionButtons: boolean;
  handleTableRowSelection: (user: IUserAccountRequest[]) => void;
  tableIsLoading: boolean;
  refreshUserAccountRequestTable: () => void;
  setUserAccountRequestDetailsModalIsVisible: Dispatch<SetStateAction<boolean>>;
  userAccountRequestDetailsModalIsVisible: boolean;
}

const View: FC<ViewProps> = ({
  userAccountRequests,
  selectedUserAccountRequests,
  flashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  tableIsLoading,
  refreshUserAccountRequestTable,
  userAccountRequestDetailsModalIsVisible,
  setUserAccountRequestDetailsModalIsVisible
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No User Account Requests</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No User Account Requests to display.
        </Box>
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching User Account Requests</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(userAccountRequests, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: [
        'firstName',
        'lastName',
        'corpEmail',
        'homeGroup',
        'justification'
      ]
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredUserAccountRequests = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshUserAccountRequestTable()}
          />
          <Button
            variant="primary"
            disabled={disableActionButtons}
            onClick={() => setUserAccountRequestDetailsModalIsVisible(true)}
          >
            View
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredUserAccountRequests.length
            ? `(${filteredUserAccountRequests.length}/${userAccountRequests.length})`
            : `(${userAccountRequests.length})`
        }
        actions={renderTableActions()}
      >
        All User Account Requests
      </Header>
    );
  };

  const renderUserAccountRequestDetailsModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setUserAccountRequestDetailsModalIsVisible(false);
        }}
        visible={userAccountRequestDetailsModalIsVisible}
        closeAriaLabel="Close modal"
        size="large"
        header="User Account Request Details"
      >
        <UserAccountRequestDetails
          selectedUserAccountRequests={selectedUserAccountRequests}
        />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'firstName',
      header: 'First Name',
      cell: (item: IUserAccountRequest) => item.firstName || '-'
    },
    {
      id: 'lastName',
      header: 'Last Name',
      cell: (item: IUserAccountRequest) => item.lastName || '-'
    },
    {
      id: 'corpEmail',
      header: 'Email',
      cell: (item: IUserAccountRequest) => item.corpEmail || '-'
    },
    {
      id: 'homeGroup',
      header: 'Home Group',
      cell: (item: IUserAccountRequest) => item.homeGroup || '-'
    },
    {
      id: 'justification',
      header: 'Justification',
      cell: (item: IUserAccountRequest) => item.justification || '-'
    }
  ];

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedUserAccountRequests}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Users"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Users"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderUserAccountRequestDetailsModal()}
    </>
  );
};
export default View;
