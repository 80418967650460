import { FC, useState, useEffect, useContext } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';
import { GlobalContext } from '../../../../../../context/globalContext';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoToken } from '../../../../../../interfaces/integrations/duo';

import View from './view';

const DuoTokenTable: FC = () => {
  const {
    currentUser: { userName }
  } = useContext(GlobalContext);

  const [duoTokens, setDuoTokens] = useState<IDuoToken[]>([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  //this needs to be an array because polaris.. ugh
  const [selectedDuoTokens, setSelectedDuoTokens] = useState<IDuoToken[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [associateDuoTokenModalIsVisible, setAssociateDuoTokenModalIsVisible] =
    useState(false);
  const [disassociateDuoTokenModalIsVisible, setDisassociateDuoTokenModalIsVisible] =
    useState(false);

  useEffect(() => {
    getDuoDevices().then((data) => {
      setDuoTokens(data);
      setTableIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  const getDuoDevices = () => {
    const api = new MainServiceApi();
    return api
      .getDuoDevicesByUserName()
      .then((data) => {
        if (data.phones.length > 0) {
          setPhoneNumber(data.phones[0].number);
        } else {
          setPhoneNumber('');
          setFlashBarItems([
            {
              type: 'warning',
              dismissible: false,
              content: 'You have no DUO Phone - you will not be able to change hardware tokens. Contact support if you need assistance.'
            }
          ]);
        }

        return data.tokens;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get DUO Tokens!</>
          }
        ]);
        return [];
      });
  };
  const duoTokensIsEmpty = () => {
    return duoTokens.length === 0;
  };

  const handleTableRowSelection = (duoToken: IDuoToken[]) => {
    if (duoToken.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedDuoTokens(duoToken);
  };
  const refreshDuoTokenTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getDuoDevices().then((data) => {
      setDuoTokens(data);
      setTableIsLoading(false);
    });
  };

  // Step 1: generate SMS verification code
  const handleResetSMS = async () => {
    const api = new MainServiceApi();

    return api.resetSMS().then(() => {
      setDisassociateDuoTokenModalIsVisible(true);
      setFlashBarItems([
        {
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Sent SMS code for verification - enter it below</>
        }
      ]);
    }).catch(() => {
      setFlashBarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Failed to send SMS verification - try again later</>
        }
      ]);
    });
  };

  // Step 2: collect SMS code and perform the disassociation
  const handleDisassociation = async (smsCode: string) => {
    const api = new MainServiceApi();

    setDisableActionButtons(true);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    for (const duoToken of selectedDuoTokens) {
      return api
        .disassociateDuoToken({ tokenId: duoToken.token_id, smsCode })
        .then((resp) => {
          setDisassociateDuoTokenModalIsVisible(false);
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>DUO Token has been disassociated!</>
            }
          ]);
          refreshDuoTokenTable();
        })
        .catch((err) => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to disassociate DUO Token! - {err}</>
            }
          ]);
        });
    }
  };

  return (
    <View
      duoTokens={duoTokens}
      phoneNumber={phoneNumber}
      selectedDuoTokens={selectedDuoTokens}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      duoTokensIsEmpty={duoTokensIsEmpty}
      tableIsLoading={tableIsLoading}
      refreshDuoTokenTable={refreshDuoTokenTable}
      handleDisassociation={handleDisassociation}
      handleResetSMS={handleResetSMS}
      associateDuoTokenModalIsVisible={associateDuoTokenModalIsVisible}
      setAssociateDuoTokenModalIsVisible={setAssociateDuoTokenModalIsVisible}
      disassociateDuoTokenModalIsVisible={disassociateDuoTokenModalIsVisible}
      setDisassociateDuoTokenModalIsVisible={setDisassociateDuoTokenModalIsVisible}
    />
  );
};
export default DuoTokenTable;
