import { FC, useState } from 'react';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';

import View from './view';

const PortalSyncForm: FC = () => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const handleSyncActiveDirectory = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .syncActiveDirectory()
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: (
              <>
                Active Directory sync was initiated successfully. Please allow
                10-15 mins to fully sync!
              </>
            )
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Active Directory Sync failed!</>
          }
        ]);
      });
  };
  const handleSyncWorkSpaces = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);
    const api = new MainServiceApi();
    api
      .syncWorkSpaces()
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WorkSpaces Sync was completed!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WorkSpaces Sync failed!</>
          }
        ]);
      });
  };
  const handleSyncWorkSpaceBundles = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .syncWorkSpaceBundles()
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WorkSpace Bundles Sync was completed!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WorkSpace Bundles Sync failed!</>
          }
        ]);
      });
  };

  const handleSyncWorkSpaceDirectories = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .syncWorkSpaceDirectories()
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WorkSpace Directories Sync was completed!</>
          }
        ]);
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WorkSpace Directories Sync failed!</>
          }
        ]);
      });
  };

  return (
    <View
      handleSyncActiveDirectory={handleSyncActiveDirectory}
      handleSyncWorkSpaces={handleSyncWorkSpaces}
      handleSyncWorkSpaceBundles={handleSyncWorkSpaceBundles}
      handleSyncWorkSpaceDirectories={handleSyncWorkSpaceDirectories}
      flashBarItems={flashBarItems}
    />
  );
};
export default PortalSyncForm;
