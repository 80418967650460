import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IUpdateBundle,
  IBundle
} from '../../../../../interfaces/workSpaces/bundle';

import View from './view';

interface IndexProps {
  refreshBundleTable: () => void;
  bundles: IBundle[];
}

const EditWorkSpaceBundleForm: FC<IndexProps> = ({
  refreshBundleTable,
  bundles
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [bundleId, setBundleId] = useState('');
  const [formValues, setFormValues] = useState<IUpdateBundle>({
    deprecated: 0,
    uiName: '',
    uiDescription: ''
  });

  useEffect(() => {
    for (const bundle of bundles) {
      setBundleId(bundle.bundleId);
      setFormValues({
        deprecated: bundle.deprecated,
        uiName: bundle.uiName || '',
        uiDescription: bundle.uiDescription || ''
      });
    }
  }, [bundles]);

  const handleSubmittedForm = async (data: IUpdateBundle) => {
    setFormValues(data);

    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateBundle(bundleId, data)
      .then(() => {
        refreshBundleTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WS Bundle has been updated!</>
          }
        ]);
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update WS Bundle! - {err}</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default EditWorkSpaceBundleForm;
