import { Dispatch, FC, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Box,
  TextFilter,
  Pagination,
  Flashbar
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { IUserWorkSpaceProvisionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';
import { FlashbarProps } from '@awsui/components-react';

import UserWorkSpaceProvisionRequestDetails from '../userWorkSpaceProvisionRequestDetails';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  userWorkSpaceProvisionRequests: IUserWorkSpaceProvisionRequest[];
  selectedUserWorkSpaceProvisionRequests: IUserWorkSpaceProvisionRequest[];
  disableActionButtons: boolean;
  handleTableRowSelection: (user: IUserWorkSpaceProvisionRequest[]) => void;
  tableIsLoading: boolean;
  refreshUserWorkSpaceProvisionRequestTable: () => void;
  userWorkSpaceProvisionRequestDetailsModalIsVisible: boolean;
  setUserWorkSpaceProvisionRequestDetailsModalIsVisible: Dispatch<
    SetStateAction<boolean>
  >;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({
  userWorkSpaceProvisionRequests,
  selectedUserWorkSpaceProvisionRequests,
  handleTableRowSelection,
  disableActionButtons,
  tableIsLoading,
  refreshUserWorkSpaceProvisionRequestTable,
  userWorkSpaceProvisionRequestDetailsModalIsVisible,
  setUserWorkSpaceProvisionRequestDetailsModalIsVisible,
  flashBarItems
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No All WorkSpace Provision Requests</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No WorkSpace Provisiont Requests to display.
        </Box>
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching User WorkSpace Provision Requests</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(userWorkSpaceProvisionRequests, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['firstName', 'lastName', 'corpEmail', 'bundleId', 'directoryId']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredUserWorkSpaceProvisionRequests = [] } =
    collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshUserWorkSpaceProvisionRequestTable()}
          />
          <Button
            variant="primary"
            disabled={disableActionButtons}
            onClick={() =>
              setUserWorkSpaceProvisionRequestDetailsModalIsVisible(true)
            }
          >
            View
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredUserWorkSpaceProvisionRequests.length
            ? `(${filteredUserWorkSpaceProvisionRequests.length}/${userWorkSpaceProvisionRequests.length})`
            : `(${userWorkSpaceProvisionRequests.length})`
        }
        actions={renderTableActions()}
      >
        All WorkSpace Provision Requests
      </Header>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'userName',
      header: 'Username',
      cell: (item: IUserWorkSpaceProvisionRequest) => item.userName || '-'
    },
    {
      id: 'corpEmail',
      header: 'Corp Email',
      cell: (item: IUserWorkSpaceProvisionRequest) => item.corpEmail || '-'
    },
    {
      id: 'bundleId',
      header: 'Bundle ID',
      cell: (item: IUserWorkSpaceProvisionRequest) => item.bundleId || '-'
    },
    {
      id: 'directoryId',
      header: 'Directory ID',
      cell: (item: IUserWorkSpaceProvisionRequest) => item.directoryId || '-'
    },
    {
      id: 'justification',
      header: 'Justification',
      cell: (item: IUserWorkSpaceProvisionRequest) => item.justification || '-'
    }
  ];

  const renderUserWorkSpaceProvisionRequestDetailsModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setUserWorkSpaceProvisionRequestDetailsModalIsVisible(false);
        }}
        visible={userWorkSpaceProvisionRequestDetailsModalIsVisible}
        closeAriaLabel="Close modal"
        size="large"
        header="WorkSpace Provision Request Details"
      >
        <UserWorkSpaceProvisionRequestDetails
          selectedUserWorkSpaceProvisionRequests={
            selectedUserWorkSpaceProvisionRequests
          }
        />
      </ChildModal>
    );
  };

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedUserWorkSpaceProvisionRequests}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Users"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Users"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderUserWorkSpaceProvisionRequestDetailsModal()}
    </>
  );
};
export default View;
