import { FC } from 'react';
import {
  Form,
  Button,
  Select,
  SpaceBetween,
  FormField,
  Textarea,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { ICreateUserWorkSpaceProvisionRequest, ICreateUserWorkSpaceRequest } from '../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';
import { SelectProps, FlashbarProps } from '@awsui/components-react';

interface ViewProps {
  formValues: ICreateUserWorkSpaceProvisionRequest | ICreateUserWorkSpaceRequest;
  handleSubmittedForm: (
    data: ICreateUserWorkSpaceProvisionRequest | ICreateUserWorkSpaceRequest
  ) => Promise<void>;
  modalFlashBarItems: FlashbarProps.MessageDefinition[];
  bundleSelectValues: SelectProps.Options;
  adDirectorySelectValues: SelectProps.Options;
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  bundleSelectValues,
  modalFlashBarItems,
  adDirectorySelectValues
}) => {
  const validationSchema = yup.object({
    bundleId: yup.string().required(),
    directoryId: yup.string().required(),
    justification: yup.string().required()
  });

  return (
    <Formik
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={modalFlashBarItems} />
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="m">
              <FormField label="Bundle" errorText={errors.bundleId}>
                <Select
                  selectedOption={{ value: values.bundleId }}
                  onChange={({ detail }) =>
                    setFieldValue('bundleId', detail.selectedOption.value)
                  }
                  options={bundleSelectValues}
                  placeholder="Choose an Bundle"
                  selectedAriaLabel="Selected"
                  invalid={!!errors.bundleId}
                  filteringType="auto"
                />
              </FormField>

              <FormField label="Directory ID" errorText={errors.directoryId}>
                <Select
                  selectedOption={{
                    value: values.directoryId
                  }}
                  onChange={({ detail }) =>
                    setFieldValue('directoryId', detail.selectedOption.value)
                  }
                  options={adDirectorySelectValues}
                  placeholder="Choose an Directory ID"
                  selectedAriaLabel="Selected"
                  invalid={!!errors.directoryId}
                  filteringType="auto"
                />
              </FormField>

              <FormField
                description="Justification for needing to provision a workspace."
                label="Justification"
                errorText={errors.justification}
              >
                <Textarea
                  value={values.justification}
                  ariaRequired
                  onChange={({ detail }) =>
                    setFieldValue('justification', detail.value)
                  }
                  invalid={!!errors.justification}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
