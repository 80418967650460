import { FC, useState, useEffect } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import View from './view';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import {
  IUserWorkSpaceProvisionRequest,
  IUpdateUserWorkSpaceProvisionRequest
} from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';

interface IndexProps {
  userWorkSpaceProvisionRequests: IUserWorkSpaceProvisionRequest[];
  refreshUserWorkSpaceProvisionRequestTable: () => void;
}
const EditUserWorkSpaceProvisionRequestForm: FC<IndexProps> = ({
  userWorkSpaceProvisionRequests,
  refreshUserWorkSpaceProvisionRequestTable
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const [uuid, setUuid] = useState('');
  const [userName, setUserName] = useState('');

  const [formValues, setFormValues] =
    useState<IUpdateUserWorkSpaceProvisionRequest>({
      bundleId: '',
      directoryId: ''
    });

  const [bundleSelectValues, setBundleSelectValues] =
    useState<SelectProps.Options>([]);
  const [directorySelectValues, setDirectorySelectValues] =
    useState<SelectProps.Options>([]);

  useEffect(() => {
    for (const userWorkSpaceProvisionRequest of userWorkSpaceProvisionRequests) {
      setFormValues((prevState) => ({
        ...prevState,
        ...{
          bundleId: userWorkSpaceProvisionRequest.bundleId,
          directoryId: userWorkSpaceProvisionRequest.directoryId
        }
      }));
      setUuid(userWorkSpaceProvisionRequest.uuid);
      setUserName(userWorkSpaceProvisionRequest.userName);
    }
  }, [userWorkSpaceProvisionRequests]);

  useInterval(() => {
    if (userName) {
      const api = new MainServiceApi();
      api
        .getHomeGroupByUser(userName)
        .then((data) => {
          const tempBundlesSelectValues = data.bundles
            .map((bundle) => ({
              label: bundle.bundleName,
              value: bundle.bundleId
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

          setBundleSelectValues(tempBundlesSelectValues);

          const tempDirectoriesSelectValues = data.adDirectories.map(
            (directory) => ({
              label: directory.alias,
              value: directory.directoryId
            })
          );

          setDirectorySelectValues(tempDirectoriesSelectValues);
        })
        .catch(() => {
          setFlashBarItems((prevState) => [
            ...prevState,
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to get Bundles!</>
            }
          ]);
        });
    }
  }, [userName]);

  const handleSubmittedForm = async (
    data: IUpdateUserWorkSpaceProvisionRequest
  ) => {
    setFormValues(data);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateUserWorkSpaceProvisionRequest(uuid, data)
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: (
              <>User WorkSpace Provision Request was updated successfully!</>
            )
          }
        ]);
        refreshUserWorkSpaceProvisionRequestTable();
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <> Unable to update User WorkSpace Provision Request!</>
          }
        ]);
      });
  };

  return (
    <View
      handleSubmittedForm={handleSubmittedForm}
      formValues={formValues}
      bundleSelectValues={bundleSelectValues}
      directorySelectValues={directorySelectValues}
      flashBarItems={flashBarItems}
    />
  );
};

export default EditUserWorkSpaceProvisionRequestForm;
