export const darkModeEnabled = () => {
  const resp = localStorage.getItem('darkModeEnabled') || 'false';

  return resp === 'true';
};

export const setDarkModeEnabled = (desired: boolean) => {
  localStorage.setItem('darkModeEnabled', desired.toString());

  if (desired && !document.body.classList.contains('awsui-dark-mode')) {
    document.body.classList.toggle('awsui-dark-mode');
  } else if (!desired && document.body.classList.contains('awsui-dark-mode')) {
    document.body.classList.toggle('awsui-dark-mode');
  }
};

export const processDarkModeChange = (desired: boolean) => {
  if (desired && !document.body.classList.contains('awsui-dark-mode')) {
    document.body.classList.toggle('awsui-dark-mode');
  } else if (!desired && document.body.classList.contains('awsui-dark-mode')) {
    document.body.classList.toggle('awsui-dark-mode');
  }
};
