import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { ISupportTopic } from '../../../../../interfaces/portal/supportTopic';

import CreateSupportTopicForm from '../createSupportTopicForm';
import EditSupportTopicForm from '../editSupportTopicForm';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  supportTopics: ISupportTopic[];
  selectedSupportTopics: ISupportTopic[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (supportTopic: ISupportTopic[]) => void;
  supportTopicsIsEmpty: () => boolean;
  tableIsLoading: boolean;
  createSupportTopicFormModalIsVisible: boolean;
  setCreateSupportTopicFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  editSupportTopicFormModalIsVisible: boolean;
  setEditSupportTopicFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshSupportTopicTable: () => void;
}

const View: FC<ViewProps> = ({
  supportTopics,
  selectedSupportTopics,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  supportTopicsIsEmpty,
  tableIsLoading,
  createSupportTopicFormModalIsVisible,
  setCreateSupportTopicFormModalIsVisible,
  editSupportTopicFormModalIsVisible,
  setEditSupportTopicFormModalIsVisible,
  refreshSupportTopicTable
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No Support Topics</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No Support Topics to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            onClick={() => {
              setCreateSupportTopicFormModalIsVisible(true);
            }}
          >
            Create Support Topic
          </Button>
        }
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching Support Topics</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(supportTopics, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['name']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredSupportTopics = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshSupportTopicTable()}
          />

          <Button
            iconName="add-plus"
            disabled={supportTopicsIsEmpty()}
            onClick={() => {
              setCreateSupportTopicFormModalIsVisible(true);
            }}
          >
            Create
          </Button>
          <Button
            iconName="settings"
            disabled={disableActionButtons}
            onClick={() => {
              setEditSupportTopicFormModalIsVisible(true);
            }}
          >
            Edit
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredSupportTopics.length
            ? `(${filteredSupportTopics.length}/${supportTopics.length})`
            : `(${supportTopics.length})`
        }
        actions={renderTableActions()}
      >
        Support Topics
      </Header>
    );
  };

  const renderCreateSupportTopicFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setCreateSupportTopicFormModalIsVisible(false);
        }}
        visible={createSupportTopicFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="New Support Topic"
      >
        <CreateSupportTopicForm
          refreshSupportTopicTable={refreshSupportTopicTable}
        />
      </ChildModal>
    );
  };

  const renderEditSupportTopicFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setEditSupportTopicFormModalIsVisible(false);
        }}
        visible={editSupportTopicFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Edit Support Topic"
      >
        <EditSupportTopicForm
          refreshSupportTopicTable={refreshSupportTopicTable}
          supportTopics={selectedSupportTopics}
        />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'topicName',
      header: 'Name',
      cell: (item: ISupportTopic) => item.topicName || '-'
    },
    {
      id: 'deprecated',
      header: 'Deprecated',
      cell: (item: ISupportTopic) => item.deprecated.toString() || '-'
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedSupportTopics}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Support Topics"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Support Topics"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderCreateSupportTopicFormModal()}
      {renderEditSupportTopicFormModal()}
    </>
  );
};
export default View;
