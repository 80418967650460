import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { IUserWorkSpaceProvisionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

const UserWorkSpaceAllProvisionRequestTable: FC = () => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const [userWorkSpaceProvisionRequests, setUserWorkSpaceProvisionRequests] =
    useState<IUserWorkSpaceProvisionRequest[]>([]);
  const [
    selectedUserWorkSpaceProvisionRequests,
    setSelectedUserWorkSpaceProvisionRequests
  ] = useState<IUserWorkSpaceProvisionRequest[]>([]);

  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    userWorkSpaceProvisionRequestDetailsModalIsVisible,
    setUserWorkSpaceProvisionRequestDetailsModalIsVisible
  ] = useState(false);

  useEffect(() => {
    getUserWorkSpaceProvisionRequests().then((data) => {
      setUserWorkSpaceProvisionRequests(data);
      setTableIsLoading(false);
    });
  }, []);

  const getUserWorkSpaceProvisionRequests = () => {
    const api = new MainServiceApi();
    return api
      .getAllUserWorkSpaceProvisionRequests()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get All User WorkSpace Provision Requests!</>
          }
        ]);

        return [];
      });
  };

  const handleTableRowSelection = (
    userWorkSpaceProvisionRequest: IUserWorkSpaceProvisionRequest[]
  ) => {
    if (userWorkSpaceProvisionRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedUserWorkSpaceProvisionRequests(userWorkSpaceProvisionRequest);
  };
  const refreshUserWorkSpaceProvisionRequestTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getUserWorkSpaceProvisionRequests().then((data) => {
      setUserWorkSpaceProvisionRequests(data);
      setTableIsLoading(false);
    });
  };

  return (
    <View
      userWorkSpaceProvisionRequests={userWorkSpaceProvisionRequests}
      selectedUserWorkSpaceProvisionRequests={
        selectedUserWorkSpaceProvisionRequests
      }
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      refreshUserWorkSpaceProvisionRequestTable={
        refreshUserWorkSpaceProvisionRequestTable
      }
      userWorkSpaceProvisionRequestDetailsModalIsVisible={
        userWorkSpaceProvisionRequestDetailsModalIsVisible
      }
      setUserWorkSpaceProvisionRequestDetailsModalIsVisible={
        setUserWorkSpaceProvisionRequestDetailsModalIsVisible
      }
      flashBarItems={flashBarItems}
    />
  );
};
export default UserWorkSpaceAllProvisionRequestTable;
