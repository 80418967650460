import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Select,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { IUpdateUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';

interface ViewProps {
  formValues: IUpdateUserAccountRequest;
  modelFlashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: IUpdateUserAccountRequest) => Promise<void>;
  homeGroupSelectValues: SelectProps.Options;
  bundleSelectValues: SelectProps.Options;
  directorySelectValues: SelectProps.Options;
  allowAutoProvision: boolean;
}

const View: FC<ViewProps> = ({
  formValues,
  modelFlashBarItems,
  handleSubmittedForm,
  homeGroupSelectValues,
  bundleSelectValues,
  directorySelectValues,
  allowAutoProvision
}) => {
  const validationSchema = yup.object({
    homeGroup: yup.string().required(),
    bundleUiName: allowAutoProvision ? yup.string().required() : yup.string(),
    directoryUiName: allowAutoProvision ? yup.string().required() : yup.string()
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSubmittedForm(data).then(() => {
            resetForm();
            setSubmitting(false);
          });
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
          <SpaceBetween direction="vertical" size="xs">
            <Flashbar items={modelFlashBarItems} />
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="m">
                <FormField label="Home Group" errorText={errors.homeGroup}>
                  <Select
                    selectedOption={{
                      value: values.homeGroup,
                      label: values.homeGroup
                    }}
                    onChange={({ detail }) => {
                      setFieldValue('homeGroup', detail.selectedOption.value);
                    }}
                    options={homeGroupSelectValues}
                    invalid={!!errors.homeGroup}
                    filteringType="auto"
                  />
                </FormField>
                {
                  allowAutoProvision
                    ? (<>
                        <FormField label="WorkSpace Bundle" errorText={errors.bundleUiName}>
                          <Select
                            selectedOption={{
                              value: values.bundleUiName,
                              label: values.bundleUiName
                            }}
                            onChange={({ detail }) => {
                              setFieldValue('bundleUiName', detail.selectedOption.value);
                            }}
                            options={bundleSelectValues}
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                          />
                        </FormField>
                        <FormField label="WorkSpace Directory" errorText={errors.directoryUiName}>
                          <Select
                            selectedOption={{
                              value: values.directoryUiName,
                              label: values.directoryUiName
                            }}
                            onChange={({ detail }) => {
                              setFieldValue('directoryUiName', detail.selectedOption.value);
                            }}
                            options={directorySelectValues}
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                          />
                        </FormField>
                      </>)
                    : <></>
                }
              </SpaceBetween>
            </Form>
          </SpaceBetween>
        )}
      </Formik>
    </>
  );
};
export default View;
