import { FC, useEffect } from 'react';

import { SpaceBetween, HelpPanel } from '@awsui/components-react';
import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

interface InitializingPortalProps {
  setPortalInitialized: (bool: boolean) => void;
}

const InitializingPortal: FC<InitializingPortalProps> = ({
  setPortalInitialized
}) => {
  useEffect(() => {
    const api = new MainServiceApi();

    api
      .getPortalSettingsByNames(['initialized'])
      .then((resp) => {
        if (!resp.initialized) {
          api.initializePortal().then(() => {
            setPortalInitialized(true);
          });
        } else {
          setPortalInitialized(true);
        }
      })
      .catch((err) => {});
  });
  return (
    <SpaceBetween direction="vertical" size="xs">
      <HelpPanel>
        <div>
          <h3>You will be sign in once these tasks complete...</h3>
          <ul>
            <li>
              Retrieving Users and designated Admin Group Members from your
              Active Directory.
            </li>
            <li>
              Retrieving WorkSpaces, Bundles and AD Connectors from AWS Account.
            </li>
            <li>
              Initiating background task to retrieve the the rest of Group data
              from your Active Directory.
            </li>
          </ul>
        </div>
      </HelpPanel>
    </SpaceBetween>
  );
};

export default InitializingPortal;
