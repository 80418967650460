import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { IUserWorkSpaceDecommissionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

const UserWorkSpaceAllDecommissionRequestTable: FC = () => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [
    userWorkSpaceDecommissionRequests,
    setUserWorkSpaceDecommissionRequests
  ] = useState<IUserWorkSpaceDecommissionRequest[]>([]);
  const [
    selectedUserWorkSpaceDecommissionRequests,
    setSelectedUserWorkSpaceDecommissionRequests
  ] = useState<IUserWorkSpaceDecommissionRequest[]>([]);

  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    userWorkSpaceDecommissionRequestDetailsModalIsVisible,
    setUserWorkSpaceDecommissionRequestDetailsModalIsVisible
  ] = useState(false);

  useEffect(() => {
    getUserWorkSpaceDecommissionRequests().then((data) => {
      setUserWorkSpaceDecommissionRequests(data);
      setTableIsLoading(false);
    });
  }, []);

  const getUserWorkSpaceDecommissionRequests = () => {
    const api = new MainServiceApi();
    return api
      .getAllUserWorkSpaceDecommissionRequests()
      .then((data) => {
        return data;
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: (
              <>Unable to get All User WorkSpace Decommission Requests!</>
            )
          }
        ]);

        return [];
      });
  };

  const handleTableRowSelection = (
    userWorkSpaceDecommissionRequest: IUserWorkSpaceDecommissionRequest[]
  ) => {
    if (userWorkSpaceDecommissionRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedUserWorkSpaceDecommissionRequests(
      userWorkSpaceDecommissionRequest
    );
  };
  const refreshUserWorkSpaceDecommissionRequestTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getUserWorkSpaceDecommissionRequests().then((data) => {
      setUserWorkSpaceDecommissionRequests(data);
      setTableIsLoading(false);
    });
  };

  return (
    <View
      userWorkSpaceDecommissionRequests={userWorkSpaceDecommissionRequests}
      selectedUserWorkSpaceDecommissionRequests={
        selectedUserWorkSpaceDecommissionRequests
      }
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      refreshUserWorkSpaceDecommissionRequestTable={
        refreshUserWorkSpaceDecommissionRequestTable
      }
      userWorkSpaceDecommissionRequestDetailsModalIsVisible={
        userWorkSpaceDecommissionRequestDetailsModalIsVisible
      }
      setUserWorkSpaceDecommissionRequestDetailsModalIsVisible={
        setUserWorkSpaceDecommissionRequestDetailsModalIsVisible
      }
      flashBarItems={flashBarItems}
    />
  );
};
export default UserWorkSpaceAllDecommissionRequestTable;
