import { FC, useState, useEffect, useCallback } from 'react';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  ICreateUserReactivationRequest,
  IUserReactivationRequest
} from '../../../../interfaces/domain/userReactivationRequest';

import View from './view';

const CreateUserReactivationRequest: FC = () => {
  const formValues: ICreateUserReactivationRequest = {
    justification: ''
  };

  const [pendingRequests, setPendingRequests] = useState<
    IUserReactivationRequest[]
  >([]);
  const [rejectedRequests, setRejectedRequests] = useState<
    IUserReactivationRequest[]
  >([]);

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const refreshUserReactivationRequests = useCallback(() => {
    getUserReactivationRequests('Pending').then((reqs) => setPendingRequests(reqs));
    getUserReactivationRequests('Rejected').then((reqs) => setRejectedRequests(reqs));
  }, []);

  useEffect(() => {
    refreshUserReactivationRequests();
  }, [refreshUserReactivationRequests]);

  const getUserReactivationRequests = async (status: string) => {
    const api = new MainServiceApi();

    try {
      const data = await api.getUserReactivationRequestsForSelfByStatus(status);
      return data.sort((a, b) => b.updatedAt - a.updatedAt);
    } catch (err) {
      setFlashBarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Unable to get User Reactivation Requests!</>
        }
      ]);

      return [];
    }
  };

  const handleSubmittedForm = async (data: ICreateUserReactivationRequest) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .createUserReactivationRequest(data.justification)
      .then(() => {
        refreshUserReactivationRequests();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>User Reactivation Request has been created!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to create User Reactivation Request!</>
          }
        ]);
      });
  };

  const handleCancellation = async (uuid: string) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Cancelling...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .deprecateUserReactivationRequest(uuid)
      .then(() => {
        refreshUserReactivationRequests();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>User Reactivation Request has been cancelled!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to cancel User Reactivation Request!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      handleCancellation={handleCancellation}
      flashBarItems={flashBarItems}
      pendingRequests={pendingRequests}
      rejectedRequests={rejectedRequests}
    />
  );
};
export default CreateUserReactivationRequest;
