import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { IContactSupport } from '../../../../interfaces/portal/contactSupport';

import View from './view';

const ContactSupport: FC = () => {
  const formValues: IContactSupport = {
    supportTopic: '',
    supportBody: ''
  };

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [supportTopicSelectValues, setSupportTopicSelectValues] = useState<
    SelectProps.Option[]
  >([]);
  const [blockingFlashBarItems, setBlockingFlashBarItems] = useState<
  FlashbarProps.MessageDefinition[]
>([]);

  useEffect(() => {
    setBlockingFlashBarItems([
      {
        type: 'info',
        content: (
          <>
            Please be sure not to include any ITAR or NONFORN CUI data in your support ticket. We appreciate your cooperation in keeping CWE secure.
          </>
        )
      }
    ]);
    const api = new MainServiceApi();
    api
      .getAllSupportTopics()
      .then((resp) => {
        const selectValues = resp.map((topic) => {
          return { label: topic.topicName, value: topic.topicName };
        });
        setSupportTopicSelectValues(selectValues);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Support Topics!</>
          }
        ]);
      });
  }, []);

  const handleSubmittedForm = async (data: IContactSupport) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .contactSupport(data)
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Support has been contacted!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to contact support!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      flashBarItems={flashBarItems}
      blockingFlashBarItems={blockingFlashBarItems}
      supportTopicSelectValues={supportTopicSelectValues}
    />
  );
};
export default ContactSupport;
