import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Input,
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { ISMSVerify } from '../../../../../interfaces/integrations/duo';

interface ViewProps {
  formValues: ISMSVerify;
  phoneNumber: string;
  handleResend: () => Promise<void>;
  handleSubmittedForm: (data: ISMSVerify) => Promise<void>;
  handleCancel?: () => void;
}

const View: FC<ViewProps> = ({
  formValues,
  phoneNumber,
  handleResend,
  handleSubmittedForm,
  handleCancel
}) => {
  const validationSchema = yup.object({
    smsCode: yup.string().length(6).required()
  });

  return (
    <Formik
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                {
                  handleCancel ?
                  <Button onClick={() => handleCancel()} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  : <></>
                }
                <Button
                  onClick={() => handleResend()}
                  disabled={isSubmitting}
                >
                  Resend SMS
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="m">
              <FormField
                description={`Enter the verification code sent to your phone number (${phoneNumber})`}
                label="SMS Code"
                errorText={errors.smsCode ? 'Must be a 6 digit code' : null}
              >
                <Input
                  value={values.smsCode}
                  ariaRequired
                  onChange={({ detail }) =>
                    setFieldValue('smsCode', detail.value)
                  }
                  invalid={!!errors.smsCode}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
