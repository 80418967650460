import { ICurrentUser } from '../../interfaces/domain/user';

export const sessionStorageUser = () => {
  return JSON.parse(
    sessionStorage.getItem('currentUser') || '{}'
  ) as ICurrentUser;
};

export const setSessionStorageUser = (currentUser: ICurrentUser) => {
  sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
};

export const unsetSessionStorageUser = () => {
  sessionStorage.setItem('currentUser', '{}');
};
