import { FC, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IUserWorkSpaceDecommissionRequest,
  IRejectUserWorkSpaceDecommissionRequest
} from '../../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';

import View from './view';

interface IndexProps {
  userWorkSpaceDecommissionRequests: IUserWorkSpaceDecommissionRequest[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  setRejectUserWorkSpaceDecommissionRequestModalIsVisible: Dispatch<
    SetStateAction<boolean>
  >;
  refreshUserWorkSpaceDecommissionRequestTable: () => void;
}

const RejectUserWorkSpaceDecommissionRequestForm: FC<IndexProps> = ({
  userWorkSpaceDecommissionRequests,
  setFlashBarItems,
  setRejectUserWorkSpaceDecommissionRequestModalIsVisible,
  refreshUserWorkSpaceDecommissionRequestTable
}) => {
  const [uuid, setUuid] = useState('');
  const formValues: IRejectUserWorkSpaceDecommissionRequest = {
    rejectionReason: ''
  };

  useEffect(() => {
    for (const userWorkSpaceDecommissionRequest of userWorkSpaceDecommissionRequests) {
      setUuid(userWorkSpaceDecommissionRequest.uuid);
    }
  }, [userWorkSpaceDecommissionRequests]);

  const handleSubmittedForm = async (
    data: IRejectUserWorkSpaceDecommissionRequest
  ) => {
    setRejectUserWorkSpaceDecommissionRequestModalIsVisible(false);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .rejectUserWorkSpaceDecommissionRequest(uuid, data.rejectionReason)
      .then((resp) => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Rejected WorkSpace Decommission Request successfully!</>
          }
        ]);
        refreshUserWorkSpaceDecommissionRequestTable();
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to reject WorkSpace Decommission Request!</>
          }
        ]);
      });
  };

  return (
    <View handleSubmittedForm={handleSubmittedForm} formValues={formValues} />
  );
};

export default RejectUserWorkSpaceDecommissionRequestForm;
