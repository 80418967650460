import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';

import View from './view';

const UserAccountAllRequestTable: FC = () => {
  const [userAccountRequests, setUserAccountRequests] = useState<
    IUserAccountRequest[]
  >([]);
  const [selectedUserAccountRequests, setSelectedUserAccountRequests] =
    useState<IUserAccountRequest[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    userAccountRequestDetailsModalIsVisible,
    setUserAccountRequestDetailsModalIsVisible
  ] = useState(false);

  useEffect(() => {
    getUserAccountRequests().then((data) => {
      setUserAccountRequests(data);
      setTableIsLoading(false);
    });
  }, []);

  const getUserAccountRequests = () => {
    const api = new MainServiceApi();
    return api
      .getAllUserAccountRequests()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to retrieve User Account Requests!</>
          }
        ]);
        return [];
      });
  };

  const handleTableRowSelection = (
    userAccountRequest: IUserAccountRequest[]
  ) => {
    if (userAccountRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedUserAccountRequests(userAccountRequest);
  };
  const refreshUserAccountRequestTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getUserAccountRequests().then((data) => {
      setUserAccountRequests(data);
      setTableIsLoading(false);
    });
  };

  return (
    <View
      userAccountRequests={userAccountRequests}
      selectedUserAccountRequests={selectedUserAccountRequests}
      flashBarItems={flashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      refreshUserAccountRequestTable={refreshUserAccountRequestTable}
      userAccountRequestDetailsModalIsVisible={
        userAccountRequestDetailsModalIsVisible
      }
      setUserAccountRequestDetailsModalIsVisible={
        setUserAccountRequestDetailsModalIsVisible
      }
    />
  );
};
export default UserAccountAllRequestTable;
