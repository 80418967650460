import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { IDuoPhoneActivation } from '../../../../../../interfaces/integrations/duo';

import View from './view';
import { FlashbarProps } from '@awsui/components-react';

interface IndexProps {
  duoPhoneId: string;
}

const DuoPhoneActivationContent: FC<IndexProps> = ({ duoPhoneId }) => {
  const [duoPhoneActivationCode, setDuoPhoneActivationCode] =
    useState<IDuoPhoneActivation>({
      activation_barcode: '',
      activation_url: '',
      valid_secs: 0
    });

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    setFlashBarItems([
      {
        type: 'success',
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => {
          setFlashBarItems([]);
        },
        content: <> DUO Phone has been created!</>
      }
    ]);
    
    const api = new MainServiceApi();
    api
      .getActivationCode(duoPhoneId)
      .then((resp) => {
        setDuoPhoneActivationCode(resp);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get DUO Phone Activation Code!</>
          }
        ]);
      });
  }, [duoPhoneId]);

  return (
    <View
      duoPhoneActivationCode={duoPhoneActivationCode}
      flashBarItems={flashBarItems}
    />
  );
};

export default DuoPhoneActivationContent;
