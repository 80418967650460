import { FC } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import {
  Button,
  Header,
  SpaceBetween,
  Form,
  Container,
  FormField,
  Flashbar,
  Select,
  Textarea
} from '@awsui/components-react';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { IContactSupport } from '../../../../interfaces/portal/contactSupport';

interface ViewProps {
  formValues: IContactSupport;
  handleSubmittedForm: (data: IContactSupport) => Promise<void>;
  flashBarItems: FlashbarProps.MessageDefinition[];
  blockingFlashBarItems: FlashbarProps.MessageDefinition[];
  supportTopicSelectValues: SelectProps.Option[];
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  flashBarItems,
  blockingFlashBarItems,
  supportTopicSelectValues
}) => {
  const validationSchema = yup.object({
    supportTopic: yup.string().required(),
    supportBody: yup.string().required()
  });

  return (
    <Formik
      validateOnChange={false}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={flashBarItems} />
          <Form
            actions={
              <Button
                variant="primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            }
          >
            <Container header={<Header variant="h2">Contact Support</Header>}>
              <SpaceBetween direction="vertical" size="xs">
              <Flashbar items={blockingFlashBarItems} />
                <FormField label="Select Topic" errorText={errors.supportTopic}>
                  <Select
                    selectedOption={{ value: values.supportTopic }}
                    onChange={({ detail }) =>
                      setFieldValue('supportTopic', detail.selectedOption.value)
                    }
                    options={supportTopicSelectValues}
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                  />
                </FormField>
                <FormField
                  label="Describe your issue here"
                  errorText={errors.supportBody}
                >
                  <Textarea
                    value={values.supportBody}
                    onChange={({ detail }) =>
                      setFieldValue('supportBody', detail.value)
                    }
                    invalid={!!errors.supportBody}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
