import { FC, Dispatch, SetStateAction } from 'react';

import { FlashbarProps } from '@awsui/components-react';

import View from './view';
import { ISMSVerify } from '../../../../../interfaces/integrations/duo';
import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

interface IndexProps {
  submitSMSCode: (smsCode: string) => Promise<void>;
  phoneNumber: string;
  handleCancel?: () => void;
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  isPhoneSetup?: boolean;
}

const api = new MainServiceApi();

const SMSVerifyForm: FC<IndexProps> = ({
  submitSMSCode,
  phoneNumber,
  handleCancel,
  setFlashBarItems,
  isPhoneSetup=false
}) => {
  const formValues: ISMSVerify = { smsCode: '' };

  const handleResend = async () => {
    api.resetSMS(isPhoneSetup ? phoneNumber : '').then(() => {
      setFlashBarItems([
        {
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>SMS has been resent!</>
        }
      ]);
    }).catch((err) => {
      setFlashBarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Error sending SMS - {err}</>
        }
      ]);
    });
  };

  const handleSubmittedForm = async (data: ISMSVerify) => {
    submitSMSCode(data.smsCode);
  };

  return (
    <View
      formValues={formValues}
      phoneNumber={phoneNumber}
      handleResend={handleResend}
      handleSubmittedForm={handleSubmittedForm}
      handleCancel={handleCancel}
    />
  );
};

export default SMSVerifyForm;
