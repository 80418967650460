import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useInterval } from './CustomHooks';

import {
  Badge,
  SideNavigation,
  SideNavigationProps
} from '@awsui/components-react';

import { MainServiceApi } from '../../lib/api/mainServiceApi';
import { GlobalContext } from '../../context/globalContext';
import { SettingsContext } from '../../context/settingsContext';

interface IBadgeData {
  userWorkSpaceProvisionRequestCount: number;
  userWorkSpaceDecommissionRequestCount: number;
  userAccountRequestCount: number;
  userReactivationRequestCount: number;
}
const SideNav: FC = () => {
  const history = useHistory();
  const {
    currentUser: { isAdmin, enabled }
  } = useContext(GlobalContext);

  const { isDuoEnabled } = useContext(SettingsContext);

  const [activeHref, setActiveHref] = useState(`/`);
  const [adminSectionOpen, setAdminSectionOpen] = useState(false);
  const [adminAuthSectionOpen, setAdminAuthSectionOpen] = useState(false);
  const [adminDropDownSectionOpen, setAdminDropDownsSectionOpen] =
    useState(false);

  const [badgeData, setBadgeData] = useState<IBadgeData>({
    userWorkSpaceProvisionRequestCount: 0,
    userWorkSpaceDecommissionRequestCount: 0,
    userAccountRequestCount: 0,
    userReactivationRequestCount: 0
  });

  useInterval(() => {
    if (isAdmin) updateBadges();
  }, [isAdmin], 60000);

  const updateBadges = async () => {
    const api = new MainServiceApi();

    api
      .getPendingUserAccountRequestCount()
      .then((resp) => {
        setBadgeData((prevState) => ({
          ...prevState,
          userAccountRequestCount: resp.count
        }));
      })
      .catch(() => {});
    api
      .getPendingUserReactivationRequestCount()
      .then((resp) => {
        setBadgeData((prevState) => ({
          ...prevState,
          userReactivationRequestCount: resp.count
        }));
      })
      .catch(() => {});
    api
      .getPendingUserWorkSpaceProvisionRequestCount()
      .then((resp) => {
        setBadgeData((prevState) => ({
          ...prevState,
          userWorkSpaceProvisionRequestCount: resp.count
        }));
      })
      .catch(() => {});
    api
      .getPendingUserWorkSpaceDecommissionRequestCount()
      .then((resp) => {
        setBadgeData((prevState) => ({
          ...prevState,
          userWorkSpaceDecommissionRequestCount: resp.count
        }));
      })
      .catch(() => {});
  };

  const renderUserWorkSpaceProvisionRequestBadge = () => {
    if (badgeData.userWorkSpaceProvisionRequestCount !== 0) {
      return (
        <Badge color="red">
          {badgeData.userWorkSpaceProvisionRequestCount}
        </Badge>
      );
    }
  };
  const renderUserWorkSpaceDecommissionRequestBadge = () => {
    if (badgeData.userWorkSpaceDecommissionRequestCount !== 0) {
      return (
        <Badge color="red">
          {badgeData.userWorkSpaceDecommissionRequestCount}
        </Badge>
      );
    }
  };
  const renderUserAccountRequestBadge = () => {
    if (badgeData.userAccountRequestCount !== 0) {
      return <Badge color="red">{badgeData.userAccountRequestCount}</Badge>;
    }
  };
  const renderUserReactivationRequestBadge = () => {
    if (badgeData.userReactivationRequestCount !== 0) {
      return <Badge color="red">{badgeData.userReactivationRequestCount}</Badge>;
    }
  };

  const getDuoUserNavItems = (): SideNavigationProps.Item => {
    if (isDuoEnabled)
      return {
        type: 'link',
        text: 'DUO Devices',
        href: '/user/integrations/duo/devices'
      };
    else {
      return {} as SideNavigationProps.Item;
    }
  };

  const getUserSideNavItems = (): SideNavigationProps.Item[] => {
    if (enabled) {
      return [
        {
          type: 'link',
          text: 'Password Reset',
          href: '/user/password_reset'
        },
        getDuoUserNavItems()
      ];
    } else {
      return [];
    }
  };

  const getSupportSideNavItems: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: 'Documentation',
      href: '/support/docs'
    },
    {
      type: 'link',
      text: 'Contact Us',
      href: '/support/contact'
    },
    {
      type: 'link',
      text: 'Settings',
      href: '/settings'
    }
  ];
  const getAdminSideNavItems = (): SideNavigationProps.Item => {
    if (isAdmin && enabled)
      return {
        type: 'section',
        text: 'Admin',
        defaultExpanded: adminSectionOpen,
        items: [
          {
            type: 'link',
            text: 'WorkSpace Provision Requests',
            href: '/admin/user_workspace_provision_requests',
            info: renderUserWorkSpaceProvisionRequestBadge()
          },
          {
            type: 'link',
            text: 'WorkSpace Decommission Requests',
            href: '/admin/user_workspace_decommission_requests',
            info: renderUserWorkSpaceDecommissionRequestBadge()
          },
          {
            type: 'link',
            text: 'User Account Requests',
            href: '/admin/user_account_requests',
            info: renderUserAccountRequestBadge()
          },
          {
            type: 'link',
            text: 'User Reactivation Requests',
            href: '/admin/user_reactivation_requests',
            info: renderUserReactivationRequestBadge()
          },
          {
            type: 'link',
            text: 'Users',
            href: '/admin/users'
          },
          {
            type: 'link',
            text: 'Home Groups',
            href: '/admin/dropdowns/domain/home_groups'
          },
          {
            type: 'link',
            text: 'Sync, Settings & Secrets',
            href: '/admin/portal/settings'
          },
          {
            type: 'section',
            text: 'Authentication',
            defaultExpanded: adminAuthSectionOpen,
            items: [
              {
                type: 'link',
                text: 'Approved Domains',
                href: '/admin/auth/approved_domains'
              }
            ]
          },
          {
            type: 'section',
            text: 'Dropdowns',
            defaultExpanded: adminDropDownSectionOpen,
            items: [
              {
                type: 'link',
                text: 'DUO Types',
                href: '/admin/dropdowns/duo/types'
              },
              {
                type: 'link',
                text: 'DUO Platforms',
                href: '/admin/dropdowns/duo/platforms'
              },
              {
                type: 'link',
                text: 'WS Bundles',
                href: '/admin/dropdowns/workspaces/bundles'
              },
              {
                type: 'link',
                text: 'WS Directories',
                href: '/admin/dropdowns/workspaces/directories'
              },
              {
                type: 'link',
                text: 'Support Topics',
                href: '/admin/dropdowns/portal/support_topics'
              }
            ]
          }
        ]
      };
    else {
      return {} as SideNavigationProps.Item;
    }
  };

  const sideNavOnChange = (detail: SideNavigationProps.ChangeDetail) => {
    if (detail.item.text === 'Admin') {
      setAdminSectionOpen(detail.expanded);
      if (!detail.expanded) {
        setAdminAuthSectionOpen(false);
        setAdminDropDownsSectionOpen(false);
      }
    } else if (detail.item.text === 'Authentication') {
      setAdminAuthSectionOpen(detail.expanded);
    } else if (detail.item.text === 'Dropdowns') {
      setAdminDropDownsSectionOpen(detail.expanded);
    }
  };

  return (
    <SideNavigation
      id="side-nav"
      activeHref={activeHref}
      header={{ href: `/`, text: 'Self-Service' }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          setActiveHref(event.detail.href);
          history.push(`${event.detail.href}`);
        }
      }}
      onChange={(event) => {
        sideNavOnChange(event.detail);
      }}
      items={[
        { type: 'link', text: 'Home', href: `/` },
        ...getUserSideNavItems(),
        getAdminSideNavItems(),
        { type: 'divider' },
        ...getSupportSideNavItems,
        { type: 'divider' },
        { type: 'link', text: 'Sign out', href: '/logout' }
      ]}
    />
  );
};

export default SideNav;
