import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IUpdateDuoType,
  IDuoType
} from '../../../../../../interfaces/integrations/duo';

import View from './view';

interface IndexProps {
  refreshDuoTypeTable: () => void;
  duoTypes: IDuoType[];
}

const EditDuoTypeForm: FC<IndexProps> = ({ refreshDuoTypeTable, duoTypes }) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [duoTypeUuid, setDuoTypeUuid] = useState('');
  const [formValues, setFormValues] = useState<IUpdateDuoType>({
    typeName: '',
    deprecated: 0
  });

  useEffect(() => {
    for (const duoType of duoTypes) {
      setDuoTypeUuid(duoType.uuid);
      setFormValues({
        typeName: duoType.typeName,
        deprecated: duoType.deprecated
      });
    }
  }, [duoTypes]);

  const handleSubmittedForm = async (data: IUpdateDuoType) => {
    setFormValues(data);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateDuoType(duoTypeUuid, data)
      .then(() => {
        refreshDuoTypeTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Duo Type has been updated!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update Duo Type!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default EditDuoTypeForm;
