import { FC, useState, useEffect, useCallback } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUserReactivationRequest } from '../../../../../interfaces/domain/userReactivationRequest';

import View from './view';

const UserReactivationPendingRequestTable: FC = () => {
  const [userReactivationRequests, setUserReactivationRequests] = useState<
    IUserReactivationRequest[]
  >([]);
  const [selectedUserReactivationRequests, setSelectedUserReactivationRequests] =
    useState<IUserReactivationRequest[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    rejectUserReactivationRequestModalIsVisible,
    setRejectUserReactivationRequestModalIsVisible
  ] = useState(false);
  const [
    userReactivationRequestDetailsModalIsVisible,
    setUserReactivationRequestDetailsModalIsVisible
  ] = useState(false);

  const getUserReactivationRequests = useCallback(() => {
    setTableIsLoading(true);

    const api = new MainServiceApi();
    return api
      .getPendingUserReactivationRequests()
      .then((data) => {
        setUserReactivationRequests(data);
        setTableIsLoading(false);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to retrieve User Reactivation Requests!</>
          }
        ]);

        return [];
      });
  }, []);

  useEffect(() => {
    getUserReactivationRequests();

    const interval = setInterval(() => {
      getUserReactivationRequests();
    }, 60000);

    return () => clearInterval(interval);
  }, [getUserReactivationRequests]);

  const handleTableRowSelection = (
    userReactivationRequest: IUserReactivationRequest[]
  ) => {
    if (userReactivationRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedUserReactivationRequests(userReactivationRequest);
  };
  const refreshUserReactivationRequestTable = () => {
    getUserReactivationRequests();
  };

  const handleApproval = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();

    for (const selectedUserReactivationRequest of selectedUserReactivationRequests) {
      api
        .approveUserReactivationRequest(selectedUserReactivationRequest.uuid)
        .then(() => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  User Reactivation Request for
                  {` ${selectedUserReactivationRequest.firstName} ${selectedUserReactivationRequest.lastName} `}
                  was approved successfully!
                </>
              )
            }
          ]);
          refreshUserReactivationRequestTable();
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  Unable to approve User Reactivation Request for
                  {`${selectedUserReactivationRequest.firstName} ${selectedUserReactivationRequest.lastName} `}
                  !
                </>
              )
            }
          ]);
        });
    }
  };

  return (
    <View
      userReactivationRequests={userReactivationRequests}
      selectedUserReactivationRequests={selectedUserReactivationRequests}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      refreshUserReactivationRequestTable={refreshUserReactivationRequestTable}
      handleApproval={handleApproval}
      rejectUserReactivationRequestModalIsVisible={
        rejectUserReactivationRequestModalIsVisible
      }
      setRejectUserReactivationRequestModalIsVisible={
        setRejectUserReactivationRequestModalIsVisible
      }
      userReactivationRequestDetailsModalIsVisible={
        userReactivationRequestDetailsModalIsVisible
      }
      setUserReactivationRequestDetailsModalIsVisible={
        setUserReactivationRequestDetailsModalIsVisible
      }
    />
  );
};
export default UserReactivationPendingRequestTable;
