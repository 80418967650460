import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IUpdateSupportTopic,
  ISupportTopic
} from '../../../../../interfaces/portal/supportTopic';

import View from './view';

interface IndexProps {
  refreshSupportTopicTable: () => void;
  supportTopics: ISupportTopic[];
}

const EditSupportTopicForm: FC<IndexProps> = ({
  refreshSupportTopicTable,
  supportTopics
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [supportTopicUuid, setSupportTopicUuid] = useState('');
  const [formValues, setFormValues] = useState<IUpdateSupportTopic>({
    topicName: '',
    deprecated: 0
  });

  useEffect(() => {
    for (const supportTopic of supportTopics) {
      setSupportTopicUuid(supportTopic.uuid);
      setFormValues({
        topicName: supportTopic.topicName,
        deprecated: supportTopic.deprecated
      });
    }
  }, [supportTopics]);

  const handleSubmittedForm = async (data: IUpdateSupportTopic) => {
    setFormValues(data);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateSupportTopic(supportTopicUuid, data)
      .then(() => {
        refreshSupportTopicTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Support Topic has been updated!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update Support Topic!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default EditSupportTopicForm;
