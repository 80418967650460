import { FC } from 'react';

import { Tabs } from '@awsui/components-react';

import PortalSecretsForm from '../portalSecretsForm';
import PortalSyncForm from '../portalSyncForm';

interface ViewProps {}

const View: FC<ViewProps> = () => {
  return (
    <Tabs
      variant="container"
      tabs={[
        {
          label: 'Sync',
          id: 'sync',
          content: <PortalSyncForm />
        },
        {
          label: 'Settings & Secrets',
          id: 'secrets',
          content: <PortalSecretsForm />
        }
      ]}
    />
  );
};
export default View;
