import { FC, useCallback, useEffect, useState } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import {
  IUpdateHomeGroup,
  IHomeGroup
} from '../../../../../interfaces/domain/homegroup';

import View from './view';

interface IndexProps {
  refreshHomeGroupTable: () => void;
  homeGroups: IHomeGroup[];
}

const api = new MainServiceApi();

const EditHomeGroupForm: FC<IndexProps> = ({
  refreshHomeGroupTable,
  homeGroups
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [groupName, setGroupName] = useState('');
  const [formValues, setFormValues] = useState<IUpdateHomeGroup>({
    emailDomain: '',
    deprecated: 0,
    bundles: [],
    adDirectories: []
  });
  const [bundleSelectValues, setBundleSelectValues] =
    useState<SelectProps.Options>([]);
  const [directorySelectValues, setDirectorySelectValues] =
    useState<SelectProps.Options>([]);
  const [approvedDomainsSelectValues, setApprovedDomainsSelectValues] =
    useState<SelectProps.Options>([]);

  useEffect(() => {
    for (const homeGroup of homeGroups) {
      setGroupName(homeGroup.groupName);
      setFormValues({
        emailDomain: homeGroup.emailDomain,
        deprecated: homeGroup.deprecated,
        bundles: homeGroup.bundles,
        adDirectories: homeGroup.adDirectories
      });
    }
  }, [homeGroups]);

  useInterval(() => {
    // Staggered intervals for each repeating call
    refreshBundles();
    setTimeout(refreshDirectories, 1000);
    setTimeout(refreshApprovedDomains, 2000);
  }, [], 60000, false);

  const refreshBundles = useCallback(() => {
    api
      .getAllBundles()
      .then((data) => {
        const temp = data.map((el) => ({
          label: el.bundleName,
          value: el.bundleId
        }));
        setBundleSelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Bundles!</>
          }
        ]);
      });
  }, []);

  const refreshDirectories = useCallback(() => {
    api
      .getAllDirectories()
      .then((data) => {
        const temp = data.map((el) => ({
          label: el.alias,
          value: el.directoryId
        }));
        setDirectorySelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Directories!</>
          }
        ]);
      });
  }, []);

  const refreshApprovedDomains = useCallback(() => {
    api
      .getAllApprovedDomains()
      .then((data) => {
        const temp = data.map((el) => ({
          label: el.domainName,
          value: el.domainName
        }));
        setApprovedDomainsSelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Approved Domains!</>
          }
        ]);
      });
  }, []);

  useEffect(() => {
    // Initial call so the components are available immediately
    refreshBundles();
    refreshDirectories();
    refreshApprovedDomains();
  }, [refreshBundles, refreshDirectories, refreshApprovedDomains]);

  const handleSubmittedForm = async (data: IUpdateHomeGroup) => {
    setFormValues({
      ...data
    });
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    api
      .updateHomeGroup(groupName, data)
      .then(() => {
        refreshHomeGroupTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Home Group has been updated!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update Home Group!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
      bundleSelectValues={bundleSelectValues}
      directorySelectValues={directorySelectValues}
      approvedDomainsSelectValues={approvedDomainsSelectValues}
    />
  );
};
export default EditHomeGroupForm;
