import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IBundle } from '../../../../../interfaces/workSpaces/bundle';

import View from './view';

const WorkSpaceBundleTable: FC = () => {
  const [bundles, setBundles] = useState<IBundle[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedBundles, setSelectedBundles] = useState<IBundle[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);

  const [editBundleFormModalIsVisible, setEditBundleFormModalIsVisible] =
    useState(false);

  useEffect(() => {
    getBundles().then((data) => {
      setBundles(data);
      setTableIsLoading(false);
    });
  }, []);

  const getBundles = () => {
    const api = new MainServiceApi();
    return api
      .getAllBundles()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Bundles!</>
          }
        ]);

        return [];
      });
  };
  const bundlesIsEmpty = () => {
    return bundles.length === 0;
  };

  const handleTableRowSelection = (bundles: IBundle[]) => {
    setDisableActionButtons(false);
    setSelectedBundles(bundles);
  };
  const refreshBundleTable = () => {
    setTableIsLoading(true);
    getBundles().then((data) => {
      setBundles(data);
      setSelectedBundles([]);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      bundles={bundles}
      selectedBundles={selectedBundles}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      bundlesIsEmpty={bundlesIsEmpty}
      tableIsLoading={tableIsLoading}
      editBundleFormModalIsVisible={editBundleFormModalIsVisible}
      setEditBundleFormModalIsVisible={setEditBundleFormModalIsVisible}
      refreshBundleTable={refreshBundleTable}
    />
  );
};
export default WorkSpaceBundleTable;
