
import { useContext } from 'react';
import { GlobalContext } from '../../context/globalContext';
import { Modal, ModalProps } from '@awsui/components-react';

/**
 * Wrapper for aws-ui Modal that allows a "top-level" modal to be shown
 * with higher priority (e.g. session timeout) in favor of this one
 * 
 * This is an mitigation to not being able to show two modals at once and control
 * their z-order properly, which is also an unsupported use case
 */
const ChildModal = (props: ModalProps) => {
  const { hideChildModals } = useContext(GlobalContext);

  return (
    <Modal
      {...props}
      visible={props.visible && !hideChildModals}
    />
  );
};

export default ChildModal;
