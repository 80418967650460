import { Redirect, Route } from 'react-router-dom';

interface UserRouteProps {
  component: React.ElementType;
  isAuthenticated: boolean;
  userEnabled: number;
  exact: boolean;
  path: string;
}
interface AdminRouteProps {
  component: React.ElementType;
  isAuthenticated: boolean;
  isAdmin: boolean;
  userEnabled: number;
  exact: boolean;
  path: string;
}

interface DuoRouteProps {
  component: React.ElementType;
  isAuthenticated: boolean;
  isDuoEnabled: boolean;
  userEnabled: number;
  exact: boolean;
  path: string;
}

export const UserRoute: React.FC<UserRouteProps> = ({
  component: Component,
  isAuthenticated,
  userEnabled,
  exact,
  path,
  ...rest
}) => {
  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (userEnabled) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />
          }
        } else {
          return <Redirect to="/logout" />;
        }
      }}
    />
  );
};

export const AdminRoute: React.FC<AdminRouteProps> = ({
  component: Component,
  isAuthenticated,
  isAdmin,
  userEnabled,
  exact,
  path,
  ...rest
}) => {
  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => {
        if (isAuthenticated && isAdmin && userEnabled) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export const DuoRoute: React.FC<DuoRouteProps> = ({
  component: Component,
  exact,
  path,
  isAuthenticated,
  isDuoEnabled,
  userEnabled,
  ...rest
}) => {
  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => {
        if (isAuthenticated && isDuoEnabled && userEnabled) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};
