import { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/globalContext';

const LogOut: FC = () => {
  const { logOut } = useContext(GlobalContext);

  const history = useHistory();

  useEffect(() => {
    logOut();
    history.replace('/');
  });
  return <></>;
};

export default LogOut;
