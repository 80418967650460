import { FC } from 'react';

import {
  Button,
  SpaceBetween,
  FormField,
  Input,
  Form,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps } from '@awsui/components-react';
import { ICreateSupportTopic } from '../../../../../interfaces/portal/supportTopic';

interface ViewProps {
  formValues: ICreateSupportTopic;
  flashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: ICreateSupportTopic) => Promise<void>;
}

const View: FC<ViewProps> = ({
  formValues,
  flashBarItems,
  handleSubmittedForm
}) => {
  const validationSchema = yup.object({
    topicName: yup.string().required().min(3)
  });

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSubmittedForm(data).then((resp) => {
            resetForm();
            setSubmitting(false);
          });
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
          <SpaceBetween direction="vertical" size="xs">
            <Flashbar items={flashBarItems} />
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <FormField label="Name" errorText={errors.topicName}>
                <Input
                  name="topicName"
                  value={values.topicName}
                  onChange={({ detail }) =>
                    setFieldValue('topicName', detail.value)
                  }
                  invalid={!!errors.topicName}
                />
              </FormField>
            </Form>
          </SpaceBetween>
        )}
      </Formik>
    </>
  );
};
export default View;
