import { FC, useCallback, useEffect, useState } from 'react';

import { useInterval } from '../../../../common/CustomHooks';
import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { ICreateHomeGroup } from '../../../../../interfaces/domain/homegroup';

import View from './view';

interface IndexProps {
  refreshHomeGroupTable: () => void;
}

const api = new MainServiceApi();

const CreateHomeGroupForm: FC<IndexProps> = ({ refreshHomeGroupTable }) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const formValues: ICreateHomeGroup = {
    groupName: '',
    emailDomain: '',
    bundles: [],
    adDirectories: []
  };

  const [bundleSelectValues, setBundleSelectValues] =
    useState<SelectProps.Options>([]);
  const [directorySelectValues, setDirectorySelectValues] =
    useState<SelectProps.Options>([]);
  const [groupSelectValues, setGroupSelectValues] =
    useState<SelectProps.Options>([]);
  const [approvedDomainsSelectValues, setApprovedDomainsSelectValues] =
    useState<SelectProps.Options>([]);

  useInterval(() => {
    // Staggered intervals for each repeating call
    refreshBundles();
    setTimeout(refreshDirectories, 1000);
    setTimeout(refreshApprovedDomains, 2000);
    setTimeout(refreshGroupSelectValues, 3000);
  }, [], 60000, false);

  const refreshBundles = useCallback(() => {
    api
      .getAllBundles()
      .then((data) => {
        const temp = data
          .map((el) => ({
            label: el.bundleName,
            value: el.bundleId
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setBundleSelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Bundles!</>
          }
        ]);
      });
  }, []);

  const refreshDirectories = useCallback(() => {
    api
      .getAllDirectories()
      .then((data) => {
        const temp = data
          .map((el) => ({
            label: el.alias,
            value: el.directoryId
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setDirectorySelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Directories!</>
          }
        ]);
      });
  }, []);

  const refreshApprovedDomains = useCallback(() => {
    api
      .getAllApprovedDomains()
      .then((data) => {
        const temp = data
          .map((el) => ({
            label: el.domainName,
            value: el.domainName
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setApprovedDomainsSelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Approved Domains!</>
          }
        ]);
      });
  }, []);

  const refreshGroupSelectValues = useCallback(() => {
    api
      .getNonHomeGroupGroups()
      .then((data) => {
        const temp = data
          .map((el) => ({
            label: el.groupName,
            value: el.groupName
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        setGroupSelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Groups!</>
          }
        ]);
      });
  }, []);

  useEffect(() => {
    // Initial call so the components are available immediately
    refreshBundles();
    refreshDirectories();
    refreshApprovedDomains();
    refreshGroupSelectValues();
  }, [refreshBundles, refreshDirectories, refreshApprovedDomains, refreshGroupSelectValues]);

  const handleSubmittedForm = async (data: ICreateHomeGroup) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    api
      .createHomeGroup(data)
      .then(() => {
        refreshHomeGroupTable();
        refreshGroupSelectValues();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Home Group has been created!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to create Home Group!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
      bundleSelectValues={bundleSelectValues}
      groupSelectValues={groupSelectValues}
      directorySelectValues={directorySelectValues}
      approvedDomainsSelectValues={approvedDomainsSelectValues}
    />
  );
};
export default CreateHomeGroupForm;
