import { Container, Header } from '@awsui/components-react';
import { FC, useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

const Documentation: FC = () => {
  const [docs, setDocs] = useState('');
  const api = new MainServiceApi();

  useEffect(() => {
    api.getStaticFile('markdown/userDocumentation.md')
      .then((res) => setDocs(res))
      .catch((err) => {
        setDocs('Error loading documentation');
        console.log(err)
      });
  });
  return (
    <Container
      header={<Header variant="h2">WorkSpaces Self Service Portal 3.0</Header>}
    >
      <ReactMarkdown children={docs}></ReactMarkdown>
    </Container>
  );
};

export default Documentation;
