import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { IUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

interface IndexProps {
  selectedUserAccountRequests: IUserAccountRequest[];
}

const UserAccountRequestDetails: FC<IndexProps> = ({
  selectedUserAccountRequests
}) => {
  const [userAccountRequest, setUserAccountRequest] =
    useState<IUserAccountRequest>({
      uuid: '',
      userName: '',
      firstName: '',
      lastName: '',
      corpEmail: '',
      homeGroup: '',
      bundleUiName: '',
      directoryUiName: '',
      requestStatus: '',
      rejectionReason: '',
      ticketLink: '',
      justification: '',
      createdAt: 0,
      updatedAt: 0,
      updatedBy: '',
      deprecated: 0
    });

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    const api = new MainServiceApi();

    for (const selectedUserAccountRequest of selectedUserAccountRequests) {
      api
        .getUserAccountRequestByUuid(selectedUserAccountRequest.uuid)
        .then((data) => {
          setUserAccountRequest(data);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to get User Account Request data!</>
            }
          ]);
        });
    }
  }, [selectedUserAccountRequests]);

  return (
    <View
      userAccountRequest={userAccountRequest}
      flashBarItems={flashBarItems}
    />
  );
};
export default UserAccountRequestDetails;
