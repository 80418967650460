import { FC, useState } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IAssociateDuoToken } from '../../../../../../interfaces/integrations/duo';

import View from './view';

interface IndexProps {
  refreshDuoTokenTable: () => void;
  phoneNumber: string;
}

const api = new MainServiceApi();

const AssociateDuoTokenForm: FC<IndexProps> = ({
  refreshDuoTokenTable,
  phoneNumber
}) => {
  const [formValues, setFormValues] = useState<IAssociateDuoToken>({
    serial: ''
  });

  const [showSMSVerify, setShowSMSVerify] = useState(false);

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  // Step 1: collect serial number
  const handleSubmittedForm = async (data: IAssociateDuoToken) => {
    return api.resetSMS().then(() => {
      setFormValues(data);
      setShowSMSVerify(true);

      setFlashBarItems([
        {
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Sent SMS code for verification - enter it below</>
        }
      ]);
    }).catch(() => {
      setFlashBarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Failed to send SMS verification - try again later</>
        }
      ]);
    });
  };

  // Step 2: collect SMS code and submit request
  const handleSubmitSMSCode = async (smsCode: string) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    api
      .associateDuoToken({ serial: formValues.serial, smsCode })
      .then((resp) => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>DUO Token has been associated!</>
          }
        ]);

        setFormValues({ serial: '' });
        setShowSMSVerify(false);
        refreshDuoTokenTable();
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to associate DUO Token! - {err}</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      phoneNumber={phoneNumber}
      showSMSVerify={showSMSVerify}
      setShowSMSVerify={setShowSMSVerify}
      handleSubmitSMSCode={handleSubmitSMSCode}
      handleSubmittedForm={handleSubmittedForm}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
    />
  );
};
export default AssociateDuoTokenForm;
