import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { IUserReactivationRequest } from '../../../../../interfaces/domain/userReactivationRequest';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

interface IndexProps {
  selectedUserReactivationRequests: IUserReactivationRequest[];
}

const UserReactivationRequestDetails: FC<IndexProps> = ({
  selectedUserReactivationRequests
}) => {
  const [userReactivationRequest, setUserReactivationRequest] =
    useState<IUserReactivationRequest>({
      uuid: '',
      userName: '',
      firstName: '',
      lastName: '',
      corpEmail: '',
      requestStatus: '',
      userName_requestStatus: '',
      rejectionReason: '',
      justification: '',
      createdAt: 0,
      updatedAt: 0,
      updatedBy: '',
      deprecated: 0
    });

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    const api = new MainServiceApi();

    for (const selectedUserReactivationRequest of selectedUserReactivationRequests) {
      api
        .getUserReactivationRequestByUuid(selectedUserReactivationRequest.uuid)
        .then((data) => {
          setUserReactivationRequest(data);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to get User Reactivation Request data!</>
            }
          ]);
        });
    }
  }, [selectedUserReactivationRequests]);

  return (
    <View
      userReactivationRequest={userReactivationRequest}
      flashBarItems={flashBarItems}
    />
  );
};
export default UserReactivationRequestDetails;
