import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoPlatform } from '../../../../../../interfaces/integrations/duo';

import CreateDuoPlatformForm from '../createDuoPlatformForm';
import EditDuoPlatformForm from '../editDuoPlatformForm';
import ChildModal from '../../../../../common/ChildModal';

interface ViewProps {
  duoPlatforms: IDuoPlatform[];
  selectedDuoPlatforms: IDuoPlatform[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (duoPlatform: IDuoPlatform[]) => void;
  duoPlatformsIsEmpty: () => boolean;
  tableIsLoading: boolean;
  createDuoPlatformFormModalIsVisible: boolean;
  setCreateDuoPlatformFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  editDuoPlatformFormModalIsVisible: boolean;
  setEditDuoPlatformFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshDuoPlatformTable: () => void;
}

const View: FC<ViewProps> = ({
  duoPlatforms,
  selectedDuoPlatforms,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  duoPlatformsIsEmpty,
  tableIsLoading,
  createDuoPlatformFormModalIsVisible,
  setCreateDuoPlatformFormModalIsVisible,
  editDuoPlatformFormModalIsVisible,
  setEditDuoPlatformFormModalIsVisible,
  refreshDuoPlatformTable
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No Duo Platforms</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No Duo Platforms to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            onClick={() => {
              setCreateDuoPlatformFormModalIsVisible(true);
            }}
          >
            Create Duo Platform
          </Button>
        }
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching Duo Platforms</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(duoPlatforms, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['platformName']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredDuoPlatforms = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshDuoPlatformTable()}
          />

          <Button
            iconName="add-plus"
            disabled={duoPlatformsIsEmpty()}
            onClick={() => {
              setCreateDuoPlatformFormModalIsVisible(true);
            }}
          >
            Create
          </Button>
          <Button
            iconName="settings"
            disabled={disableActionButtons}
            onClick={() => {
              setEditDuoPlatformFormModalIsVisible(true);
            }}
          >
            Edit
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredDuoPlatforms.length
            ? `(${filteredDuoPlatforms.length}/${duoPlatforms.length})`
            : `(${duoPlatforms.length})`
        }
        actions={renderTableActions()}
      >
        Duo Platforms
      </Header>
    );
  };

  const renderCreateDuoPlatformFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setCreateDuoPlatformFormModalIsVisible(false);
        }}
        visible={createDuoPlatformFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="New Duo Platform"
      >
        <CreateDuoPlatformForm
          refreshDuoPlatformTable={refreshDuoPlatformTable}
        />
      </ChildModal>
    );
  };

  const renderEditDuoPlatformFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setEditDuoPlatformFormModalIsVisible(false);
        }}
        visible={editDuoPlatformFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Edit Duo Platform"
      >
        <EditDuoPlatformForm
          refreshDuoPlatformTable={refreshDuoPlatformTable}
          duoPlatforms={selectedDuoPlatforms}
        />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'platformName',
      header: 'Name',
      cell: (item: IDuoPlatform) => item.platformName || '-'
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedDuoPlatforms}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Duo Platforms"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Duo Platforms"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderCreateDuoPlatformFormModal()}
      {renderEditDuoPlatformFormModal()}
    </>
  );
};
export default View;
