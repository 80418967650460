import { FC } from 'react';

import { Tabs } from '@awsui/components-react';

import UserReactivationPendingRequestTable from '../userReactivationPendingRequestTable';
import UserReactivationAllRequestTable from '../userReactivationAllRequestTable';

interface ViewProps {}

const View: FC<ViewProps> = () => {
  return (
    <Tabs
      variant="container"
      tabs={[
        {
          label: 'Pending',
          id: 'pending',
          content: <UserReactivationPendingRequestTable />
        },
        {
          label: 'All',
          id: 'all',
          content: <UserReactivationAllRequestTable />
        }
      ]}
    />
  );
};
export default View;
