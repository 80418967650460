import { createContext } from 'react';
import { ICurrentUser } from '../interfaces/domain/user';

interface IGlobalContext {
  currentUser: ICurrentUser;
  logOut: () => void;
  hideChildModals: boolean;
}
export const GlobalContext = createContext<IGlobalContext>(
  {} as IGlobalContext
);
