import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Textarea
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { IRejectUserWorkSpaceProvisionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';

interface ViewProps {
  handleSubmittedForm: (
    data: IRejectUserWorkSpaceProvisionRequest
  ) => Promise<void>;
  formValues: IRejectUserWorkSpaceProvisionRequest;
}

const View: FC<ViewProps> = ({ formValues, handleSubmittedForm }) => {
  const validationSchema = yup.object({
    rejectionReason: yup.string().required()
  });

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={formValues}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="xs">
              <FormField
                label="Rejection Reason"
                errorText={errors.rejectionReason}
              >
                <Textarea
                  value={values.rejectionReason}
                  onChange={({ detail }) =>
                    setFieldValue('rejectionReason', detail.value)
                  }
                  invalid={!!errors.rejectionReason}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
