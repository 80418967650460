import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoType } from '../../../../../../interfaces/integrations/duo';

import CreateDuoTypeForm from '../createDuoTypeForm';
import EditDuoTypeForm from '../editDuoTypeForm';
import ChildModal from '../../../../../common/ChildModal';

interface ViewProps {
  duoTypes: IDuoType[];
  selectedDuoTypes: IDuoType[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (duoType: IDuoType[]) => void;
  duoTypesIsEmpty: () => boolean;
  tableIsLoading: boolean;
  createDuoTypeFormModalIsVisible: boolean;
  setCreateDuoTypeFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  editDuoTypeFormModalIsVisible: boolean;
  setEditDuoTypeFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshDuoTypeTable: () => void;
}

const View: FC<ViewProps> = ({
  duoTypes,
  selectedDuoTypes,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  duoTypesIsEmpty,
  tableIsLoading,
  createDuoTypeFormModalIsVisible,
  setCreateDuoTypeFormModalIsVisible,
  editDuoTypeFormModalIsVisible,
  setEditDuoTypeFormModalIsVisible,
  refreshDuoTypeTable
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No Duo Types</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No Duo Types to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            onClick={() => {
              setCreateDuoTypeFormModalIsVisible(true);
            }}
          >
            Create Duo Type
          </Button>
        }
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching Duo Types</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(duoTypes, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['typeName']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredDuoTypes = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshDuoTypeTable()}
          />

          <Button
            iconName="add-plus"
            disabled={duoTypesIsEmpty()}
            onClick={() => {
              setCreateDuoTypeFormModalIsVisible(true);
            }}
          >
            Create
          </Button>
          <Button
            iconName="settings"
            disabled={disableActionButtons}
            onClick={() => {
              setEditDuoTypeFormModalIsVisible(true);
            }}
          >
            Edit
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredDuoTypes.length
            ? `(${filteredDuoTypes.length}/${duoTypes.length})`
            : `(${duoTypes.length})`
        }
        actions={renderTableActions()}
      >
        Duo Types
      </Header>
    );
  };

  const renderCreateDuoTypeFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setCreateDuoTypeFormModalIsVisible(false);
        }}
        visible={createDuoTypeFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="New Duo Type"
      >
        <CreateDuoTypeForm refreshDuoTypeTable={refreshDuoTypeTable} />
      </ChildModal>
    );
  };

  const renderEditDuoTypeFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setEditDuoTypeFormModalIsVisible(false);
        }}
        visible={editDuoTypeFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Edit Duo Type"
      >
        <EditDuoTypeForm
          refreshDuoTypeTable={refreshDuoTypeTable}
          duoTypes={selectedDuoTypes}
        />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'typeName',
      header: 'Name',
      cell: (item: IDuoType) => item.typeName || '-'
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedDuoTypes}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Duo Types"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Duo Types"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderCreateDuoTypeFormModal()}
      {renderEditDuoTypeFormModal()}
    </>
  );
};
export default View;
