import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Input,
  Select,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { ICreateUser } from '../../../../../interfaces/domain/user';

interface ViewProps {
  formValues: ICreateUser;
  handleSubmittedForm: (data: ICreateUser) => Promise<void>;
  homeGroupSelectValues: SelectProps.Options;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  homeGroupSelectValues,
  flashBarItems
}) => {
  const validationSchema = yup.object({
    firstName: yup.string().matches(/^[a-zA-Z0-9]+$/).max(30).required(),
    lastName: yup.string().matches(/^[a-zA-Z0-9]+$/).max(30).required(),
    corpEmail: yup.string().email().required(),
    homeGroup: yup.string().required()
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={flashBarItems} />
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormField label="First Name" errorText={errors.firstName}>
              <Input
                name="firstName"
                value={values.firstName}
                onChange={({ detail }) =>
                  setFieldValue('firstName', detail.value)
                }
                onBlur={() =>
                  setFieldValue('firstName', values.firstName.trim())
                }
                invalid={!!errors.firstName}
              />
            </FormField>
            <FormField label="Last Name" errorText={errors.lastName}>
              <Input
                name="lastName"
                value={values.lastName}
                onChange={({ detail }) =>
                  setFieldValue('lastName', detail.value)
                }
                onBlur={() => setFieldValue('lastName', values.lastName.trim())}
                invalid={!!errors.lastName}
              />
            </FormField>
            <FormField label="Corp Email" errorText={errors.corpEmail}>
              <Input
                name="corpEmail"
                type="email"
                value={values.corpEmail}
                onChange={({ detail }) =>
                  setFieldValue('corpEmail', detail.value)
                }
                onBlur={() =>
                  setFieldValue('corpEmail', values.corpEmail.trim())
                }
                invalid={!!errors.corpEmail}
              />
            </FormField>

            <FormField label="Home Group" errorText={errors.homeGroup}>
              <Select
                selectedOption={{
                  value: values.homeGroup,
                  label: values.homeGroup
                }}
                onChange={({ detail }) =>
                  setFieldValue('homeGroup', detail.selectedOption.value)
                }
                options={homeGroupSelectValues}
                selectedAriaLabel="Selected"
                filteringType="auto"
              />
            </FormField>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
