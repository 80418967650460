import { FC } from 'react';

import {
  Button,
  SpaceBetween,
  FormField,
  Input,
  Form,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps } from '@awsui/components-react';
import { ICreateDuoPlatform } from '../../../../../../interfaces/integrations/duo';

interface ViewProps {
  formValues: ICreateDuoPlatform;
  flashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: ICreateDuoPlatform) => Promise<void>;
}

const View: FC<ViewProps> = ({
  formValues,
  flashBarItems,
  handleSubmittedForm
}) => {
  const validationSchema = yup.object({
    platformName: yup.string().required().min(3)
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={flashBarItems} />
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormField
              label="DUO Device Platform"
              description="Acceptable values can be pulled from the DUO Admin API documentation"
              errorText={errors.platformName}
            >
              <Input
                name="platformName"
                value={values.platformName}
                onChange={({ detail }) =>
                  setFieldValue('platformName', detail.value)
                }
                invalid={!!errors.platformName}
              />
            </FormField>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
