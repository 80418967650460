import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IDirectory } from '../../../../../interfaces/workSpaces/directory';

import View from './view';

const WorkSpaceDirectoryTable: FC = () => {
  const [directories, setDirectories] = useState<IDirectory[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedDirectories, setSelectedDirectories] = useState<IDirectory[]>(
    []
  );
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);

  const [editDirectoryFormModalIsVisible, setEditDirectoryFormModalIsVisible] =
    useState(false);

  useEffect(() => {
    getDirectories().then((data) => {
      setDirectories(data);
      setTableIsLoading(false);
    });
  }, []);

  const getDirectories = () => {
    const api = new MainServiceApi();
    return api
      .getAllDirectories()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Directories!</>
          }
        ]);
        return [];
      });
  };

  const handleTableRowSelection = (row: IDirectory[]) => {
    if (row.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedDirectories(row);
  };
  const refreshDirectoryTable = () => {
    setTableIsLoading(true);
    getDirectories().then((data) => {
      setDirectories(data);
      setSelectedDirectories([]);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      directories={directories}
      selectedDirectories={selectedDirectories}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      editDirectoryFormModalIsVisible={editDirectoryFormModalIsVisible}
      setEditDirectoryFormModalIsVisible={setEditDirectoryFormModalIsVisible}
      refreshDirectoryTable={refreshDirectoryTable}
    />
  );
};
export default WorkSpaceDirectoryTable;
