import { FC } from 'react';

import {
  Modal,
  Flashbar,
  SpaceBetween,
  Button,
  Box
} from '@awsui/components-react';

interface IndexProps {
  timeLeftMS: number | null;
  isVisible: boolean;
  handleDismiss: () => void;
  handleRefreshActivity: () => void;
}

const InactivityModal: FC<IndexProps> = ({
  timeLeftMS,
  isVisible,
  handleDismiss,
  handleRefreshActivity
}) => {
  const hasExpired = timeLeftMS && timeLeftMS <= 0;

  const minSecDisplay = (timeMS: number) => {
    const totalSeconds = Math.floor(timeMS / 1000);

    const min = Math.floor(totalSeconds / 60);
    const sec = totalSeconds % 60;

    return min > 0
      ? `${min} minutes, ${sec} seconds`
      : `${sec} seconds`;
  }

  const renderInactivityContent = () => {
    if (timeLeftMS === null) {
      // this modal won't be displayed
      return <></>;
    } else if (timeLeftMS <= 0) {
      // session expired
      return (
        <Flashbar
          items={[
            {
              type: 'warning',
              content: 'You\'ve been logged out for being idle too long.'
            }
          ]}
        />
      );
    } else {
      // countdown timer
      return (
        <SpaceBetween size="m">
          <p>You will be logged out for inactivity in <b>{minSecDisplay(timeLeftMS)}</b>.</p>

          <p>Press <b>Continue</b> to stay signed in.</p>
        </SpaceBetween>
      );
    }
  };

  return (
    <Modal
      visible={isVisible}
      size="medium"
      header="Session Idle"
      onDismiss={() => {
        if (hasExpired) {
          handleDismiss();
        }
      }}
      footer={
        <Box float="right">
          <Button
            variant="primary"
            onClick={() => hasExpired ? handleDismiss() : handleRefreshActivity()}
          >
            { hasExpired ? <>Close</> : <>Continue</> }
          </Button>
        </Box>
      }
    >
      {renderInactivityContent()}
    </Modal>
  );
};

export default InactivityModal;
