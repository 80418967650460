import { Dispatch, FC, SetStateAction } from 'react';
import {
  Box,
  Button,
  Header,
  Table,
  SpaceBetween,
  Flashbar,
  FlashbarProps,
  ButtonDropdown,
  Popover,
  StatusIndicator
} from '@awsui/components-react';

import { IUserWorkSpace, UserWorkSpaceAction } from '../../../../interfaces/workSpaces/userworkSpaces';

import DecommissionRequestForm from '../decommissionRequestForm';
import ProvisionRequestForm from '../provisionRequestForm';
import ChildModal from '../../../common/ChildModal';

interface ViewProps {
  selectedWorkSpaces: IUserWorkSpace[];
  disableActionButtons: boolean;
  handleTableRowSelection: (workSpace: IUserWorkSpace[]) => void;
  userWorkSpaces: IUserWorkSpace[];
  userWorkSpacesIsEmpty: () => boolean;
  tableIsLoading: boolean;
  handleRebootButtonPress: () => void;
  handleRebuildButtonPress: () => void;
  handleStartButtonPress: () => void;
  decommissionRequestModalIsVisible: boolean;
  setDecommissionRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  provisionRequestModalIsVisible: boolean;
  setProvisionRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  rebuildConfirmationModalIsVisible: boolean;
  setRebuildConfirmationModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshWorkSpacesTable: () => void;
  allowedWSActions: UserWorkSpaceAction[];
  userEnabled: number;
}

const View: FC<ViewProps> = ({
  selectedWorkSpaces,
  disableActionButtons,
  handleTableRowSelection,
  userWorkSpaces,
  userWorkSpacesIsEmpty,
  tableIsLoading,
  handleRebootButtonPress,
  handleRebuildButtonPress,
  handleStartButtonPress,
  decommissionRequestModalIsVisible,
  setDecommissionRequestModalIsVisible,
  flashBarItems,
  setFlashBarItems,
  provisionRequestModalIsVisible,
  setProvisionRequestModalIsVisible,
  rebuildConfirmationModalIsVisible,
  setRebuildConfirmationModalIsVisible,
  refreshWorkSpacesTable,
  allowedWSActions,
  userEnabled
}) => {
  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshWorkSpacesTable()}
          />
          <ButtonDropdown
            disabled={disableActionButtons}
            items={[
              {
                text: 'Start',
                id: 'start-workspace',
                disabled: !allowedWSActions.includes('start') || !userEnabled
              },
              {
                text: 'Reboot',
                id: 'reboot-workspace',
                disabled: !allowedWSActions.includes('reboot') || !userEnabled
              },
              {
                text: 'Rebuild',
                id: 'rebuild-workspace',
                disabled: !allowedWSActions.includes('rebuild') || !userEnabled
              },
              {
                text: 'Decommission',
                id: 'decommission-workspace',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'start-workspace') {
                handleStartButtonPress();
              } else if (detail.id === 'reboot-workspace') {
                handleRebootButtonPress();
              } else if (detail.id === 'rebuild-workspace') {
                setRebuildConfirmationModalIsVisible(true);
              } else if (detail.id === 'decommission-workspace') {
                setDecommissionRequestModalIsVisible(true);
              }
            }}
          >
            Actions
          </ButtonDropdown>
          <Button
            iconName="add-plus"
            disabled={userWorkSpacesIsEmpty() || !userEnabled}
            onClick={() => {
              setProvisionRequestModalIsVisible(true);
            }}
          >
            Request New WS
          </Button>
        </SpaceBetween>
      </>
    );
  };

  const renderTableHeader = () => {
    return <Header actions={renderTableActions()}> Your Workspaces </Header>;
  };

  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No WorkSpaces</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No WorkSpaces to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            disabled={!userEnabled}
            onClick={() => {
              setProvisionRequestModalIsVisible(true);
            }}
          >
            Request New WorkSpace
          </Button>
        }
      </Box>
    );
  };
  const renderDecommissionRequestModal = (
    <ChildModal
      onDismiss={() => {
        setDecommissionRequestModalIsVisible(false);
      }}
      visible={decommissionRequestModalIsVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header="Decommission WorkSpace"
    >
      <DecommissionRequestForm
        userWorkSpaces={selectedWorkSpaces}
        setFlashBarItems={setFlashBarItems}
        setDecommissionRequestModalIsVisible={
          setDecommissionRequestModalIsVisible
        }
      />
    </ChildModal>
  );
  const renderProvisionRequestModal = (
    <ChildModal
      onDismiss={() => {
        setProvisionRequestModalIsVisible(false);
      }}
      visible={provisionRequestModalIsVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header="Provision WorkSpace"
    >
      <ProvisionRequestForm
        setFlashBarItems={setFlashBarItems}
        setProvisionRequestModalIsVisible={setProvisionRequestModalIsVisible}
      />
    </ChildModal>
  );

  const renderConfirmRebuildRequestModal = (
    <ChildModal
      onDismiss={() => {
        setRebuildConfirmationModalIsVisible(false);
      }}
      visible={rebuildConfirmationModalIsVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header="Rebuild Confirmation"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => {
                setRebuildConfirmationModalIsVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setRebuildConfirmationModalIsVisible(false);
                handleRebuildButtonPress();
              }}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      This will take about 45 minutes to complete and the WorkSpace will be
      inaccessible during this time.
    </ChildModal>
  );

  const tableColumnDefinitions = [
    {
      id: 'workSpaceId',
      header: 'Workspace ID',
      cell: (item: IUserWorkSpace) => item.workSpaceId || '-'
    },
    {
      id: 'computeTypeName',
      header: 'Type',
      cell: (item: IUserWorkSpace) => item.computeTypeName || '-'
    },
    {
      id: 'workSpaceState',
      header: 'State',
      cell: (item: IUserWorkSpace) => item.workSpaceState || '-'
    },
    {
      id: 'registrationCode',
      header: 'Registration Code',
      cell: (item: IUserWorkSpace) => {
        return (
          <>
            {item.registrationCode || '-'}
            <Popover
              size="small"
              position="top"
              triggerType="custom"
              dismissButton={false}
              content={
                <StatusIndicator type="success">Copied!</StatusIndicator>
              }
            >
              <Button
                iconName="copy"
                variant="inline-icon"
                onClick={() => {
                  navigator.clipboard.writeText(item.registrationCode || '');
                }}
              />
            </Popover>
          </>
        );
      }
    }
  ];

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          loading={tableIsLoading}
          trackBy="workSpaceId"
          selectedItems={selectedWorkSpaces}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={userWorkSpaces}
          loadingText="Loading resources"
          empty={renderEmptyTableContent()}
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
        />
      </SpaceBetween>

      {renderDecommissionRequestModal}
      {renderProvisionRequestModal}
      {renderConfirmRebuildRequestModal}
    </>
  );
};
export default View;
