import { FC } from 'react';
import { TextContent } from '@awsui/components-react';
import { ConfigHelper } from '../../lib/config/helper';

const Header: FC = () => {
  return (
    <div className="header">
      <img src="./cwe-logo.png" width="60" height="60" alt="CWE logo" />
      <TextContent>
        <h4 className="text-orange">
          &nbsp;{ConfigHelper.get().TEAM_NAME_FULL} |{' '}
          <span className="text-white">Workspaces</span>
        </h4>
      </TextContent>
    </div>
  );
};

export default Header;
