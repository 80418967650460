import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoPlatform } from '../../../../../../interfaces/integrations/duo';

import View from './view';

const DuoPlatformTable: FC = () => {
  const [duoPlatforms, setDuoPlatforms] = useState<IDuoPlatform[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedDuoPlatforms, setSelectedDuoPlatforms] = useState<
    IDuoPlatform[]
  >([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    createDuoPlatformFormModalIsVisible,
    setCreateDuoPlatformFormModalIsVisible
  ] = useState(false);
  const [
    editDuoPlatformFormModalIsVisible,
    setEditDuoPlatformFormModalIsVisible
  ] = useState(false);

  useEffect(() => {
    getDuoPlatforms().then((data) => {
      setDuoPlatforms(data);
      setTableIsLoading(false);
    });
  }, []);

  const getDuoPlatforms = () => {
    const api = new MainServiceApi();
    return api
      .getAllDuoPlatforms()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get DUO Platforms!</>
          }
        ]);
        return [];
      });
  };
  const duoPlatformsIsEmpty = () => {
    return duoPlatforms.length === 0;
  };

  const handleTableRowSelection = (duoPlatform: IDuoPlatform[]) => {
    if (duoPlatform.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedDuoPlatforms(duoPlatform);
  };
  const refreshDuoPlatformTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getDuoPlatforms().then((data) => {
      setDuoPlatforms(data);
      setSelectedDuoPlatforms([]);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      duoPlatforms={duoPlatforms}
      selectedDuoPlatforms={selectedDuoPlatforms}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      duoPlatformsIsEmpty={duoPlatformsIsEmpty}
      tableIsLoading={tableIsLoading}
      createDuoPlatformFormModalIsVisible={createDuoPlatformFormModalIsVisible}
      setCreateDuoPlatformFormModalIsVisible={
        setCreateDuoPlatformFormModalIsVisible
      }
      editDuoPlatformFormModalIsVisible={editDuoPlatformFormModalIsVisible}
      setEditDuoPlatformFormModalIsVisible={
        setEditDuoPlatformFormModalIsVisible
      }
      refreshDuoPlatformTable={refreshDuoPlatformTable}
    />
  );
};
export default DuoPlatformTable;
