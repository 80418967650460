import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Textarea,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { ICreateUserWorkSpaceDecommissionRequest } from '../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';
import { FlashbarProps } from '@awsui/components-react';

interface ViewProps {
  formValues: ICreateUserWorkSpaceDecommissionRequest;
  handleSubmittedForm: (
    data: ICreateUserWorkSpaceDecommissionRequest
  ) => Promise<void>;
  modalFlashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  modalFlashBarItems
}) => {
  const validationSchema = yup.object({
    justification: yup.string().required()
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={formValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={modalFlashBarItems} />
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormField
              description="Justification for needing to decommission this workspace."
              label="Justification"
              errorText={errors.justification}
            >
              <Textarea
                value={values.justification}
                ariaRequired
                onChange={({ detail }) =>
                  setFieldValue('justification', detail.value)
                }
                invalid={!!errors.justification}
              />
            </FormField>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
