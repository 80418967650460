import { FC } from 'react';
import {
  ColumnLayout,
  Flashbar,
  SpaceBetween,
  StatusIndicator,
  Table
} from '@awsui/components-react';
import Moment from 'react-moment';

import { FlashbarProps } from '@awsui/components-react';

import ValueWithLabel from '../../../../common/ValueWithLabel';
import { IUser } from '../../../../../interfaces/domain/user';

interface ViewProps {
  user: IUser;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({ user, flashBarItems }) => {
  const renderUserAccountStatus = (enabled: number) => {
    if (enabled) {
      return <StatusIndicator type="success">Not locked</StatusIndicator>;
    } else {
      return <StatusIndicator type="warning">Locked</StatusIndicator>;
    }
  };

  const tableColumnDefinitions = [
    {
      id: 'attribute',
      header: 'Attribute',
      cell: (item: any) => item.name
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item: any) => item.value
    }
  ];
  const tableItems = [
    {
      name: 'Home Group',
      value: user.homeGroup || '-'
    },
    {
      name: 'Locked?',
      value: renderUserAccountStatus(user.enabled)
    },
    {
      name: 'OU Path',
      value: user.distinguishedName || '-'
    },
    {
      name: 'Password Expires At',
      value: user.passwordExpiresAt || '-'
    },
    {
      name: 'WorkMail Email',
      value: user.workMailEmail || '-'
    },
    {
      name: 'WorkMail ID',
      value: user.workMailId || '-'
    },
    {
      name: 'WorkDocs ID',
      value: user.workDocsId || '-'
    },
    {
      name: 'Created At',
      value: (
        <Moment unix local format="LLLL">
          {user.createdAt || '-'}
        </Moment>
      )
    },
    {
      name: 'Updated At',
      value: (
        <Moment unix local format="LLLL">
          {user.updatedAt || '-'}
        </Moment>
      )
    },
    {
      name: 'Password Expires At',
      value: (
        <Moment unix local format="LLLL">
          {user.passwordExpiresAt || '-'}
        </Moment>
      )
    }
  ];

  return (
    <SpaceBetween size="xs">
      <Flashbar items={flashBarItems} />
      <SpaceBetween size="l">
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label="User Name">
            {user.userName || '-'}
          </ValueWithLabel>

          <ValueWithLabel label="Display Name">
            {user.displayName || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Email">{user.corpEmail || '-'}</ValueWithLabel>
        </ColumnLayout>

        <Table columnDefinitions={tableColumnDefinitions} items={tableItems} />
      </SpaceBetween>
    </SpaceBetween>
  );
};
export default View;
