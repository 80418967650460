import { FC } from 'react';

import {
  Button,
  SpaceBetween,
  FormField,
  Form,
  Flashbar,
  Select
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { IUpdateUser } from '../../../../../interfaces/domain/user';

interface ViewProps {
  formValues: IUpdateUser;
  flashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: IUpdateUser) => Promise<void>;
  homeGroupSelectValues: SelectProps.Options;
}

const View: FC<ViewProps> = ({
  formValues,
  flashBarItems,
  handleSubmittedForm,
  homeGroupSelectValues
}) => {
  const validationSchema = yup.object({
    homeGroup: yup.string().required()
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSubmittedForm(data).then((resp) => {
            resetForm();
            setSubmitting(false);
          });
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
          <SpaceBetween direction="vertical" size="xs">
            <Flashbar items={flashBarItems} />
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="m">
                <FormField
                  label="Home Group"
                  description="Home Group to place user in."
                  errorText={errors.homeGroup}
                >
                  <Select
                    name="homeGroup"
                    selectedOption={{ value: values.homeGroup }}
                    onChange={({ detail }) =>
                      setFieldValue('homeGroup', detail.selectedOption.value)
                    }
                    options={homeGroupSelectValues}
                    invalid={!!errors.homeGroup}
                    filteringType="auto"
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </SpaceBetween>
        )}
      </Formik>
    </>
  );
};
export default View;
