import { FC, useEffect, useState } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { AppLayout, Button, Flashbar, FlashbarProps, SpaceBetween } from '@awsui/components-react';

import { AdminRoute, UserRoute, DuoRoute } from './lib/routing/customRoutes';

import { SettingsContext } from './context/settingsContext';
import { GlobalContext } from './context/globalContext';

import { ICurrentUser } from './interfaces/domain/user';

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import SideNav from './components/common/SideNav';
import ToolBox from './components/common/ToolBox';
import NotFound from './components/common/NotFound';
import LogOut from './components/common/LogOut';

import UsersWorkSpaces from './components/user/workSpaces/workSpacesTable';
import UserPasswordReset from './components/user/domain/passwordReset';
import UsersTable from './components/admin/domain/users/userTable';
import DuoDevicePage from './components/user/integrations/duo/duoDevicePage';
import PortalSettingsPage from './components/admin/portal/portalSettingsPage';
import DuoTypeTable from './components/admin/integrations/duo/type/duoTypeTable';
import DuoPlatformTable from './components/admin/integrations/duo/platform/duoPlatformTable';
import WorkSpaceBundleTable from './components/admin/workSpaces/workSpaceBundles/workSpaceBundleTable';
import SupportTopicTable from './components/admin/portal/supportTopic/supportTopicTable';
import HomeGroupTable from './components/admin/domain/homeGroups/homeGroupTable';
import ContactSupport from './components/user/portal/contactSupport';
import UserWorkSpaceDecommissionRequestPage from './components/admin/workSpaces/userWorkSpaceDecommissionRequests/userWorkSpaceDecommissionRequestPage';
import UserWorkSpaceProvisionRequestPage from './components/admin/workSpaces/userWorkSpaceProvisionRequests/userWorkSpaceProvisionRequestPage';
import UserAccountRequestPage from './components/admin/domain/userAccountRequests/userAccountRequestPage';
import WorkSpaceDirectoryTable from './components/admin/workSpaces/workSpaceDirectories/workSpaceDirectoryTable';
import ApprovedDomainTable from './components/admin/authentication/approvedDomains/approvedDomainsTable';
import Documentation from './components/user/portal/documentation';
import Settings from './components/common/Settings';
import UserReactivationRequestPage from './components/admin/domain/userReactivationRequests/userReactivationRequestPage';
import CreateUserReactivationRequest from './components/user/domain/createUserReactivationRequest';

import { darkModeEnabled, processDarkModeChange } from './lib/utils/darkMode';
import { MainServiceApi } from './lib/api/mainServiceApi';
import ChildModal from './components/common/ChildModal';

interface IHome {
  isAuthenticated: boolean;
  isLoading: boolean;
  currentUser: ICurrentUser;
  isPortalInitialized: boolean;
  logOut: () => void;
  hideChildModals: boolean;
}
const Home: FC<IHome> = ({
  isAuthenticated,
  isLoading,
  currentUser,
  logOut,
  hideChildModals
}) => {
  const [
    createUserReactivationRequestModalIsVisible,
    setCreateUserReactivationRequestModalIsVisible
  ] = useState(false);

  const [isDuoEnabled, setIsDuoEnabled] = useState(false);

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    if (currentUser.enabled === 0) { // not using ! because it can be temporarily undefined right before Auth finishes login
      setFlashBarItems([
        {
          type: 'warning',
          dismissible: false,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          action:
            <Button
              id="reactivate-request-button"
              onClick={() => setCreateUserReactivationRequestModalIsVisible(true)}
            >
              Request Account Reactivation
            </Button>,
          content: <>
            Your account is disabled. Most portal functions won't work.
            You may request your account to be reactivated.
          </>
        }
      ]);
    }
  }, [currentUser]);

  useEffect(() => {
    processDarkModeChange(darkModeEnabled());

    // moving this check here since refreshing the page previously
    // reset this state to false (it was only set at logIn)
    const api = new MainServiceApi();

    api
      .getPortalSettingsByNames(['duoEnabled'])
      .then((data) => {
        setIsDuoEnabled(data.duoEnabled);
      }).catch(() => {
        setIsDuoEnabled(false);
        setFlashBarItems([{
          type: 'error',
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashBarItems([]),
          content: <>Failed to check if DUO is enabled!</>
        }])
      });
  }, []);

  const renderCreateUserReactivationRequestModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setCreateUserReactivationRequestModalIsVisible(false);
        }}
        visible={createUserReactivationRequestModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="New User Reactivation Request"
      >
        <CreateUserReactivationRequest />
      </ChildModal>
    );
  };

  const userRoutes = [
    <UserRoute
      exact
      path="/"
      isAuthenticated={isAuthenticated}
      userEnabled={1}
      component={UsersWorkSpaces}
    />,
    <UserRoute
      exact
      path="/user/password_reset"
      isAuthenticated={isAuthenticated}
      userEnabled={currentUser.enabled}
      component={UserPasswordReset}
    />,
    <DuoRoute
      exact
      path="/user/integrations/duo/devices"
      isAuthenticated={isAuthenticated}
      isDuoEnabled={isDuoEnabled}
      userEnabled={currentUser.enabled}
      component={DuoDevicePage}
    />,
    <UserRoute
      exact
      path="/support/contact"
      isAuthenticated={isAuthenticated}
      userEnabled={1}
      component={ContactSupport}
    />,
    <UserRoute
      exact
      path="/support/docs"
      isAuthenticated={isAuthenticated}
      userEnabled={1}
      component={Documentation}
    />,
    <UserRoute
      exact
      path="/settings"
      isAuthenticated={isAuthenticated}
      userEnabled={1}
      component={Settings}
    />
  ];
  const adminRoutes = [
    <AdminRoute
      exact
      path="/admin/users"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={UsersTable}
    />,
    <AdminRoute
      exact
      path="/admin/user_account_requests"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={UserAccountRequestPage}
    />,
    <AdminRoute
      exact
      path="/admin/user_reactivation_requests"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={UserReactivationRequestPage}
    />,
    <AdminRoute
      exact
      path="/admin/user_workspace_provision_requests"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={UserWorkSpaceProvisionRequestPage}
    />,
    <AdminRoute
      exact
      path="/admin/user_workspace_decommission_requests"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={UserWorkSpaceDecommissionRequestPage}
    />,
    <AdminRoute
      exact
      path="/admin/portal/settings"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={PortalSettingsPage}
    />,
    <AdminRoute
      exact
      path="/admin/dropdowns/duo/types"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={DuoTypeTable}
    />,
    <AdminRoute
      exact
      path="/admin/dropdowns/duo/platforms"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={DuoPlatformTable}
    />,
    <AdminRoute
      exact
      path="/admin/dropdowns/workspaces/bundles"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={WorkSpaceBundleTable}
    />,
    <AdminRoute
      exact
      path="/admin/dropdowns/workspaces/directories"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={WorkSpaceDirectoryTable}
    />,
    <AdminRoute
      exact
      path="/admin/dropdowns/portal/support_topics"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={SupportTopicTable}
    />,
    <AdminRoute
      exact
      path="/admin/dropdowns/domain/home_groups"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={HomeGroupTable}
    />,
    <AdminRoute
      exact
      path="/admin/auth/approved_domains"
      isAuthenticated={isAuthenticated}
      isAdmin={currentUser.isAdmin}
      userEnabled={currentUser.enabled}
      component={ApprovedDomainTable}
    />
  ];
  const allRoutes = [
    ...userRoutes,
    ...adminRoutes,
    <Route exact path="/logout" component={LogOut} />,
    <Route exact component={NotFound} />
  ];

  return (
    <SettingsContext.Provider
      value={{
        isDuoEnabled
      }}
    >
      <GlobalContext.Provider
        value={{
          currentUser,
          logOut,
          hideChildModals
        }}
      >
        <Router>
          <Header />
          <AppLayout
            footerSelector=".footer"
            headerSelector=".header"
            content={
              <SpaceBetween direction="vertical" size="xs">
                <Flashbar items={flashBarItems} />
                <Switch>{allRoutes}</Switch>
                {isAuthenticated ? renderCreateUserReactivationRequestModal() : <></>}
              </SpaceBetween>
            }
            navigation={<SideNav />}
            tools={<ToolBox />}
            toolsHide={!isAuthenticated || isLoading}
            navigationHide={!isAuthenticated || isLoading}
          />
          <Footer />
        </Router>
      </GlobalContext.Provider>
    </SettingsContext.Provider>
  );
};

export default Home;
