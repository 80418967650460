import {
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext
} from 'react';

import { useInterval } from '../../../common/CustomHooks';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';
import { GlobalContext } from '../../../../context/globalContext';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { ICreateUserWorkSpaceProvisionRequest } from '../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';

import View from './view';

interface IndexProps {
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  setProvisionRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
}
const ProvisionRequestForm: FC<IndexProps> = ({
  setFlashBarItems,
  setProvisionRequestModalIsVisible
}) => {
  const {
    currentUser: { userName, corpEmail }
  } = useContext(GlobalContext);

  const [formValues, setFormValues] =
    useState<ICreateUserWorkSpaceProvisionRequest>({
      userName: '',
      corpEmail: '',
      bundleId: '',
      directoryId: '',
      justification: ''
    });

  const [modalFlashBarItems, setModalFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const [bundleSelectValues, setBundleSelectValues] =
    useState<SelectProps.Options>([]);

  const [adDirectorySelectValues, setAdDirectorySelectValues] =
    useState<SelectProps.Options>([]);

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      ...{ userName, corpEmail }
    }));
  }, [corpEmail, userName]);

  useInterval(() => {
    refreshDropDowns();
  }, []);

  const refreshDropDowns = () => {
    const api = new MainServiceApi();
    api
      .getHomeGroupByCurrentUser()
      .then((data) => {
        let tempBundleSelectValues = data.bundles
          .map((bundle) => ({
            label: bundle.bundleName,
            value: bundle.bundleId
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        let tempAdDirectorySelectValues = data.adDirectories
          .map((adDirectory) => ({
            label: adDirectory.alias,
            value: adDirectory.directoryId
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        setBundleSelectValues(tempBundleSelectValues);
        setAdDirectorySelectValues(tempAdDirectorySelectValues);

        if (tempAdDirectorySelectValues.length === 0) {
          setModalFlashBarItems([
            {
              type: 'warning',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setModalFlashBarItems([]),
              content: (
                <>
                  You cannot request anymore WorkSpaces! Contact your admin for
                  more details.
                </>
              )
            }
          ]);
        }
      })
      .catch(() => {
        setModalFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setModalFlashBarItems([]),
            content: <>Unable to retrieve data for this provision request!</>
          }
        ]);
      });
  };
  const handleSubmittedForm = async (
    data: ICreateUserWorkSpaceProvisionRequest
  ) => {
    setModalFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .requestWorkSpaceProvisionRequest(data)
      .then(() => {
        setProvisionRequestModalIsVisible(false);
        refreshDropDowns();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Your WorkSpace Provision request has been received!</>
          }
        ]);
        setModalFlashBarItems([]);
      })
      .catch(() => {
        setModalFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setModalFlashBarItems([]),
            content: <>Unable to submit your WorkSpace Provision request!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      bundleSelectValues={bundleSelectValues}
      modalFlashBarItems={modalFlashBarItems}
      adDirectorySelectValues={adDirectorySelectValues}
    />
  );
};
export default ProvisionRequestForm;
