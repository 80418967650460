import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IApprovedDomain } from '../../../../../interfaces/authentication/approvedDomain';

import View from './view';

const ApprovedDomainTable: FC = () => {
  const [items, setItems] = useState<IApprovedDomain[]>([]);
  const [selectedItems, setSelectedItems] = useState<IApprovedDomain[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [createFormModalIsVisible, setCreateFormModalIsVisible] =
    useState(false);
  const [editFormModalIsVisible, setEditFormModalIsVisible] = useState(false);

  useEffect(() => {
    getApprovedDomains().then((data) => {
      setItems(data);
      setTableIsLoading(false);
    });
  }, []);

  const getApprovedDomains = () => {
    const api = new MainServiceApi();
    return api
      .getAllApprovedDomains()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Approved Domains!</>
          }
        ]);
        return [];
      });
  };
  const itemsIsEmpty = () => {
    return items.length === 0;
  };

  const handleTableRowSelection = (row: IApprovedDomain[]) => {
    if (row.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedItems(row);
  };
  const refreshTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getApprovedDomains().then((data) => {
      setItems(data);
      setSelectedItems([]);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      items={items}
      selectedItems={selectedItems}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      itemsIsEmpty={itemsIsEmpty}
      tableIsLoading={tableIsLoading}
      createFormModalIsVisible={createFormModalIsVisible}
      setCreateFormModalIsVisible={setCreateFormModalIsVisible}
      editFormModalIsVisible={editFormModalIsVisible}
      setEditFormModalIsVisible={setEditFormModalIsVisible}
      refreshTable={refreshTable}
    />
  );
};
export default ApprovedDomainTable;
