import { Dispatch, FC, SetStateAction } from 'react';
import {
  Box,
  Table,
  SpaceBetween,
  Flashbar,
  FlashbarProps,
  ButtonDropdown,
  ColumnLayout,
  Button
} from '@awsui/components-react';

import ValueWithLabel from '../../../common/ValueWithLabel';
import { IUserWorkSpace, UserWorkSpaceAction } from '../../../../interfaces/workSpaces/userworkSpaces';
import { IUser } from '../../../../interfaces/domain/user';
import ChildModal from '../../../common/ChildModal';

interface ViewProps {
  user: IUser;
  selectedWorkSpaces: IUserWorkSpace[];
  disableActionButtons: boolean;
  handleTableRowSelection: (workSpace: IUserWorkSpace[]) => void;
  userWorkSpaces: IUserWorkSpace[];
  handleRebootButtonPress: () => void;
  handleRebuildButtonPress: () => void;
  handleDecommissionButtonPress: () => void;
  handleStartButtonPress: () => void;
  flashBarItems: FlashbarProps.MessageDefinition[];
  allowedWSActions: UserWorkSpaceAction[];
  rebuildConfirmationModalIsVisible: boolean;
  setRebuildConfirmationModalIsVisible: Dispatch<SetStateAction<boolean>>;
  decommissionConfirmationModalIsVisible: boolean;
  setDecommissionConfirmationModalIsVisible: Dispatch<SetStateAction<boolean>>;
}

const View: FC<ViewProps> = ({
  user,
  selectedWorkSpaces,
  disableActionButtons,
  handleTableRowSelection,
  userWorkSpaces,
  handleRebootButtonPress,
  handleRebuildButtonPress,
  handleDecommissionButtonPress,
  handleStartButtonPress,
  flashBarItems,
  allowedWSActions,
  rebuildConfirmationModalIsVisible,
  setRebuildConfirmationModalIsVisible,
  decommissionConfirmationModalIsVisible,
  setDecommissionConfirmationModalIsVisible
}) => {
  const tableColumnDefinitions = [
    {
      id: 'workSpaceId',
      header: 'Workspace ID',
      cell: (item: IUserWorkSpace) => item.workSpaceId || '-'
    },
    {
      id: 'registrationCode',
      header: 'Registration Code',
      cell: (item: IUserWorkSpace) => item.registrationCode || '-'
    },
    {
      id: 'bundleId',
      header: 'Bundle ID',
      cell: (item: IUserWorkSpace) => item.bundleId || '-'
    },
    {
      id: 'computeTypeName',
      header: 'Type',
      cell: (item: IUserWorkSpace) => item.computeTypeName || '-'
    },
    {
      id: 'workSpaceState',
      header: 'State',
      cell: (item: IUserWorkSpace) => item.workSpaceState || '-'
    },
    {
      id: 'directoryId',
      header: 'Directory Id',
      cell: (item: IUserWorkSpace) => item.directoryId || '-'
    }
  ];

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <ButtonDropdown
            variant="normal"
            disabled={disableActionButtons}
            items={[
              {
                text: 'Start',
                id: 'start-workspace',
                disabled: !allowedWSActions.includes('start')
              },
              {
                text: 'Reboot',
                id: 'reboot-workspace',
                disabled: !allowedWSActions.includes('reboot')
              },
              {
                text: 'Rebuild',
                id: 'rebuild-workspace',
                disabled: !allowedWSActions.includes('rebuild')
              },
              {
                text: 'Decommission',
                id: 'decommission-workspace',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'start-workspace') {
                handleStartButtonPress();
              } else if (detail.id === 'reboot-workspace') {
                handleRebootButtonPress();
              } else if (detail.id === 'rebuild-workspace') {
                setRebuildConfirmationModalIsVisible(true);
              } else if (detail.id === 'decommission-workspace') {
                setDecommissionConfirmationModalIsVisible(true);
              }
            }}
          >
            Actions
          </ButtonDropdown>
        </SpaceBetween>
      </>
    );
  };

  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No WorkSpaces</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No WorkSpaces to display.
        </Box>
      </Box>
    );
  };

  const renderConfirmRebuildRequestModal = (
    <ChildModal
      onDismiss={() => {
        setRebuildConfirmationModalIsVisible(false);
      }}
      visible={rebuildConfirmationModalIsVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header="Rebuild Confirmation"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => {
                setRebuildConfirmationModalIsVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleRebuildButtonPress();
              }}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      This will take about 45 minutes to complete and the WorkSpace will be
      inaccessible during this time.
    </ChildModal>
  );

  const renderConfirmDecommissionRequestModal = (
    <ChildModal
      onDismiss={() => {
        setDecommissionConfirmationModalIsVisible(false);
      }}
      visible={decommissionConfirmationModalIsVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header="Decommission Confirmation"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => {
                setDecommissionConfirmationModalIsVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleDecommissionButtonPress();
              }}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      This will take about 15 minutes to complete. User will loose all data on
      this WorkSpace that has not been backed up elsewhere.
    </ChildModal>
  );

  return (
    <SpaceBetween size="l">
      <ColumnLayout columns={2} variant="text-grid">
        <ValueWithLabel label="User Name">
          {user.userName || '-'}
        </ValueWithLabel>

        <ValueWithLabel label="Display Name">
          {user.displayName || '-'}
        </ValueWithLabel>
      </ColumnLayout>

      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          trackBy="workSpaceId"
          selectedItems={selectedWorkSpaces}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={userWorkSpaces}
          loadingText="Loading resources"
          empty={renderEmptyTableContent()}
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          footer={renderTableActions()}
        />
      </SpaceBetween>
      {renderConfirmRebuildRequestModal}
      {renderConfirmDecommissionRequestModal}
    </SpaceBetween>
  );
};
export default View;
