import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { IUserWorkSpaceProvisionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

interface IndexProps {
  selectedUserWorkSpaceProvisionRequests: IUserWorkSpaceProvisionRequest[];
}

const UserWorkSpaceProvisionRequestDetails: FC<IndexProps> = ({
  selectedUserWorkSpaceProvisionRequests
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const [userWorkSpaceProvisionRequest, setUserWorkSpaceProvisionRequest] =
    useState<IUserWorkSpaceProvisionRequest>({
      uuid: '',
      userName: '',
      corpEmail: '',
      requestStatus: '',
      rejectionReason: '',
      bundleId: '',
      directoryId: '',
      justification: '',
      deprecated: 0,
      createdAt: 0,
      updatedAt: 0,
      updatedBy: ''
    });

  useEffect(() => {
    const api = new MainServiceApi();

    for (const selectedUserWorkSpaceProvisionRequest of selectedUserWorkSpaceProvisionRequests) {
      api
        .getUserWorkSpaceProvisionRequestByUuid(
          selectedUserWorkSpaceProvisionRequest.uuid
        )
        .then((data) => {
          setUserWorkSpaceProvisionRequest(data);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to get User WorkSpace Provision Request data!</>
            }
          ]);
        });
    }
  }, [selectedUserWorkSpaceProvisionRequests]);

  return (
    <View
      userWorkSpaceProvisionRequest={userWorkSpaceProvisionRequest}
      flashBarItems={flashBarItems}
    />
  );
};
export default UserWorkSpaceProvisionRequestDetails;
