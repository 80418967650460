import { FC, useState, useCallback } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUserWorkSpaceProvisionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceProvisionRequest';

import View from './view';

const UserWorkSpacePendingProvisionRequestTable: FC = () => {
  const [userWorkSpaceProvisionRequests, setUserWorkSpaceProvisionRequests] =
    useState<IUserWorkSpaceProvisionRequest[]>([]);
  const [
    selectedUserWorkSpaceProvisionRequests,
    setSelectedUserWorkSpaceProvisionRequests
  ] = useState<IUserWorkSpaceProvisionRequest[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    rejectUserWorkSpaceProvisionRequestModalIsVisible,
    setRejectUserWorkSpaceProvisionRequestModalIsVisible
  ] = useState(false);
  const [
    editUserWorkSpaceProvisionRequestModalIsVisible,
    setEditUserWorkSpaceProvisionRequestModalIsVisible
  ] = useState(false);
  const [
    userWorkSpaceProvisionRequestDetailsModalIsVisible,
    setUserWorkSpaceProvisionRequestDetailsModalIsVisible
  ] = useState(false);

  const getUserWorkSpaceProvisionRequests = useCallback(() => {
    handleTableRowSelection([]);
    setTableIsLoading(true);

    const api = new MainServiceApi();
    return api
      .getPendingUserWorkSpaceProvisionRequests()
      .then((data) => {
        setUserWorkSpaceProvisionRequests(data);
        setTableIsLoading(false);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: (
              <>Unable to get Pending User WorkSpace Provision Requests!</>
            )
          }
        ]);

        return [];
      });
  }, []);

  useInterval(() => {
    getUserWorkSpaceProvisionRequests();
  }, [getUserWorkSpaceProvisionRequests], 60000);

  const userWorkSpaceProvisionRequestsIsEmpty = () => {
    return userWorkSpaceProvisionRequests.length === 0;
  };

  const handleTableRowSelection = (
    userWorkSpaceProvisionRequest: IUserWorkSpaceProvisionRequest[]
  ) => {
    if (userWorkSpaceProvisionRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedUserWorkSpaceProvisionRequests(userWorkSpaceProvisionRequest);
  };
  const refreshUserWorkSpaceProvisionRequestTable = () => {
    getUserWorkSpaceProvisionRequests();
  };

  const handleApproval = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();

    for (const selectedUserWorkSpaceProvisionRequest of selectedUserWorkSpaceProvisionRequests) {
      api
        .approveUserWorkSpaceProvisionRequest(
          selectedUserWorkSpaceProvisionRequest.uuid
        )
        .then((resp) => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  User WorkSpace Provision Request for{' '}
                  {selectedUserWorkSpaceProvisionRequest.userName} was approved
                  successfully!
                </>
              )
            }
          ]);
          refreshUserWorkSpaceProvisionRequestTable();
        })
        .catch((resp) => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  Unable to approved User WorkSpace Provision Request for
                  {selectedUserWorkSpaceProvisionRequest.userName}!
                </>
              )
            }
          ]);
        });
    }
  };

  return (
    <View
      userWorkSpaceProvisionRequests={userWorkSpaceProvisionRequests}
      selectedUserWorkSpaceProvisionRequests={
        selectedUserWorkSpaceProvisionRequests
      }
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      userWorkSpaceProvisionRequestsIsEmpty={
        userWorkSpaceProvisionRequestsIsEmpty
      }
      tableIsLoading={tableIsLoading}
      refreshUserWorkSpaceProvisionRequestTable={
        refreshUserWorkSpaceProvisionRequestTable
      }
      handleApproval={handleApproval}
      rejectUserWorkSpaceProvisionRequestModalIsVisible={
        rejectUserWorkSpaceProvisionRequestModalIsVisible
      }
      setRejectUserWorkSpaceProvisionRequestModalIsVisible={
        setRejectUserWorkSpaceProvisionRequestModalIsVisible
      }
      editUserWorkSpaceProvisionRequestModalIsVisible={
        editUserWorkSpaceProvisionRequestModalIsVisible
      }
      setEditUserWorkSpaceProvisionRequestModalIsVisible={
        setEditUserWorkSpaceProvisionRequestModalIsVisible
      }
      userWorkSpaceProvisionRequestDetailsModalIsVisible={
        userWorkSpaceProvisionRequestDetailsModalIsVisible
      }
      setUserWorkSpaceProvisionRequestDetailsModalIsVisible={
        setUserWorkSpaceProvisionRequestDetailsModalIsVisible
      }
    />
  );
};
export default UserWorkSpacePendingProvisionRequestTable;
