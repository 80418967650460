import Cookies from 'js-cookie';

export const authConfig = {
  userPoolId: Cookies.get('user-pool-id') || 'us-east-1_b4KvvOOvf',
  userPoolWebClientId:
    Cookies.get('user-pool-web-client-id') || '1mp67dt2gvrtr2t9op1r0dak6m',
  region: Cookies.get('region') || 'us-east-1;',
  mandatorySignIn: true,
  oauth: {
    domain: Cookies.get('oauth-domain'),
    scope: ['email', 'openid'],
    redirectSignIn: Cookies.get('oauth-redirect-url'),
    redirectSignOut: Cookies.get('oauth-redirect-url'),
    responseType: 'code'
  },
  storage: window.sessionStorage
};

export const inactivityConfig = {
  inactivityCheckIntervalMS: Number(Cookies.get('inactivity-check-interval-ms')) || 1000, // default: 1 second
  inactivityWarnThresholdMS: Number(Cookies.get('inactivity-warn-threshold-seconds')) * 1000 || 60000, // default: 1 min
  inactivityLogoutThresholdSec: Number(Cookies.get('inactivity-logout-threshold-seconds')) || 1800 // default: 30 minutes
};
