import { FC, Dispatch, SetStateAction } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import {
  Button,
  Header,
  SpaceBetween,
  Form,
  Container,
  FormField,
  Input,
  Flashbar,
  FlashbarProps,
  ColumnLayout,
  HelpPanel
} from '@awsui/components-react';

import { IResetPassword } from '../../../../interfaces/domain/user';

interface ViewProps {
  formValues: IResetPassword;
  handleSubmittedForm: (data: IResetPassword) => Promise<void>;
  flashBarItems: FlashbarProps.MessageDefinition[];
  viewPassword: boolean;
  setViewPassword: Dispatch<SetStateAction<boolean>>;
  userCanResetPassword: boolean;
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  flashBarItems,
  viewPassword,
  setViewPassword,
  userCanResetPassword
}) => {
  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .matches(/^.{8,}$/, 'Password needs to be at least 8 characters')
      .required(),
    confirmedPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar id="form-messages" items={flashBarItems} />
          <Form
            id="reset-password-form"
            actions={
              <Button
                id="reset-password-form-submit"
                variant="primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting || !userCanResetPassword}
              >
                Submit
              </Button>
            }
          >
            <Container header={<Header variant="h2">Change Password</Header>}>
              <ColumnLayout columns={2}>
                <SpaceBetween direction="vertical" size="xs">
                  <FormField
                    label="Enter New Password"
                    errorText={errors.newPassword}
                  >
                    <Input
                      id="new-password-field"
                      type={viewPassword ? 'text' : 'password'}
                      value={values.newPassword}
                      onChange={({ detail }) =>
                        setFieldValue('newPassword', detail.value)
                      }
                      invalid={!!errors.newPassword}
                    />
                  </FormField>
                  <FormField
                    label="Confirm New Password"
                    errorText={errors.confirmedPassword}
                  >
                    <Input
                      id="confirm-password-field"
                      type={viewPassword ? 'text' : 'password'}
                      value={values.confirmedPassword}
                      onChange={({ detail }) =>
                        setFieldValue('confirmedPassword', detail.value)
                      }
                      invalid={!!errors.confirmedPassword}
                    />
                  </FormField>
                  <Button
                    onClick={() => setViewPassword((prevState) => !prevState)}
                  >
                    {viewPassword ? 'Hide' : 'View'}
                  </Button>
                </SpaceBetween>

                <HelpPanel>
                  <div>
                    <h3>Password Requirements</h3>
                    <ul>
                      <li>Must be at least 8 characters long.</li>
                      <li>Must not have been changed in the last 24 hours.</li>
                    </ul>
                  </div>
                </HelpPanel>
              </ColumnLayout>
            </Container>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );
};
export default View;
