import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { ISupportTopic } from '../../../../../interfaces/portal/supportTopic';

import View from './view';

const SupportTopicTable: FC = () => {
  const [supportTopics, setSupportTopics] = useState<ISupportTopic[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedSupportTopics, setSelectedSupportTopics] = useState<
    ISupportTopic[]
  >([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    createSupportTopicFormModalIsVisible,
    setCreateSupportTopicFormModalIsVisible
  ] = useState(false);
  const [
    editSupportTopicFormModalIsVisible,
    setEditSupportTopicFormModalIsVisible
  ] = useState(false);

  useEffect(() => {
    getSupportTopics().then((data) => {
      setSupportTopics(data);
      setTableIsLoading(false);
    });
  }, []);

  const getSupportTopics = () => {
    const api = new MainServiceApi();
    return api
      .getAllSupportTopics()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Support Topics!</>
          }
        ]);

        return [];
      });
  };
  const supportTopicsIsEmpty = () => {
    return supportTopics.length === 0;
  };

  const handleTableRowSelection = (supportTopics: ISupportTopic[]) => {
    setDisableActionButtons(false);
    setSelectedSupportTopics(supportTopics);
  };
  const refreshSupportTopicTable = () => {
    setTableIsLoading(true);
    getSupportTopics().then((data) => {
      setSelectedSupportTopics([]);
      setSupportTopics(data);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      supportTopics={supportTopics}
      selectedSupportTopics={selectedSupportTopics}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      supportTopicsIsEmpty={supportTopicsIsEmpty}
      tableIsLoading={tableIsLoading}
      createSupportTopicFormModalIsVisible={
        createSupportTopicFormModalIsVisible
      }
      setCreateSupportTopicFormModalIsVisible={
        setCreateSupportTopicFormModalIsVisible
      }
      editSupportTopicFormModalIsVisible={editSupportTopicFormModalIsVisible}
      setEditSupportTopicFormModalIsVisible={
        setEditSupportTopicFormModalIsVisible
      }
      refreshSupportTopicTable={refreshSupportTopicTable}
    />
  );
};
export default SupportTopicTable;
