import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IDirectory } from '../../../../../interfaces/workSpaces/directory';

import EditWorkSpaceDirectoryForm from '../editWorkSpaceDirectoryForm';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  directories: IDirectory[];
  selectedDirectories: IDirectory[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (row: IDirectory[]) => void;
  tableIsLoading: boolean;
  editDirectoryFormModalIsVisible: boolean;
  setEditDirectoryFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshDirectoryTable: () => void;
}

const View: FC<ViewProps> = ({
  directories,
  selectedDirectories,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  tableIsLoading,
  editDirectoryFormModalIsVisible,
  setEditDirectoryFormModalIsVisible,
  refreshDirectoryTable
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No WS Directories</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No WS Directories to display.
        </Box>
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching WS Directories</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(directories, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['directoryId', 'directoryName', 'alias']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredDirectories = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshDirectoryTable()}
          />

          <Button
            iconName="settings"
            disabled={disableActionButtons}
            onClick={() => {
              setEditDirectoryFormModalIsVisible(true);
            }}
          >
            Edit
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredDirectories.length
            ? `(${filteredDirectories.length}/${directories.length})`
            : `(${directories.length})`
        }
        actions={renderTableActions()}
      >
        WS Directories
      </Header>
    );
  };

  const renderEditDirectoryFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setEditDirectoryFormModalIsVisible(false);
        }}
        visible={editDirectoryFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Edit WS Directory"
      >
        <EditWorkSpaceDirectoryForm
          refreshDirectoryTable={refreshDirectoryTable}
          directories={selectedDirectories}
        />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'directoryId',
      header: 'ID',
      cell: (item: IDirectory) => `${item.directoryId} (${item.directoryName})` || '-'
    },
    {
      id: 'uiName',
      header: 'Option Name',
      cell: (item: IDirectory) => item.uiName || '-'
    },
    {
      id: 'uiDescription',
      header: 'Option Description',
      cell: (item: IDirectory) => item.uiDescription || '-'
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="directoryId"
          selectedItems={selectedDirectories}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading WS Directories"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter WS Directories"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderEditDirectoryFormModal()}
    </>
  );
};
export default View;
