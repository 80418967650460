import { FC, useContext, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';
import { GlobalContext } from '../../../../context/globalContext';

import { FlashbarProps } from '@awsui/components-react';
import { IResetPassword } from '../../../../interfaces/domain/user';

import View from './view';

const UserPasswordReset: FC = () => {
  const {
    currentUser: { userName }
  } = useContext(GlobalContext);

  const formValues: IResetPassword = {
    newPassword: '',
    confirmedPassword: ''
  };

  const [viewPassword, setViewPassword] = useState(false);
  const [userCanResetPassword, setUserCanResetPassword] = useState(false);

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    const api = new MainServiceApi();

    api
      .canCurrentUserRequestPasswordReset()
      .then((data) => {
        setUserCanResetPassword(data.canUserResetPassword);
        if (!data.canUserResetPassword) {
          setFlashBarItems([
            {
              type: 'warning',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>You have already reset your password recently!</>
            }
          ]);
        }
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to detemine if user can reset their password!</>
          }
        ]);
      });
  }, []);

  const handleSubmittedForm = async (data: IResetPassword) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .requestPasswordReset({
        userName: userName,
        userPassword: data.newPassword
      })
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Your password has been changed!</>
          }
        ]);
        setUserCanResetPassword(false);
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: err.message
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      flashBarItems={flashBarItems}
      viewPassword={viewPassword}
      setViewPassword={setViewPassword}
      userCanResetPassword={userCanResetPassword}
    />
  );
};
export default UserPasswordReset;
