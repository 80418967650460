import { FC, useState, useEffect, useContext } from 'react';

import View from './view';
import { FlashbarProps } from '@awsui/components-react';

import { IUserWorkSpace, UserWorkSpaceAction } from '../../../../interfaces/workSpaces/userworkSpaces';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';
import { GlobalContext } from '../../../../context/globalContext';
import {
  handleWorkSpaceReboot,
  handleWorkSpaceRebuild,
  handleWorkSpaceStart,
  updateWorkSpaceTableActions
} from '../../../common/workSpaces/workSpacesTable';

const UsersWorkSpaces: FC = () => {
  const {
    currentUser: { userName, enabled }
  } = useContext(GlobalContext);

  const [userWorkSpaces, setUserWorkSpaces] = useState<IUserWorkSpace[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedWorkSpaces, setSelectedWorkSpaces] = useState<
    IUserWorkSpace[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);

  const [allowedWSActions, setAllowedWSActions] = useState<UserWorkSpaceAction[]>(['start', 'reboot', 'rebuild']);

  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    decommissionRequestModalIsVisible,
    setDecommissionRequestModalIsVisible
  ] = useState(false);
  const [provisionRequestModalIsVisible, setProvisionRequestModalIsVisible] =
    useState(false);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [
    rebuildConfirmationModalIsVisible,
    setRebuildConfirmationModalIsVisible
  ] = useState(false);

  const getWorkSpaces = () => {
    const api = new MainServiceApi();
    return api
      .getWorkSpacesByUserName(userName)
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get WorkSpaces!</>
          }
        ]);

        return [];
      });
  };
  useEffect(() => {
    setTableIsLoading(true);
    getWorkSpaces().then((resp) => {
      setUserWorkSpaces(resp);
      setTableIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  const refreshWorkSpacesTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getWorkSpaces().then((data) => {
      setUserWorkSpaces(data);
      setTableIsLoading(false);
    });
  };

  useEffect(() => {
    updateWorkSpaceTableActions(
      selectedWorkSpaces,
      setAllowedWSActions
    );
  }, [selectedWorkSpaces]);

  const userWorkSpacesIsEmpty = () => {
    return userWorkSpaces.length === 0;
  };
  const handleTableRowSelection = (workSpace: IUserWorkSpace[]) => {
    if (workSpace.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedWorkSpaces(workSpace);
  };

  const handleRebootButtonPress = () => {
    handleWorkSpaceReboot(selectedWorkSpaces, setFlashBarItems);
  };

  const handleRebuildButtonPress = () => {
    handleWorkSpaceRebuild(
      selectedWorkSpaces,
      setFlashBarItems,
      setRebuildConfirmationModalIsVisible
    );
  };

  const handleStartButtonPress = () => {
    handleWorkSpaceStart(selectedWorkSpaces, setFlashBarItems);
  };

  return (
    <>
      <View
        selectedWorkSpaces={selectedWorkSpaces}
        disableActionButtons={disableActionButtons}
        handleTableRowSelection={handleTableRowSelection}
        userWorkSpaces={userWorkSpaces}
        userWorkSpacesIsEmpty={userWorkSpacesIsEmpty}
        tableIsLoading={tableIsLoading}
        handleRebootButtonPress={handleRebootButtonPress}
        handleRebuildButtonPress={handleRebuildButtonPress}
        handleStartButtonPress={handleStartButtonPress}
        decommissionRequestModalIsVisible={decommissionRequestModalIsVisible}
        setDecommissionRequestModalIsVisible={
          setDecommissionRequestModalIsVisible
        }
        flashBarItems={flashBarItems}
        setFlashBarItems={setFlashBarItems}
        provisionRequestModalIsVisible={provisionRequestModalIsVisible}
        setProvisionRequestModalIsVisible={setProvisionRequestModalIsVisible}
        rebuildConfirmationModalIsVisible={rebuildConfirmationModalIsVisible}
        setRebuildConfirmationModalIsVisible={
          setRebuildConfirmationModalIsVisible
        }
        refreshWorkSpacesTable={refreshWorkSpacesTable}
        allowedWSActions={allowedWSActions}
        userEnabled={enabled}
      />
    </>
  );
};
export default UsersWorkSpaces;
