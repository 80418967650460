import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination,
  ButtonDropdown
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoToken } from '../../../../../../interfaces/integrations/duo';

import AssociateDuoTokenForm from '../associateDuoTokenForm';
import SMSVerifyModal from '../../smsVerifyForm/modal';
import ChildModal from '../../../../../common/ChildModal';

interface ViewProps {
  duoTokens: IDuoToken[];
  phoneNumber: string;
  selectedDuoTokens: IDuoToken[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (duoToken: IDuoToken[]) => void;
  duoTokensIsEmpty: () => boolean;
  tableIsLoading: boolean;
  setAssociateDuoTokenModalIsVisible: Dispatch<SetStateAction<boolean>>;
  associateDuoTokenModalIsVisible: boolean;
  setDisassociateDuoTokenModalIsVisible: Dispatch<SetStateAction<boolean>>;
  disassociateDuoTokenModalIsVisible: boolean;
  refreshDuoTokenTable: () => void;
  handleDisassociation: (smsCode: string) => Promise<void>;
  handleResetSMS: () => Promise<void>;
}

const View: FC<ViewProps> = ({
  duoTokens,
  phoneNumber,
  selectedDuoTokens,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  duoTokensIsEmpty,
  tableIsLoading,
  associateDuoTokenModalIsVisible,
  setAssociateDuoTokenModalIsVisible,
  disassociateDuoTokenModalIsVisible,
  setDisassociateDuoTokenModalIsVisible,
  refreshDuoTokenTable,
  handleDisassociation,
  handleResetSMS
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No DUO Tokens</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No DUO Tokens to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            disabled={!phoneNumber}
            onClick={() => {
              setAssociateDuoTokenModalIsVisible(true);
              handleTableRowSelection([]);
            }}
          >
            Associate DUO Tokens
          </Button>
        }
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching DUO Tokens</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(duoTokens, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['userName', 'firstName', 'lastName', 'corpEmail']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredDuoTokens = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshDuoTokenTable()}
          />
          <ButtonDropdown
            disabled={disableActionButtons || !phoneNumber}
            items={[
              {
                text: 'Disassociate',
                id: 'disassociate-token',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'disassociate-token') {
                handleResetSMS();
              }
            }}
          >
            Actions
          </ButtonDropdown>
          <Button
            iconName="add-plus"
            disabled={duoTokensIsEmpty() || !phoneNumber}
            onClick={() => {
              setAssociateDuoTokenModalIsVisible(true);
              handleTableRowSelection([]);
            }}
          >
            Associate Duo Token
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredDuoTokens.length
            ? `(${filteredDuoTokens.length}/${duoTokens.length})`
            : `(${duoTokens.length})`
        }
        actions={renderTableActions()}
      >
        DUO Tokens
      </Header>
    );
  };

  const renderAssociateDuoTokenModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setAssociateDuoTokenModalIsVisible(false);
        }}
        visible={associateDuoTokenModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Associate DUO Token"
      >
        <AssociateDuoTokenForm
          refreshDuoTokenTable={refreshDuoTokenTable}
          phoneNumber={phoneNumber}
        />
      </ChildModal>
    );
  };

  const renderDisassociateDuoTokenModal = () => {
    return (
      <SMSVerifyModal
        submitSMSCode={handleDisassociation}
        phoneNumber={phoneNumber}
        handleDismiss={() => setDisassociateDuoTokenModalIsVisible(false)}
        header="Disassociate DUO Token"
        isVisible={disassociateDuoTokenModalIsVisible}
        flashBarItems={flashBarItems}
        setFlashBarItems={setFlashBarItems}
      />
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'serial',
      header: 'Serial',
      cell: (item: IDuoToken) => item.serial || '-'
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item: IDuoToken) => item.type || '-'
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="token_id"
          selectedItems={selectedDuoTokens}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Duo Tokens"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Duo Tokens"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderAssociateDuoTokenModal()}
      {renderDisassociateDuoTokenModal()}
    </>
  );
};
export default View;
