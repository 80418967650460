import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUser } from '../../../../interfaces/domain/user';
import { IUserWorkSpace, UserWorkSpaceAction } from '../../../../interfaces/workSpaces/userworkSpaces';

import View from './view';
import {
  handleWorkSpaceReboot,
  handleWorkSpaceRebuild,
  handleWorkSpaceStart,
  updateWorkSpaceTableActions
} from '../../../common/workSpaces/workSpacesTable';

interface IndexProps {
  users: IUser[];
}

const UserWorkSpaceDetails: FC<IndexProps> = ({ users }) => {
  const [user, setUser] = useState<IUser>({
    userName: '',
    enabled: 0,
    displayName: '',
    firstName: '',
    middleInitial: '',
    lastName: '',
    workMailEmail: '',
    corpEmail: '',
    distinguishedName: '',
    workDocsId: '',
    workMailId: '',
    homeGroup: '',
    deprecated: 0,
    createdAt: 0,
    updatedAt: 0,
    passwordAge: 0,
    passwordExpiresAt: 0
  });

  const [userWorkSpaces, setUserWorkSpaces] = useState<IUserWorkSpace[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedWorkSpaces, setSelectedWorkSpaces] = useState<
    IUserWorkSpace[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);

  const [allowedWSActions, setAllowedWSActions] = useState<UserWorkSpaceAction[]>(['start', 'reboot', 'rebuild']);

  const [
    rebuildConfirmationModalIsVisible,
    setRebuildConfirmationModalIsVisible
  ] = useState(false);
  const [
    decommissionConfirmationModalIsVisible,
    setDecommissionConfirmationModalIsVisible
  ] = useState(false);

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    updateWorkSpaceTableActions(
      selectedWorkSpaces,
      setAllowedWSActions,
    );
  }, [selectedWorkSpaces]);

  useEffect(() => {
    const api = new MainServiceApi();

    for (const user of users) {
      setUser(user);

      api
        .getWorkSpacesByUserName(user.userName)
        .then((data) => {
          setUserWorkSpaces(data);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>Unable to get User WorkSpace data!</>
            }
          ]);
        });
    }
  }, [users]);

  const handleTableRowSelection = (workSpace: IUserWorkSpace[]) => {
    setDisableActionButtons(false);
    setSelectedWorkSpaces(workSpace);
  };

  const handleRebootButtonPress = () => {
    handleWorkSpaceReboot(selectedWorkSpaces, setFlashBarItems);
  };

  const handleRebuildButtonPress = () => {
    setRebuildConfirmationModalIsVisible(false);
    handleWorkSpaceRebuild(
      selectedWorkSpaces,
      setFlashBarItems,
      setRebuildConfirmationModalIsVisible
    );
  };

  const handleStartButtonPress = () => {
    handleWorkSpaceStart(selectedWorkSpaces, setFlashBarItems);
  };

  const handleDecommissionButtonPress = () => {
    setDecommissionConfirmationModalIsVisible(false);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();

    for (const selectedWorkSpace of selectedWorkSpaces) {
      if (selectedWorkSpace.workSpaceId) {
        api
          .requestDecommissionWorkSpace(selectedWorkSpace.workSpaceId)
          .then((resp) => {
            // setRebuildConfirmationModalIsVisible(false);
            setFlashBarItems([
              {
                type: 'success',
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setFlashBarItems([]),
                content: (
                  <>Your WorkSpace Decommission request has been received!</>
                )
              }
            ]);
          })
          .catch((err) => {
            setFlashBarItems([
              {
                type: 'error',
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setFlashBarItems([]),
                content: (
                  <>Unable to submit your WorkSpace Decommission request!</>
                )
              }
            ]);
          });
      }
    }
  };

  return (
    <View
      user={user}
      selectedWorkSpaces={selectedWorkSpaces}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      userWorkSpaces={userWorkSpaces}
      handleRebootButtonPress={handleRebootButtonPress}
      handleRebuildButtonPress={handleRebuildButtonPress}
      handleStartButtonPress={handleStartButtonPress}
      handleDecommissionButtonPress={handleDecommissionButtonPress}
      flashBarItems={flashBarItems}
      allowedWSActions={allowedWSActions}
      rebuildConfirmationModalIsVisible={rebuildConfirmationModalIsVisible}
      setRebuildConfirmationModalIsVisible={
        setRebuildConfirmationModalIsVisible
      }
      decommissionConfirmationModalIsVisible={
        decommissionConfirmationModalIsVisible
      }
      setDecommissionConfirmationModalIsVisible={
        setDecommissionConfirmationModalIsVisible
      }
    />
  );
};
export default UserWorkSpaceDetails;
