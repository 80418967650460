import { FC } from 'react';

import { Tabs } from '@awsui/components-react';

import DuoPhoneTable from '../phone/duoPhoneTable';
import DuoTokenTable from '../token/duoTokenTable';

import './styles.css';

interface ViewProps {}

const View: FC<ViewProps> = () => {
  return (
    <Tabs
      variant="container"
      tabs={[
        {
          label: 'DUO Phones',
          id: 'phones',
          content: <DuoPhoneTable />
        },
        {
          label: 'DUO Tokens',
          id: 'tokens',
          content: <DuoTokenTable />
        }
      ]}
    />
  );
};
export default View;
