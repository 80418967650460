import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Input,
  Select,
  Flashbar,
  Textarea,
  Container,
  AppLayout,
  Table,
  Header as AWSUIHeader,
  TableProps
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';
import Moment from 'react-moment';

import { FlashbarProps, SelectProps } from '@awsui/components-react';

import {
  ICreateUserAccountRequest,
  IUserAccountRequest
} from '../../../../interfaces/domain/userAccountRequest';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';

interface ViewProps {
  formValues: ICreateUserAccountRequest;
  handleSubmittedForm: (data: ICreateUserAccountRequest) => Promise<void>;
  homeGroupSelectValues: SelectProps.Options;
  setSelectedHomeGroup: React.Dispatch<React.SetStateAction<string>>;
  bundleSelectValues: SelectProps.Options;
  directorySelectValues: SelectProps.Options;
  flashBarItems: FlashbarProps.MessageDefinition[];
  userAccountRequests: IUserAccountRequest[];
  userHasPendingRequest: boolean;
  allowAutoProvision: boolean;
  logOut: () => void;
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  homeGroupSelectValues,
  setSelectedHomeGroup,
  bundleSelectValues,
  directorySelectValues,
  flashBarItems,
  userAccountRequests,
  userHasPendingRequest,
  allowAutoProvision,
  logOut
}) => {
  const bundleSchema = yup.object({
    uiName: yup.string(),
    uiDescription: yup.string()
  });
  const directorySchema = yup.object({
    uiName: yup.string(),
    uiDescription: yup.string()
  });
  const validationSchema = yup.object({
    firstName: yup.string().matches(/^[a-zA-Z0-9]+$/).max(30).required(),
    lastName: yup.string().matches(/^[a-zA-Z0-9]+$/).max(30).required(),
    ticketLink: yup.string().url().matches(/.*\.amazon\.com|amazon-cwe\.com/, {message: 'URL must be a valid SIM ticket link. Example: https://t.corp.amazon.com/123456789'}),
    homeGroup: yup.string().required(),
    bundle: allowAutoProvision ? bundleSchema.required() : bundleSchema,
    directory: allowAutoProvision ? directorySchema.required() : directorySchema,
    justification: yup.string().required()
  });

  const SubmittedRequests = () => {
    const tableColumnDefinitions: TableProps.ColumnDefinition<IUserAccountRequest>[] =
      [
        {
          id: 'uuid',
          header: 'UUID',
          cell: (item) => item.uuid || '-'
        },
        {
          id: 'justification',
          header: 'Justification',
          cell: (item) => item.justification || '-'
        },
        {
          id: 'requestStatus',
          header: 'Status',
          cell: (item) => item.requestStatus || '-'
        },
        {
          id: 'rejectionReason',
          header: 'Reason',
          cell: (item) => item.rejectionReason || '-'
        },
        {
          id: 'createdAt',
          header: 'Created At',
          cell: (item: IUserAccountRequest) => (
            <Moment unix local format="LLLL">
              {item.createdAt}
            </Moment>
          )
        }
      ];
    return (
      <Table
        trackBy="uuid"
        header={
          <AWSUIHeader
            actions={
              <Button
                onClick={() => {
                  logOut();
                }}
              >
                Sign Out
              </Button>
            }
          >
            Submitted Requests
          </AWSUIHeader>
        }
        columnDefinitions={tableColumnDefinitions}
        items={userAccountRequests}
        resizableColumns
        wrapLines
      />
    );
  };

  const CreateNewRequestForm = (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <Container
          header={
            <AWSUIHeader
              actions={
                <Button
                  onClick={() => {
                    logOut();
                  }}
                >
                  Sign Out
                </Button>
              }
            >
              Request New Account
            </AWSUIHeader>
          }
        >
          <SpaceBetween direction="vertical" size="xs">
            <Flashbar items={flashBarItems} />
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="xs">
                <FormField label="First Name" errorText={errors.firstName}>
                  <Input
                    name="firstName"
                    value={values.firstName}
                    onChange={({ detail }) =>
                      setFieldValue('firstName', detail.value)
                    }
                    onBlur={() =>
                      setFieldValue('firstName', values.firstName.trim())
                    }
                    invalid={!!errors.firstName}
                  />
                </FormField>
                <FormField label="Last Name" errorText={errors.lastName}>
                  <Input
                    name="lastName"
                    value={values.lastName}
                    onChange={({ detail }) =>
                      setFieldValue('lastName', detail.value)
                    }
                    onBlur={() =>
                      setFieldValue('lastName', values.lastName.trim())
                    }
                    invalid={!!errors.lastName}
                  />
                </FormField>
                <FormField
                  label="Ticket Link"
                  description="Link to SIM account request ticket"
                  errorText={errors.ticketLink}
                >
                  <Input
                    name="ticketLink"
                    value={values.ticketLink}
                    onChange={({ detail }) =>
                      setFieldValue('ticketLink', detail.value)
                    }
                    onBlur={() =>
                      setFieldValue('ticketLink', values.ticketLink.trim())
                    }
                    invalid={!!errors.ticketLink}
                  />
                </FormField>
                <FormField label="Home Group" errorText={errors.homeGroup}>
                  <Select
                    selectedOption={{
                      value: values.homeGroup,
                      label: values.homeGroup
                    }}
                    onChange={({ detail }) => {
                      setFieldValue('homeGroup', detail.selectedOption.value);
                      setSelectedHomeGroup(detail.selectedOption.value || '');
                      setFieldValue('bundle', { value: '', label: '' });
                      setFieldValue('directory', { value: '', label: '' });
                    }}
                    options={homeGroupSelectValues}
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                  />
                </FormField>
                {
                  allowAutoProvision
                    ? (<>
                        <FormField
                          label="WorkSpace Bundle"
                          description="Choose the software and compute power you want for your WorkSpace."
                          errorText={errors.bundle ? 'Bundle is required' : ''}
                        >
                          <Select
                            selectedOption={{
                              value: values.bundle?.uiName,
                              label: values.bundle?.uiName,
                              description: values.bundle?.uiDescription
                            }}
                            onChange={({ detail }) => {
                              setFieldValue('bundle', {
                                uiName: detail.selectedOption.value,
                                uiDescription: detail.selectedOption.description
                              });
                            }}
                            options={bundleSelectValues}
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                          />
                        </FormField>
                        <FormField
                          label="WorkSpace Directory"
                          description="Choose a directory to launch your WorkSpace in."
                          errorText={errors.directory ? 'Directory is required' : ''}
                        >
                          <Select
                            selectedOption={{
                              value: values.directory?.uiName,
                              label: values.directory?.uiName,
                              description: values.directory?.uiDescription
                            }}
                            onChange={({ detail }) => {
                              setFieldValue('directory', {
                                uiName: detail.selectedOption.value,
                                uiDescription: detail.selectedOption.description
                              });
                            }}
                            options={directorySelectValues}
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                          />
                        </FormField>
                      </>)
                    : <></>
                }
                <FormField
                  label="Justification"
                  errorText={errors.justification}
                >
                  <Textarea
                    value={values.justification}
                    onChange={({ detail }) =>
                      setFieldValue('justification', detail.value)
                    }
                    invalid={!!errors.justification}
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </SpaceBetween>
        </Container>
      )}
    </Formik>
  );

  return (
    <>
      <Header />
      <AppLayout
        footerSelector=".footer"
        headerSelector=".header"
        content={
          <>
            <SpaceBetween direction="vertical" size="xs">
              {!userHasPendingRequest ? CreateNewRequestForm : <></>}
              {userAccountRequests.length !== 0 ? SubmittedRequests() : <></>}
            </SpaceBetween>
          </>
        }
        toolsHide
        navigationHide
      />
      <Footer />
    </>
  );
};
export default View;
