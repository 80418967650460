import { FC, Dispatch, SetStateAction } from 'react';
import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination,
  StatusIndicator,
  ButtonDropdown
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IUser } from '../../../../../interfaces/domain/user';

import UserDetails from '../userDetails';
import CreateUserForm from '../createUserForm';
import UserWorkSpaceDetails from '../../../workSpaces/userWorkSpaceDetails';
import EditUserForm from '../editUserForm';
import UserProvisionRequestForm from '../../../workSpaces/createUserWorkspace';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  users: IUser[];
  selectedUsers: IUser[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (user: IUser[]) => void;
  usersIsEmpty: () => boolean;
  handlePasswordReset: () => void;
  handleToggleAccountLock: () => void;
  tableIsLoading: boolean;
  userDetailModalIsVisible: boolean;
  setUserDetailModalIsVisible: Dispatch<SetStateAction<boolean>>;
  createUserFormModalIsVisible: boolean;
  setCreateUserFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  userWorkSpacesDetailModalIsVisible: boolean;
  setUserWorkSpacesDetailModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshUserTable: () => void;
  editUserFormModalIsVisible: boolean;
  setEditUserFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  setProvisionRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  provisionRequestModalIsVisible: boolean;
}

const View: FC<ViewProps> = ({
  users,
  selectedUsers,
  flashBarItems,
  setProvisionRequestModalIsVisible,
  provisionRequestModalIsVisible,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  usersIsEmpty,
  handlePasswordReset,
  handleToggleAccountLock,
  tableIsLoading,
  userDetailModalIsVisible,
  setUserDetailModalIsVisible,
  createUserFormModalIsVisible,
  setCreateUserFormModalIsVisible,
  userWorkSpacesDetailModalIsVisible,
  setUserWorkSpacesDetailModalIsVisible,
  refreshUserTable,
  editUserFormModalIsVisible,
  setEditUserFormModalIsVisible
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No Users</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No Users to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            onClick={() => {
              setCreateUserFormModalIsVisible(true);
            }}
          >
            Create User
          </Button>
        }
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching Users</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(users, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['userName', 'firstName', 'lastName', 'corpEmail']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredUsers = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshUserTable()}
          />
          <ButtonDropdown
            variant="primary"
            disabled={disableActionButtons}
            items={[
              {
                text: 'User Details',
                id: 'user-details',
                disabled: disableActionButtons
              },
              {
                text: 'User WorkSpaces',
                id: 'user-workspaces',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'user-details') {
                setUserDetailModalIsVisible(true);
              } else if (detail.id === 'user-workspaces') {
                setUserWorkSpacesDetailModalIsVisible(true);
              }
            }}
          >
            View
          </ButtonDropdown>
          <ButtonDropdown
            disabled={disableActionButtons}
            items={[
              {
                text: 'Edit',
                id: 'edit',
                disabled: disableActionButtons
              },
              {
                text: 'Reset Password',
                id: 'reset-password',
                disabled: disableActionButtons
              },
              {
                text: 'Toggle Account Lock',
                id: 'toggle-account-lock',
                disabled: disableActionButtons
              },
              {
                text: 'Provision User Workspace',
                id: 'provision-user-workspace',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'reset-password') {
                handlePasswordReset();
              } else if (detail.id === 'toggle-account-lock') {
                handleToggleAccountLock();
              } else if (detail.id === 'edit') {
                setEditUserFormModalIsVisible(true);
              } else if (detail.id === 'provision-user-workspace') {
                setProvisionRequestModalIsVisible(true);
              }
            }}
          >
            Actions
          </ButtonDropdown>
          <Button
            iconName="add-plus"
            disabled={usersIsEmpty()}
            onClick={() => {
              handleTableRowSelection([]);
              setCreateUserFormModalIsVisible(true);
            }}
          >
            Create User
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredUsers.length
            ? `(${filteredUsers.length}/${users.length})`
            : `(${users.length})`
        }
        actions={renderTableActions()}
      >
        Users
      </Header>
    );
  };
  const renderUserAccountStatus = (enabled: number) => {
    if (enabled) {
      return <StatusIndicator type="success">Not locked</StatusIndicator>;
    } else {
      return <StatusIndicator type="warning">Locked</StatusIndicator>;
    }
  };

  const renderProvisionRequestModal = () => {
    return(
    <ChildModal
      onDismiss={() => {
        setProvisionRequestModalIsVisible(false);
      }}
      visible={provisionRequestModalIsVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header="Provision User WorkSpace"
    >
      <UserProvisionRequestForm
        setFlashBarItems={setFlashBarItems}
        setProvisionRequestModalIsVisible={setProvisionRequestModalIsVisible}
        users={selectedUsers}
      />
    </ChildModal>
    );
  }

  const renderUserDetailsModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setUserDetailModalIsVisible(false);
        }}
        visible={userDetailModalIsVisible}
        closeAriaLabel="Close modal"
        size="large"
        header="User Details"
      >
        <UserDetails users={selectedUsers} />
      </ChildModal>
    );
  };
  const renderUserWorkSpacesDetailsModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setUserWorkSpacesDetailModalIsVisible(false);
        }}
        visible={userWorkSpacesDetailModalIsVisible}
        closeAriaLabel="Close modal"
        size="max"
        header="WorkSpace Details"
      >
        <UserWorkSpaceDetails users={selectedUsers} />
      </ChildModal>
    );
  };
  const renderCreateUserFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setCreateUserFormModalIsVisible(false);
        }}
        visible={createUserFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="New User"
      >
        <CreateUserForm refreshUserTable={refreshUserTable} />
      </ChildModal>
    );
  };
  const renderEditUserFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setEditUserFormModalIsVisible(false);
        }}
        visible={editUserFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Edit User"
      >
        <EditUserForm
          refreshUserTable={refreshUserTable}
          users={selectedUsers}
        />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'userName',
      header: 'UserName',
      cell: (item: IUser) => item.userName || '-'
    },
    {
      id: 'firstName',
      header: 'First Name',
      cell: (item: IUser) => item.firstName || '-'
    },
    {
      id: 'lastName',
      header: 'Last Name',
      cell: (item: IUser) => item.lastName || '-'
    },
    {
      id: 'corpEmail',
      header: 'Email',
      cell: (item: IUser) => item.corpEmail || '-'
    },
    {
      id: '',
      header: 'Status',
      cell: (item: IUser) => renderUserAccountStatus(item.enabled)
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="userName"
          selectedItems={selectedUsers}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Users"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Users"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderUserDetailsModal()}
      {renderCreateUserFormModal()}
      {renderUserWorkSpacesDetailsModal()}
      {renderEditUserFormModal()}
      {renderProvisionRequestModal()}
    </>
  );
};
export default View;
