import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IUpdateDirectory,
  IDirectory
} from '../../../../../interfaces/workSpaces/directory';

import View from './view';

interface IndexProps {
  refreshDirectoryTable: () => void;
  directories: IDirectory[];
}

const EditWorkSpaceDirectoryForm: FC<IndexProps> = ({
  refreshDirectoryTable,
  directories
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [directoryId, setDirectoryId] = useState('');
  const [formValues, setFormValues] = useState<IUpdateDirectory>({
    deprecated: 0,
    uiName: '',
    uiDescription: ''
  });

  useEffect(() => {
    for (const directory of directories) {
      setDirectoryId(directory.directoryId);
      setFormValues({
        deprecated: directory.deprecated,
        uiName: directory.uiName || '',
        uiDescription: directory.uiDescription || ''
      });
    }
  }, [directories]);

  const handleSubmittedForm = async (data: IUpdateDirectory) => {
    setFormValues(data);

    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateDirectory(directoryId, data)
      .then(() => {
        refreshDirectoryTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>WS Directory has been updated!</>
          }
        ]);
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update WS Directory! - {err}</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default EditWorkSpaceDirectoryForm;
