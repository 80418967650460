import { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import View from './view';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import {
  IUpdateUserAccountRequest,
  IUserAccountRequest
} from '../../../../../interfaces/domain/userAccountRequest';

interface IndexProps {
  userAccountRequests: IUserAccountRequest[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  setEditUserAccountRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshUserAccountRequestTable: () => void;
}
const EditUserAccountRequestForm: FC<IndexProps> = ({
  userAccountRequests,
  setFlashBarItems,
  setEditUserAccountRequestModalIsVisible,
  refreshUserAccountRequestTable
}) => {
  const [formValues, setFormValues] = useState<IUpdateUserAccountRequest>({
    homeGroup: '',
    bundleUiName: '',
    directoryUiName: ''
  });
  const [modelFlashBarItems, setModelFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [homeGroupSelectValues, setHomeGroupSelectValues] =
    useState<SelectProps.Options>([]);
  const [bundleSelectValues, setBundleSelectValues] =
    useState<SelectProps.Options>([]);
  const [directorySelectValues, setDirectorySelectValues] =
    useState<SelectProps.Options>([]);
  const [allowAutoProvision, setAllowAutoProvision] = useState(false);

  useEffect(() => {
    for (const userAccountRequest of userAccountRequests) {
      setFormValues({
        homeGroup: userAccountRequest.homeGroup,
        bundleUiName: userAccountRequest.bundleUiName || '',
        directoryUiName: userAccountRequest.directoryUiName || ''
      });
    }
  }, [userAccountRequests]);

  useInterval(() => {
    const api = new MainServiceApi();

    Promise.all([
      api.getAllHomeGroups(),
      api.getAllBundles(),
      api.getAllDirectories(),
      api.getPortalSettingsByNames(['enableAutoProvision'])
    ])
      .then(([homeGroups, bundles, directories, settings]) => {
        setHomeGroupSelectValues(
          homeGroups
            .map((el) => ({
              label: el.groupName,
              value: el.groupName
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );

        setBundleSelectValues(
          bundles.map((el) => ({
            label: el.uiName,
            value: el.uiName
          }))
        );

        setDirectorySelectValues(
          directories.map((el) => ({
            label: el.uiName,
            value: el.uiName
          }))
        );

        setAllowAutoProvision(settings.enableAutoProvision);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get WorkSpaces settings!</>
          }
        ]);
      });
  }, []);

  const handleSubmittedForm = async (data: IUpdateUserAccountRequest) => {
    setModelFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    for (const userAccountRequest of userAccountRequests) {
      const { bundleUiName, directoryUiName, ...otherAttrs } = data;

      api
        .updateUserAccountRequest(
          userAccountRequest.uuid,
          {
            ...(allowAutoProvision ? { bundleUiName, directoryUiName } : {}),
            ...otherAttrs
          }
        )
        .then((resp) => {
          setEditUserAccountRequestModalIsVisible(false);
          setModelFlashBarItems([]);
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>User Account Request was updated successfully!</>
            }
          ]);
          refreshUserAccountRequestTable();
        })
        .catch(() => {
          setModelFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setModelFlashBarItems([]),
              content: <> Unable to update User Account Request!</>
            }
          ]);
        });
    }
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      homeGroupSelectValues={homeGroupSelectValues}
      bundleSelectValues={bundleSelectValues}
      directorySelectValues={directorySelectValues}
      modelFlashBarItems={modelFlashBarItems}
      allowAutoProvision={allowAutoProvision}
    />
  );
};

export default EditUserAccountRequestForm;
