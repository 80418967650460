import { FC, useEffect, useState } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { IUpdateUser, IUser } from '../../../../../interfaces/domain/user';

import View from './view';

interface IndexProps {
  refreshUserTable: () => void;
  users: IUser[];
}

const EditUserForm: FC<IndexProps> = ({ refreshUserTable, users }) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [userName, setUserName] = useState('');
  const [formValues, setFormValues] = useState<IUpdateUser>({
    homeGroup: ''
  });
  const [homeGroupSelectValues, setHomeGroupSelectValues] =
    useState<SelectProps.Options>([]);

  useEffect(() => {
    for (const user of users) {
      setUserName(user.userName);
      setFormValues({
        homeGroup: user.homeGroup
      });
    }
  }, [users]);

  useInterval(() => {
    const api = new MainServiceApi();
    api
      .getAllHomeGroups()
      .then((data) => {
        const temp = data
          .map((el) => ({
            label: el.groupName,
            value: el.groupName
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        setHomeGroupSelectValues(temp);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Bundles!</>
          }
        ]);
      });
  }, []);

  const handleSubmittedForm = async (data: IUpdateUser) => {
    setFormValues({
      ...data
    });
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateUser(userName, data)
      .then(() => {
        refreshUserTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Home Group has been updated!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update Home Group!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
      homeGroupSelectValues={homeGroupSelectValues}
    />
  );
};
export default EditUserForm;
