import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Textarea,
  Flashbar
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { FlashbarProps } from '@awsui/components-react';
import { IRejectUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';

interface ViewProps {
  formValues: IRejectUserAccountRequest;
  modelFlashBarItems: FlashbarProps.MessageDefinition[];
  handleSubmittedForm: (data: IRejectUserAccountRequest) => Promise<void>;
}

const View: FC<ViewProps> = ({
  formValues,
  modelFlashBarItems,
  handleSubmittedForm
}) => {
  const validationSchema = yup.object({
    rejectionReason: yup.string().required().max(100)
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSubmittedForm(data).then(() => {
            resetForm();
            setSubmitting(false);
          });
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
          <SpaceBetween direction="vertical" size="xs">
            <Flashbar items={modelFlashBarItems} />
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <FormField
                label="Rejection Reason"
                description="Reason for not approving this User Account Request."
                errorText={errors.rejectionReason}
              >
                <Textarea
                  value={values.rejectionReason}
                  name="rejectionReason"
                  onChange={({ detail }) => {
                    setFieldValue('rejectionReason', detail.value);
                  }}
                  invalid={!!errors.rejectionReason}
                />
              </FormField>
            </Form>
          </SpaceBetween>
        )}
      </Formik>
    </>
  );
};
export default View;
