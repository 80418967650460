import { FC } from 'react';

import { ColumnLayout, Flashbar, SpaceBetween } from '@awsui/components-react';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoPhoneActivation } from '../../../../../../interfaces/integrations/duo';

interface ViewProps {
  duoPhoneActivationCode: IDuoPhoneActivation;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({ duoPhoneActivationCode, flashBarItems }) => {
  return (
    <SpaceBetween direction="vertical" size="xs">
      <Flashbar items={flashBarItems} />
      <ColumnLayout columns={2} variant="default">
        <ol>
          <li>Download and Open DUO Mobile app.</li>
          <li>Tap on the "+ Add" button.</li>
          <li>Tap on the "Use QR code" button.</li>
          <li>Scan this barcode.</li>
        </ol>
        <img
          src={duoPhoneActivationCode.activation_barcode}
          alt="DUO Phone activation barcode"
          width="125"
          height="125"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
        />
      </ColumnLayout>
    </SpaceBetween>
  );
};
export default View;
