import { FC } from 'react';
import {
  ColumnLayout,
  Flashbar,
  SpaceBetween,
  Table
} from '@awsui/components-react';
import Moment from 'react-moment';

import ValueWithLabel from '../../../../common/ValueWithLabel';
import { IUserWorkSpaceDecommissionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';
import { FlashbarProps } from '@awsui/components-react';

interface ViewProps {
  userWorkSpaceDecommissionRequest: IUserWorkSpaceDecommissionRequest;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({
  userWorkSpaceDecommissionRequest,
  flashBarItems
}) => {
  const tableColumnDefinitions = [
    {
      id: 'attribute',
      header: 'Attribute',
      cell: (item: any) => item.name
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item: any) => item.value
    }
  ];

  const tableItems = [
    {
      name: 'UUID',
      value: userWorkSpaceDecommissionRequest.uuid || '-'
    },
    {
      name: 'Corp Email',
      value: userWorkSpaceDecommissionRequest.corpEmail || '-'
    },
    {
      name: 'Rejection Reason',
      value: userWorkSpaceDecommissionRequest.rejectionReason || '-'
    },
    {
      name: 'Justification',
      value: userWorkSpaceDecommissionRequest.justification || '-'
    },
    {
      name: 'Deprecated',
      value: userWorkSpaceDecommissionRequest.deprecated.toString() || '-'
    },
    {
      name: 'Created At',
      value: (
        <Moment unix local format="LLLL">
          {userWorkSpaceDecommissionRequest.createdAt || '-'}
        </Moment>
      )
    },
    {
      name: 'Updated At',
      value: (
        <Moment unix local format="LLLL">
          {userWorkSpaceDecommissionRequest.updatedAt || '-'}
        </Moment>
      )
    },
    {
      name: 'Updated By',
      value: userWorkSpaceDecommissionRequest.updatedBy || '-'
    }
  ];

  return (
    <SpaceBetween size="xs">
      <Flashbar items={flashBarItems} />
      <SpaceBetween size="l">
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label="User Name">
            {userWorkSpaceDecommissionRequest.userName || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="WorkSpace ID">
            {userWorkSpaceDecommissionRequest.workSpaceId || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Request Status">
            {userWorkSpaceDecommissionRequest.requestStatus || '-'}
          </ValueWithLabel>
        </ColumnLayout>

        <Table columnDefinitions={tableColumnDefinitions} items={tableItems} />
      </SpaceBetween>
    </SpaceBetween>
  );
};
export default View;
