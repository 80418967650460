import { FC, useState } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import { ICreateUser } from '../../../../../interfaces/domain/user';

import View from './view';

interface IndexProps {
  refreshUserTable: () => void;
}

const CreateUserForm: FC<IndexProps> = ({ refreshUserTable }) => {
  const formValues: ICreateUser = {
    firstName: '',
    lastName: '',
    corpEmail: '',
    homeGroup: ''
  };

  const [homeGroupSelectValues, setHomeGroupSelectValues] =
    useState<SelectProps.Options>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useInterval(() => {
    const api = new MainServiceApi();
    api
      .getAllHomeGroups()
      .then((data) => {
        let tempHomeGroupSelectValues = data
          .map((homeGroup) => ({
            label: homeGroup.groupName,
            value: homeGroup.groupName
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        setHomeGroupSelectValues(tempHomeGroupSelectValues);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Home Groups!</>
          }
        ]);
      });
  }, []);

  const handleSubmittedForm = async (data: ICreateUser) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .createUser(data)
      .then((resp) => {
        refreshUserTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>User has been created!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to create User!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      homeGroupSelectValues={homeGroupSelectValues}
      flashBarItems={flashBarItems}
    />
  );
};
export default CreateUserForm;
