import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';

import {
  IUpdateApprovedDomain,
  IApprovedDomain
} from '../../../../../interfaces/authentication/approvedDomain';

import View from './view';

interface IndexProps {
  refreshTable: () => void;
  selectedItems: IApprovedDomain[];
}

const EditForm: FC<IndexProps> = ({ refreshTable, selectedItems }) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [uuid, setUuid] = useState('');
  const [formValues, setFormValues] = useState<IUpdateApprovedDomain>({
    domainName: '',
    deprecated: 0
  });

  useEffect(() => {
    for (const item of selectedItems) {
      setUuid(item.uuid);
      setFormValues({
        domainName: item.domainName,
        deprecated: item.deprecated
      });
    }
  }, [selectedItems]);

  const handleSubmittedForm = async (data: IUpdateApprovedDomain) => {
    setFormValues({
      ...data
    });
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateApprovedDomain(uuid, data)
      .then(() => {
        refreshTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Approved Domain has been updated!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update Approved Domain!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default EditForm;
