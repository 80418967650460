import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoType } from '../../../../../../interfaces/integrations/duo';

import View from './view';

const DuoTypeTable: FC = () => {
  const [duoTypes, setDuoTypes] = useState<IDuoType[]>([]);
  //this needs to be an array because polaris.. ugh
  const [selectedDuoTypes, setSelectedDuoTypes] = useState<IDuoType[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [createDuoTypeFormModalIsVisible, setCreateDuoTypeFormModalIsVisible] =
    useState(false);
  const [editDuoTypeFormModalIsVisible, setEditDuoTypeFormModalIsVisible] =
    useState(false);

  useEffect(() => {
    getDuoTypes().then((data) => {
      setDuoTypes(data);
      setTableIsLoading(false);
    });
  }, []);

  const getDuoTypes = () => {
    const api = new MainServiceApi();
    return api
      .getAllDuoTypes()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get DUO Types!</>
          }
        ]);

        return [];
      });
  };
  const duoTypesIsEmpty = () => {
    return duoTypes.length === 0;
  };

  const handleTableRowSelection = (duoType: IDuoType[]) => {
    if (duoType.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedDuoTypes(duoType);
  };
  const refreshDuoTypeTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getDuoTypes().then((data) => {
      setSelectedDuoTypes([]);
      setDuoTypes(data);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      duoTypes={duoTypes}
      selectedDuoTypes={selectedDuoTypes}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      duoTypesIsEmpty={duoTypesIsEmpty}
      tableIsLoading={tableIsLoading}
      createDuoTypeFormModalIsVisible={createDuoTypeFormModalIsVisible}
      setCreateDuoTypeFormModalIsVisible={setCreateDuoTypeFormModalIsVisible}
      editDuoTypeFormModalIsVisible={editDuoTypeFormModalIsVisible}
      setEditDuoTypeFormModalIsVisible={setEditDuoTypeFormModalIsVisible}
      refreshDuoTypeTable={refreshDuoTypeTable}
    />
  );
};
export default DuoTypeTable;
