import { FC } from 'react';

import { Tabs } from '@awsui/components-react';

import UserWorkSpacePendingProvisionRequestTable from '../userWorkSpacePendingProvisionRequestTable';
import UserWorkSpaceAllProvisionRequestTable from '../userWorkSpaceAllProvisionRequestTable';

import './styles.css';

interface ViewProps {}

const View: FC<ViewProps> = () => {
  return (
    <Tabs
      variant="container"
      tabs={[
        {
          label: 'Pending',
          id: 'pending',
          content: <UserWorkSpacePendingProvisionRequestTable />
        },
        {
          label: 'All',
          id: 'all',
          content: <UserWorkSpaceAllProvisionRequestTable />
        }
      ]}
    />
  );
};
export default View;
