import { FC, useState, useCallback } from 'react';

import { useInterval } from '../../../../common/CustomHooks';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';

import View from './view';

const UserAccountPendingRequestTable: FC = () => {
  const [userAccountRequests, setUserAccountRequests] = useState<
    IUserAccountRequest[]
  >([]);
  const [selectedUserAccountRequests, setSelectedUserAccountRequests] =
    useState<IUserAccountRequest[]>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    rejectUserAccountRequestModalIsVisible,
    setRejectUserAccountRequestModalIsVisible
  ] = useState(false);
  const [
    editUserAccountRequestModalIsVisible,
    setEditUserAccountRequestModalIsVisible
  ] = useState(false);
  const [
    userAccountRequestDetailsModalIsVisible,
    setUserAccountRequestDetailsModalIsVisible
  ] = useState(false);

  const getUserAccountRequests = useCallback(() => {
    setTableIsLoading(true);

    const api = new MainServiceApi();
    return api
      .getPendingUserAccountRequests()
      .then((data) => {
        setUserAccountRequests(data);
        setTableIsLoading(false);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to retrieve User Account Requests!</>
          }
        ]);

        return [];
      });
  }, []);

  useInterval(() => {
    getUserAccountRequests();
  }, [getUserAccountRequests], 60000);

  const handleTableRowSelection = (
    userAccountRequest: IUserAccountRequest[]
  ) => {
    if (userAccountRequest.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }
    setSelectedUserAccountRequests(userAccountRequest);
  };
  const refreshUserAccountRequestTable = () => {
    getUserAccountRequests();
  };

  const handleApproval = () => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();

    for (const selectedUserAccountRequest of selectedUserAccountRequests) {
      api
        .approveUserAccountRequests(selectedUserAccountRequest.uuid)
        .then(() => {
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  User Account Request for
                  {` ${selectedUserAccountRequest.firstName} ${selectedUserAccountRequest.lastName} `}
                  was approved successfully!
                </>
              )
            }
          ]);
          refreshUserAccountRequestTable();
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>
                  Unable to approved User Account Request for
                  {`${selectedUserAccountRequest.firstName} ${selectedUserAccountRequest.lastName} `}
                  !
                </>
              )
            }
          ]);
        });
    }
  };

  return (
    <View
      userAccountRequests={userAccountRequests}
      selectedUserAccountRequests={selectedUserAccountRequests}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      tableIsLoading={tableIsLoading}
      refreshUserAccountRequestTable={refreshUserAccountRequestTable}
      handleApproval={handleApproval}
      rejectUserAccountRequestModalIsVisible={
        rejectUserAccountRequestModalIsVisible
      }
      setRejectUserAccountRequestModalIsVisible={
        setRejectUserAccountRequestModalIsVisible
      }
      editUserAccountRequestModalIsVisible={
        editUserAccountRequestModalIsVisible
      }
      setEditUserAccountRequestModalIsVisible={
        setEditUserAccountRequestModalIsVisible
      }
      userAccountRequestDetailsModalIsVisible={
        userAccountRequestDetailsModalIsVisible
      }
      setUserAccountRequestDetailsModalIsVisible={
        setUserAccountRequestDetailsModalIsVisible
      }
    />
  );
};
export default UserAccountPendingRequestTable;
