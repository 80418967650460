import { FC, useState, Dispatch, SetStateAction } from 'react';

import View from './view';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IRejectUserAccountRequest,
  IUserAccountRequest
} from '../../../../../interfaces/domain/userAccountRequest';

interface IndexProps {
  userAccountRequests: IUserAccountRequest[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  setRejectUserAccountRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshUserAccountRequestTable: () => void;
}
const RejectUserAccountRequestForm: FC<IndexProps> = ({
  userAccountRequests,
  setFlashBarItems,
  setRejectUserAccountRequestModalIsVisible,
  refreshUserAccountRequestTable
}) => {
  const formValues: IRejectUserAccountRequest = {
    rejectionReason: ''
  };
  const [modelFlashBarItems, setModelFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const handleSubmittedForm = async (data: IRejectUserAccountRequest) => {
    setModelFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);
    const api = new MainServiceApi();
    for (const userAccountRequest of userAccountRequests) {
      api
        .rejectUserAccountRequests(
          userAccountRequest.uuid,
          data.rejectionReason
        )
        .then(() => {
          setRejectUserAccountRequestModalIsVisible(false);
          setModelFlashBarItems([]);
          setFlashBarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: <>User Account Request was rejected successfully!</>
            }
          ]);
          refreshUserAccountRequestTable();
        })
        .catch(() => {
          setModelFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setModelFlashBarItems([]),
              content: <> Unable to reject User Account Request!</>
            }
          ]);
        });
    }
  };

  return (
    <View
      formValues={formValues}
      modelFlashBarItems={modelFlashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};

export default RejectUserAccountRequestForm;
