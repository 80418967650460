import { FC } from 'react';
import {
  ColumnLayout,
  Flashbar,
  SpaceBetween,
  Table
} from '@awsui/components-react';
import Moment from 'react-moment';

import { FlashbarProps } from '@awsui/components-react';

import ValueWithLabel from '../../../../common/ValueWithLabel';
import { IUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';

interface ViewProps {
  userAccountRequest: IUserAccountRequest;
  flashBarItems: FlashbarProps.MessageDefinition[];
}

const View: FC<ViewProps> = ({ userAccountRequest, flashBarItems }) => {
  const tableColumnDefinitions = [
    {
      id: 'attribute',
      header: 'Attribute',
      cell: (item: any) => item.name
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item: any) => item.value
    }
  ];

  const tableItems = [
    {
      name: 'UUID',
      value: userAccountRequest.uuid || '-'
    },
    {
      name: 'First Name',
      value: userAccountRequest.firstName || '-'
    },
    {
      name: 'Last Name',
      value: userAccountRequest.lastName || '-'
    },
    {
      name: 'Corp Email',
      value: userAccountRequest.corpEmail || '-'
    },
    {
      name: 'Ticket Link',
      value: userAccountRequest.ticketLink || '-'
    },
    {
      name: 'Rejection Reason',
      value: userAccountRequest.rejectionReason || '-'
    },
    {
      name: 'Justification',
      value: userAccountRequest.justification || '-'
    },
    {
      name: 'Deprecated',
      value: userAccountRequest.deprecated.toString() || '-'
    },
    {
      name: 'Created At',
      value: (
        <Moment unix local format="LLLL">
          {userAccountRequest.createdAt || '-'}
        </Moment>
      )
    },
    {
      name: 'Updated At',
      value: (
        <Moment unix local format="LLLL">
          {userAccountRequest.updatedAt || '-'}
        </Moment>
      )
    },
    {
      name: 'Updated By',
      value: userAccountRequest.updatedBy || '-'
    }
  ];

  return (
    <SpaceBetween size="xs">
      <Flashbar items={flashBarItems} />
      <SpaceBetween size="l">
        <ColumnLayout columns={5} variant="text-grid">
          <ValueWithLabel label="User Name">
            {userAccountRequest.userName || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Home Group">
            {userAccountRequest.homeGroup || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="WS Bundle">
            {userAccountRequest.bundleUiName || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="WS Directory">
            {userAccountRequest.directoryUiName || '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Request Status">
            {userAccountRequest.requestStatus || '-'}
          </ValueWithLabel>
        </ColumnLayout>

        <Table columnDefinitions={tableColumnDefinitions} items={tableItems} />
      </SpaceBetween>
    </SpaceBetween>
  );
};
export default View;
