import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination,
  ButtonDropdown
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IUserAccountRequest } from '../../../../../interfaces/domain/userAccountRequest';

import RejectUserAccountRequestForm from '../rejectUserAccountRequestForm';
import EditUserAccountRequestForm from '../editUserAccountRequestForm';
import UserAccountRequestDetails from '../userAccountRequestDetails';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  userAccountRequests: IUserAccountRequest[];
  selectedUserAccountRequests: IUserAccountRequest[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (user: IUserAccountRequest[]) => void;
  tableIsLoading: boolean;
  setRejectUserAccountRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  rejectUserAccountRequestModalIsVisible: boolean;
  setEditUserAccountRequestModalIsVisible: Dispatch<SetStateAction<boolean>>;
  editUserAccountRequestModalIsVisible: boolean;
  handleApproval: () => void;
  refreshUserAccountRequestTable: () => void;
  setUserAccountRequestDetailsModalIsVisible: Dispatch<SetStateAction<boolean>>;
  userAccountRequestDetailsModalIsVisible: boolean;
}

const View: FC<ViewProps> = ({
  userAccountRequests,
  selectedUserAccountRequests,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  tableIsLoading,
  setRejectUserAccountRequestModalIsVisible,
  rejectUserAccountRequestModalIsVisible,
  setEditUserAccountRequestModalIsVisible,
  editUserAccountRequestModalIsVisible,
  handleApproval,
  refreshUserAccountRequestTable,
  userAccountRequestDetailsModalIsVisible,
  setUserAccountRequestDetailsModalIsVisible
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No User Account Requests</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No User Account Requests to display.
        </Box>
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching User Account Requests</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(userAccountRequests, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: [
        'firstName',
        'lastName',
        'corpEmail',
        'homeGroup',
        'justification'
      ]
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredUserAccountRequests = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshUserAccountRequestTable()}
          />
          <Button
            variant="primary"
            disabled={disableActionButtons}
            onClick={() => setUserAccountRequestDetailsModalIsVisible(true)}
          >
            View
          </Button>
          <ButtonDropdown
            disabled={disableActionButtons}
            items={[
              {
                text: 'Edit',
                id: 'edit-request',
                disabled: disableActionButtons
              },
              {
                text: 'Approve',
                id: 'approve-request',
                disabled: disableActionButtons
              },
              {
                text: 'Reject',
                id: 'reject-request',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'edit-request') {
                setEditUserAccountRequestModalIsVisible(true);
              } else if (detail.id === 'approve-request') {
                handleApproval();
              } else if (detail.id === 'reject-request') {
                setRejectUserAccountRequestModalIsVisible(true);
              }
            }}
          >
            Actions
          </ButtonDropdown>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredUserAccountRequests.length
            ? `(${filteredUserAccountRequests.length}/${userAccountRequests.length})`
            : `(${userAccountRequests.length})`
        }
        actions={renderTableActions()}
      >
        Pending User Account Requests
      </Header>
    );
  };

  const renderRejectUserAccountRequestModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setRejectUserAccountRequestModalIsVisible(false);
        }}
        visible={rejectUserAccountRequestModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Reject User Account Request"
      >
        <RejectUserAccountRequestForm
          userAccountRequests={selectedUserAccountRequests}
          setFlashBarItems={setFlashBarItems}
          setRejectUserAccountRequestModalIsVisible={
            setRejectUserAccountRequestModalIsVisible
          }
          refreshUserAccountRequestTable={refreshUserAccountRequestTable}
        />
      </ChildModal>
    );
  };

  const renderEditUserAccountRequestModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setEditUserAccountRequestModalIsVisible(false);
        }}
        visible={editUserAccountRequestModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Edit User Account Request"
      >
        <EditUserAccountRequestForm
          userAccountRequests={selectedUserAccountRequests}
          setFlashBarItems={setFlashBarItems}
          setEditUserAccountRequestModalIsVisible={
            setEditUserAccountRequestModalIsVisible
          }
          refreshUserAccountRequestTable={refreshUserAccountRequestTable}
        />
      </ChildModal>
    );
  };

  const renderUserAccountRequestDetailsModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setUserAccountRequestDetailsModalIsVisible(false);
        }}
        visible={userAccountRequestDetailsModalIsVisible}
        closeAriaLabel="Close modal"
        size="large"
        header="User Account Request Details"
      >
        <UserAccountRequestDetails
          selectedUserAccountRequests={selectedUserAccountRequests}
        />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'firstName',
      header: 'First Name',
      cell: (item: IUserAccountRequest) => item.firstName || '-'
    },
    {
      id: 'lastName',
      header: 'Last Name',
      cell: (item: IUserAccountRequest) => item.lastName || '-'
    },
    {
      id: 'corpEmail',
      header: 'Email',
      cell: (item: IUserAccountRequest) => item.corpEmail || '-'
    },
    {
      id: 'homeGroup',
      header: 'Home Group',
      cell: (item: IUserAccountRequest) => item.homeGroup || '-'
    },
    {
      id: 'bundleUiName',
      header: 'WS Bundle',
      cell: (item: IUserAccountRequest) => item.bundleUiName || '-'
    },
    {
      id: 'directoryUiName',
      header: 'WS Directory',
      cell: (item: IUserAccountRequest) => item.directoryUiName || '-'
    },
    {
      id: 'justification',
      header: 'Justification',
      cell: (item: IUserAccountRequest) => item.justification || '-'
    }
  ];

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedUserAccountRequests}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Users"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Users"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderRejectUserAccountRequestModal()}
      {renderEditUserAccountRequestModal()}
      {renderUserAccountRequestDetailsModal()}
    </>
  );
};
export default View;
