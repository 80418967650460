import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination,
  ButtonDropdown,
  Alert
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IDuoPhone } from '../../../../../../interfaces/integrations/duo';

import CreateDuoPhoneForm from '../createDuoPhoneForm';
import SMSVerifyModal from '../../smsVerifyForm/modal';
import ChildModal from '../../../../../common/ChildModal';
// import DuoPhoneActivationContent from '../duoPhoneActivationContent';

interface ViewProps {
  duoPhones: IDuoPhone[];
  hasDuoToken: boolean;
  selectedDuoPhones: IDuoPhone[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (duoPhone: IDuoPhone[]) => void;
  duoPhonesIsEmpty: () => boolean;
  tableIsLoading: boolean;
  setCreateDuoPhoneModalIsVisible: Dispatch<SetStateAction<boolean>>;
  createDuoPhoneModalIsVisible: boolean;
  refreshDuoPhoneTable: () => void;
  handleDeletion: (smsCode: string) => Promise<void>;
  handleResetSMS: () => Promise<void>;
  selectedDuoPhoneId: string;
  setActivationCodeModalIsVisible: Dispatch<SetStateAction<boolean>>;
  activationCodeModalIsVisible: boolean;
  setDeletePhoneModalIsVisible: Dispatch<SetStateAction<boolean>>;
  deletePhoneModalIsVisible: boolean;
  deleteConfirmed: boolean;
  setDeleteConfirmed: Dispatch<SetStateAction<boolean>>;
}

const View: FC<ViewProps> = ({
  duoPhones,
  hasDuoToken,
  selectedDuoPhones,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  duoPhonesIsEmpty,
  tableIsLoading,
  createDuoPhoneModalIsVisible,
  setCreateDuoPhoneModalIsVisible,
  refreshDuoPhoneTable,
  handleDeletion,
  handleResetSMS,
  selectedDuoPhoneId,
  setActivationCodeModalIsVisible,
  activationCodeModalIsVisible,
  setDeletePhoneModalIsVisible,
  deletePhoneModalIsVisible,
  deleteConfirmed,
  setDeleteConfirmed
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No DUO Phones</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No DUO Phones to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            onClick={() => {
              setCreateDuoPhoneModalIsVisible(true);
              handleTableRowSelection([]);
            }}
          >
            Create DUO Phones
          </Button>
        }
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching DUO Phones</b>
      </Box>
    );
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(duoPhones, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['userName', 'firstName', 'lastName', 'corpEmail']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredDuoPhones = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshDuoPhoneTable()}
          />

          {/* <ButtonDropdown
            variant="primary"
            disabled={disableActionButtons}
            items={[
              {
                text: 'Activation',
                id: 'activate-phone',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'activate-phone') {
                setDuoPhoneActivationModalIsVisible(true);
              }
            }}
          >
            View
          </ButtonDropdown> */}
          <ButtonDropdown
            items={[
              {
                text: 'Create',
                id: 'create-phone'
              },
              {
                text: 'Delete',
                id: 'delete-phone',
                disabled: disableActionButtons
              }
            ]}
            onItemClick={({ detail }) => {
              if (detail.id === 'delete-phone') {
                setDeletePhoneModalIsVisible(true);
              } else if (detail.id === 'create-phone') {
                setCreateDuoPhoneModalIsVisible(true);
                handleTableRowSelection([]);
              }
            }}
          >
            Actions
          </ButtonDropdown>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredDuoPhones.length
            ? `(${filteredDuoPhones.length}/${duoPhones.length})`
            : `(${duoPhones.length})`
        }
        actions={renderTableActions()}
      >
        DUO Phones
      </Header>
    );
  };

  const renderCreateDuoPhoneModal = (
    <ChildModal
      onDismiss={() => {
        setCreateDuoPhoneModalIsVisible(false);
      }}
      visible={createDuoPhoneModalIsVisible}
      closeAriaLabel="Close modal"
      size="medium"
      header="Create DUO Phone"
    >
      <CreateDuoPhoneForm
        refreshDuoPhoneTable={refreshDuoPhoneTable}
        duoPhones={duoPhones}
        hasDuoToken={hasDuoToken}
        setCreateDuoPhoneModalIsVisible={setCreateDuoPhoneModalIsVisible}
        setActivationCodeModalIsVisible={setActivationCodeModalIsVisible}
        activationCodeModalIsVisible={activationCodeModalIsVisible}
      />
    </ChildModal>
  );

  const renderDeletePhoneModal = () => {
    return (
      <div>
        <ChildModal
          onDismiss={() => setDeletePhoneModalIsVisible(false)}
          visible={!deleteConfirmed && deletePhoneModalIsVisible}
          closeAriaLabel="Close modal"
          size="medium"
          header="Confirm - Delete DUO Phone"
        >
          <SpaceBetween size="s">
            <Alert statusIconAriaLabel="Warning" type="warning">
              Are you sure you want to delete your DUO Phone?
              {
                hasDuoToken ?
                <>
                  <br></br><br></br>
                  Since you're using a hardware token, you won't be able to register a
                  new phone after deleting this one without admin support.
                </> :
                <></>
              }
            </Alert>
            <Button
              variant="primary"
              onClick={() => handleResetSMS()}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </ChildModal>
        <SMSVerifyModal
          submitSMSCode={handleDeletion}
          phoneNumber={duoPhones.length > 0 ? duoPhones[0].number : ''}
          handleDismiss={() => setDeletePhoneModalIsVisible(false)}
          header="Delete DUO Phone"
          isVisible={deleteConfirmed && deletePhoneModalIsVisible}
          flashBarItems={flashBarItems}
          setFlashBarItems={setFlashBarItems}
        />
      </div>
    );
  };

  // const renderDuoPhoneActivationModel = (
  //   <Modal
  //     onDismiss={() => {
  //       setDuoPhoneActivationModalIsVisible(false);
  //     }}
  //     visible={duoPhoneActivationModalIsVisible}
  //     closeAriaLabel="Close modal"
  //     size="medium"
  //     header="Activate DUO Phone"
  //   >
  //     <DuoPhoneActivationContent duoPhoneId={selectedDuoPhoneId} />
  //   </Modal>
  // );

  const tableColumnDefinitions = [
    {
      id: 'number',
      header: 'Phone Number',
      cell: (item: IDuoPhone) => item.number || '-'
    },
    {
      id: 'name',
      header: 'Name',
      cell: (item: IDuoPhone) => item.name || '-'
    },
    {
      id: 'model',
      header: 'Model',
      cell: (item: IDuoPhone) => item.model || '-'
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item: IDuoPhone) => item.type || '-'
    },
    {
      id: 'platform',
      header: 'Platform',
      cell: (item: IDuoPhone) => item.platform || '-'
    },
    {
      id: 'last_seen',
      header: 'Last Seen',
      cell: (item: IDuoPhone) => item.last_seen || '-'
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="phone_id"
          selectedItems={selectedDuoPhones}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={items}
          loadingText="Loading Duo Phones"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Duo Phones"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderCreateDuoPhoneModal}
      {renderDeletePhoneModal()}
      {/* {renderDuoPhoneActivationModel} */}
    </>
  );
};
export default View;
