import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { IUserWorkSpaceDecommissionRequest } from '../../../../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

interface IndexProps {
  selectedUserWorkSpaceDecommissionRequests: IUserWorkSpaceDecommissionRequest[];
}

const UserWorkSpaceDecommissionRequestDetails: FC<IndexProps> = ({
  selectedUserWorkSpaceDecommissionRequests
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [
    userWorkSpaceDecommissionRequest,
    setUserWorkSpaceDecommissionRequest
  ] = useState<IUserWorkSpaceDecommissionRequest>({
    uuid: '',
    userName: '',
    corpEmail: '',
    requestStatus: '',
    rejectionReason: '',
    workSpaceId: '',
    justification: '',
    deprecated: 0,
    createdAt: 0,
    updatedAt: 0,
    updatedBy: ''
  });

  useEffect(() => {
    const api = new MainServiceApi();

    for (const selectedUserWorkSpaceDecommissionRequest of selectedUserWorkSpaceDecommissionRequests) {
      api
        .getUserWorkSpaceDecommissionRequestByUuid(
          selectedUserWorkSpaceDecommissionRequest.uuid
        )
        .then((data) => {
          setUserWorkSpaceDecommissionRequest(data);
        })
        .catch(() => {
          setFlashBarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashBarItems([]),
              content: (
                <>Unable to get User WorkSpace Decommission Request data!</>
              )
            }
          ]);
        });
    }
  }, [selectedUserWorkSpaceDecommissionRequests]);

  return (
    <View
      userWorkSpaceDecommissionRequest={userWorkSpaceDecommissionRequest}
      flashBarItems={flashBarItems}
    />
  );
};
export default UserWorkSpaceDecommissionRequestDetails;
