import { FC, useState, useEffect } from 'react';

import { MainServiceApi } from '../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import { IHomeGroup } from '../../../../../interfaces/domain/homegroup';

import View from './view';

const HomeGroupTable: FC = () => {
  const [homeGroups, setHomeGroups] = useState<IHomeGroup[]>([]);
  const [selectedHomeGroups, setSelectedHomeGroups] = useState<IHomeGroup[]>(
    []
  );
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [
    createHomeGroupFormModalIsVisible,
    setCreateHomeGroupFormModalIsVisible
  ] = useState(false);
  const [editHomeGroupFormModalIsVisible, setEditHomeGroupFormModalIsVisible] =
    useState(false);

  useEffect(() => {
    getHomeGroups().then((data) => {
      setHomeGroups(data);
      setTableIsLoading(false);
    });
  }, []);

  const getHomeGroups = () => {
    const api = new MainServiceApi();
    return api
      .getAllHomeGroups()
      .then((data) => {
        return data;
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Home Groups!</>
          }
        ]);
        return [];
      });
  };
  const homeGroupsIsEmpty = () => {
    return homeGroups.length === 0;
  };

  const handleTableRowSelection = (row: IHomeGroup[]) => {
    if (row.length === 0) {
      setDisableActionButtons(true);
    } else {
      setDisableActionButtons(false);
    }

    setSelectedHomeGroups(row);
  };
  const refreshHomeGroupTable = () => {
    setTableIsLoading(true);
    handleTableRowSelection([]);
    getHomeGroups().then((data) => {
      setHomeGroups(data);
      setSelectedHomeGroups([]);
      setTableIsLoading(false);
    });
  };
  return (
    <View
      homeGroups={homeGroups}
      selectedHomeGroups={selectedHomeGroups}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      disableActionButtons={disableActionButtons}
      handleTableRowSelection={handleTableRowSelection}
      homeGroupsIsEmpty={homeGroupsIsEmpty}
      tableIsLoading={tableIsLoading}
      createHomeGroupFormModalIsVisible={createHomeGroupFormModalIsVisible}
      setCreateHomeGroupFormModalIsVisible={
        setCreateHomeGroupFormModalIsVisible
      }
      editHomeGroupFormModalIsVisible={editHomeGroupFormModalIsVisible}
      setEditHomeGroupFormModalIsVisible={setEditHomeGroupFormModalIsVisible}
      refreshHomeGroupTable={refreshHomeGroupTable}
    />
  );
};
export default HomeGroupTable;
