import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { FlashbarProps } from '@awsui/components-react';
import {
  IUpdateDuoPlatform,
  IDuoPlatform
} from '../../../../../../interfaces/integrations/duo';

import View from './view';

interface IndexProps {
  refreshDuoPlatformTable: () => void;
  duoPlatforms: IDuoPlatform[];
}

const EditDuoPlatformForm: FC<IndexProps> = ({
  refreshDuoPlatformTable,
  duoPlatforms
}) => {
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [duoPlatformUuid, setDuoPlatformUuid] = useState('');
  const [formValues, setFormValues] = useState<IUpdateDuoPlatform>({
    platformName: '',
    deprecated: 0
  });

  useEffect(() => {
    for (const duoPlatform of duoPlatforms) {
      setDuoPlatformUuid(duoPlatform.uuid);
      setFormValues({
        platformName: duoPlatform.platformName,
        deprecated: duoPlatform.deprecated
      });
    }
  }, [duoPlatforms]);

  const handleSubmittedForm = async (data: IUpdateDuoPlatform) => {
    setFormValues(data);

    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updateDuoPlatform(duoPlatformUuid, data)
      .then(() => {
        refreshDuoPlatformTable();
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Duo Platform has been updated!</>
          }
        ]);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update Duo Platform!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      flashBarItems={flashBarItems}
      handleSubmittedForm={handleSubmittedForm}
    />
  );
};
export default EditDuoPlatformForm;
