import { FC, Dispatch, SetStateAction } from 'react';

import {
  Header,
  Button,
  SpaceBetween,
  Table,
  Flashbar,
  Box,
  TextFilter,
  Pagination
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';

import { FlashbarProps } from '@awsui/components-react';
import { IApprovedDomain } from '../../../../../interfaces/authentication/approvedDomain';

import CreateForm from '../createForm';
import EditForm from '../editForm';
import ChildModal from '../../../../common/ChildModal';

interface ViewProps {
  items: IApprovedDomain[];
  selectedItems: IApprovedDomain[];
  flashBarItems: FlashbarProps.MessageDefinition[];
  setFlashBarItems: Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  disableActionButtons: boolean;
  handleTableRowSelection: (row: IApprovedDomain[]) => void;
  itemsIsEmpty: () => boolean;
  tableIsLoading: boolean;
  createFormModalIsVisible: boolean;
  setCreateFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  editFormModalIsVisible: boolean;
  setEditFormModalIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshTable: () => void;
}

const View: FC<ViewProps> = ({
  items,
  selectedItems,
  flashBarItems,
  setFlashBarItems,
  handleTableRowSelection,
  disableActionButtons,
  itemsIsEmpty,
  tableIsLoading,
  createFormModalIsVisible,
  setCreateFormModalIsVisible,
  editFormModalIsVisible,
  setEditFormModalIsVisible,
  refreshTable
}) => {
  const renderEmptyTableContent = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No Approved Domains</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No Approved Domains to display.
        </Box>
        {
          <Button
            variant="primary"
            iconName="add-plus"
            onClick={() => {
              setCreateFormModalIsVisible(true);
            }}
          >
            Create Approved Domain
          </Button>
        }
      </Box>
    );
  };
  const renderEmptyTableContentAfterFiltering = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No matching Approved Domains</b>
      </Box>
    );
  };

  const {
    items: tableItems,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(items, {
    filtering: {
      empty: renderEmptyTableContent(),
      noMatch: renderEmptyTableContentAfterFiltering(),
      fields: ['domainName']
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems: filteredItems = [] } = collectionProps;

  const renderTableActions = () => {
    return (
      <>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="refresh"
            variant="link"
            onClick={() => refreshTable()}
          />

          <Button
            iconName="add-plus"
            disabled={itemsIsEmpty()}
            onClick={() => {
              setCreateFormModalIsVisible(true);
            }}
          >
            Create
          </Button>
          <Button
            iconName="settings"
            disabled={disableActionButtons}
            onClick={() => {
              setEditFormModalIsVisible(true);
            }}
          >
            Edit
          </Button>
        </SpaceBetween>
      </>
    );
  };
  const renderTableHeader = () => {
    return (
      <Header
        counter={
          filteredItems.length
            ? `(${filteredItems.length}/${items.length})`
            : `(${items.length})`
        }
        actions={renderTableActions()}
      >
        Approved Domains
      </Header>
    );
  };

  const renderCreateFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          handleTableRowSelection([]);
          setCreateFormModalIsVisible(false);
        }}
        visible={createFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="New Approved Domain"
      >
        <CreateForm refreshTable={refreshTable} />
      </ChildModal>
    );
  };

  const renderEditFormModal = () => {
    return (
      <ChildModal
        onDismiss={() => {
          setEditFormModalIsVisible(false);
        }}
        visible={editFormModalIsVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Edit Approved Domain"
      >
        <EditForm refreshTable={refreshTable} selectedItems={selectedItems} />
      </ChildModal>
    );
  };

  const tableColumnDefinitions = [
    {
      id: 'domainName',
      header: 'Domain',
      cell: (item: IApprovedDomain) => item.domainName || '-'
    }
  ];
  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Flashbar items={flashBarItems} />
        <Table
          {...collectionProps}
          loading={tableIsLoading}
          trackBy="uuid"
          selectedItems={selectedItems}
          selectionType="single"
          columnDefinitions={tableColumnDefinitions}
          items={tableItems}
          loadingText="Loading Items"
          onSelectionChange={({ detail }) =>
            handleTableRowSelection(detail.selectedItems)
          }
          header={renderTableHeader()}
          filter={
            <TextFilter
              {...filterProps}
              countText={`${filteredItemsCount} matches`}
              filteringAriaLabel="Filter Items"
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
              }}
            />
          }
        />
      </SpaceBetween>
      {renderCreateFormModal()}
      {renderEditFormModal()}
    </>
  );
};
export default View;
