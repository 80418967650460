import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Flashbar } from '@awsui/components-react';

import '@awsui/global-styles/index.css';

import { ICurrentUser } from './interfaces/domain/user';

import { MainServiceApi } from './lib/api/mainServiceApi';
import {
  setSessionStorageUser,
  sessionStorageUser,
  unsetSessionStorageUser
} from './lib/utils/user';
import {
  localPortalInitialized,
  setLocalPortalInitialized
} from './lib/utils/portalInitialized';

import { inactivityConfig } from './lib/config/amplifyConfig';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';

import Authentication from './components/authentication';
import InitializingPortal from './components/admin/portal/initializingPortal';
import Home from './Home';
import CreateUserAccountRequest from './components/user/domain/createUserAccountRequest';
import InactivityModal from './components/authentication/inactivityModal';

const App: FC = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  const [isAuthenticated, setIsAuthenticated] = useState(
    route === 'authenticated'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<ICurrentUser>(
    sessionStorageUser()
  );
  const [isPortalInitialized, setIsPortalInitialized] = useState(
    localPortalInitialized()
  );
  const [userExists, setUserExists] = useState(
    Object.keys(sessionStorageUser()).length !== 0
  );
  const [somethingWentWrongWithLogin, setSomethingWentWrongWithLogin] =
    useState(false);

  useEffect(() => {
    setIsAuthenticated(route === 'authenticated');

    if (route === 'setup' || route === 'authenticated') {
      setIsLoading(false);
    }
  }, [route]);

  const setUser = (user: ICurrentUser) => {
    setCurrentUser(user);
    setSessionStorageUser(user);
  };

  const setPortalInitialized = (portalInitialized: boolean) => {
    setIsPortalInitialized(portalInitialized);
    setLocalPortalInitialized(portalInitialized);
  };

  // Records the time the user will be logged out at for inactivity
  const idleLogoutTime = useRef<Date | null>(null);

  // Used to display a countdown on the warning dialog before a user
  // is about to be logged out
  const [timeLeftMS, setTimeLeftMS] = useState<number | null>(null);

  const [showInactivityModal, setShowInactivityModal] = useState(false);

  // Record user activity - this resets the idle timeout
  const refreshIdleLogoutTime = useCallback(() => {
    setShowInactivityModal(false);

    const newTime = new Date();
    newTime.setSeconds(newTime.getSeconds() + inactivityConfig.inactivityLogoutThresholdSec);

    idleLogoutTime.current = newTime;
  }, []);

  const { signOut } = useAuthenticator();

  const logOut = useCallback(() => {
    // Amplify is very half baked so need to use the old Authenticator as well as useAuthenticator
    signOut();
    Auth.signOut();

    setIsAuthenticated(false);
    unsetSessionStorageUser();
    idleLogoutTime.current = null;
  }, [signOut]);

  const logIn = useCallback(() => {
    setIsLoading(true);
    setIsAuthenticated(true);
    refreshIdleLogoutTime(); // set initial logout time

    const api = new MainServiceApi();

    api
      .userAlreadyExists()
      .then((data) => {
        setUserExists(data.exists);
        if (data.exists) {
          Promise.all([
            api.whoAmI(),
            api.getPortalSettingsByNames(['initialized'])
          ])
            .then(([whoAmI, settings]) => {
              setPortalInitialized(settings.initialized);
              setUser(whoAmI);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
              setIsAuthenticated(false);
              unsetSessionStorageUser();
              idleLogoutTime.current = null;

              setSomethingWentWrongWithLogin(true);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setSomethingWentWrongWithLogin(true);
      });
  }, [refreshIdleLogoutTime]);

  // Main hook for tracking user activity
  useEffect(() => {
    // Check for inactivity and warn or log out the user as needed
    const inactivityCheck = () => {
      if (idleLogoutTime.current) {
        const remainingMS = idleLogoutTime.current.getTime() - Date.now();

        if (remainingMS <= 0) {
          // Session time out reached - log out
          logOut();
        } else if (remainingMS <= inactivityConfig.inactivityWarnThresholdMS) {
          // Display grace period warning
          setShowInactivityModal(true);
        }

        if (showInactivityModal) {
          // Update the countdown clock only if the warning modal is showing
          // (avoid unnecessary re-renders)
          setTimeLeftMS(remainingMS);
        }
      }
    };

    let inactivityCheckInterval: NodeJS.Timer;

    const resetActivityFromWindowEvent = () => {
      // Require the Continue button to be pressed if the warning modal
      // is showing - do not accept regular user activity (e.g. mouse movement)
      // to reset the timer
      if (!showInactivityModal) {
        refreshIdleLogoutTime();
      }
    };

    const listenForActivity = () => {
      // Create listeners for user activity and checking interval
      window.addEventListener('mousemove', resetActivityFromWindowEvent);
      window.addEventListener('keypress', resetActivityFromWindowEvent);

      inactivityCheckInterval = setInterval(inactivityCheck, inactivityConfig.inactivityCheckIntervalMS);
    };
    const unlistenForActivity = () => {
      // Remove listeners and checking interval
      window.removeEventListener('mousemove', resetActivityFromWindowEvent);
      window.removeEventListener('keypress', resetActivityFromWindowEvent);

      if (inactivityCheckInterval) {
        clearInterval(inactivityCheckInterval);
      }
    };

    // If the component has re-rendered (e.g. due to user log in),
    // need to recreate the activity listeners again
    if (isAuthenticated) {
      listenForActivity();
    }

    return () => {
      unlistenForActivity();
    };
  }, [isAuthenticated, showInactivityModal, refreshIdleLogoutTime, logOut]);

  // Hook for listening to Amplify events
  useEffect(() => {
    const authListener = Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'cognitoHostedUI') {
        // Trigger log in when SSO signs in and redirects back to WSSP
        logIn();
      } else if (event === 'tokenRefresh_failure') {
        // Log out when refresh token expires, regardless of user activity
        logOut();
      }
    });

    return () => {
      // clean up listener on component unmount
      authListener();
    };
  }, [logIn, logOut]);

  return (
    <>
      <InactivityModal
        timeLeftMS={timeLeftMS}
        isVisible={showInactivityModal}
        handleDismiss={() => setShowInactivityModal(false)}
        handleRefreshActivity={() => refreshIdleLogoutTime()}
      />
      {isLoading ? (
        <Flashbar
          items={[
            {
              type: 'warning',
              loading: true,
              content: 'Loading...'
            }
          ]}
        />
      ) : (
        <>
          {somethingWentWrongWithLogin ? (
            <Flashbar
              items={[
                {
                  type: 'error',
                  content:
                    'Something went wrong with logging in... Contact your administrator!'
                }
              ]}
            />
          ) : isAuthenticated ? (
            isPortalInitialized ? (
              userExists ? (
                <Home
                  currentUser={currentUser}
                  isAuthenticated={isAuthenticated}
                  isLoading={isLoading}
                  isPortalInitialized={isPortalInitialized}
                  logOut={logOut}
                  hideChildModals={showInactivityModal}
                />
              ) : (
                <CreateUserAccountRequest logOut={logOut} />
              )
            ) : (
              <InitializingPortal setPortalInitialized={setPortalInitialized} />
            )
          ) : (
            <Authentication logIn={logIn} />
          )}
        </>
      )}
    </>
  );
};
export default App;