import { FC, useState } from 'react';
import { Toggle, Container, Header } from '@awsui/components-react';
import { darkModeEnabled, setDarkModeEnabled } from '../../lib/utils/darkMode';

const Settings: FC = () => {
  const [darkModeChecked, setDarkModeChecked] = useState(darkModeEnabled());
  return (
    <Container header={<Header variant="h2">User Settings</Header>}>
      <Toggle
        onChange={({ detail }) => {
          setDarkModeChecked(detail.checked);
          setDarkModeEnabled(detail.checked);
        }}
        checked={darkModeChecked}
      >
        Enable Dark Mode
      </Toggle>
    </Container>
  );
};

export default Settings;
