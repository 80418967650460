export const localPortalInitialized = () => {
  return JSON.parse(
    localStorage.getItem('portalInitialized') || 'false'
  ) as boolean;
};

export const setLocalPortalInitialized = (portalInitialized: boolean) => {
  localStorage.setItem('portalInitialized', JSON.stringify(portalInitialized));
};

export const unsetLocalPortalInitialized = () => {
  localStorage.setItem('portalInitialized', 'false');
};
