import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { authConfig } from './lib/config/amplifyConfig';

Auth.configure(authConfig);

ReactDOM.render(
  <StrictMode>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </StrictMode>,
  document.getElementById('root')
);
